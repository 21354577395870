import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showContractorModal: boolean;
  modalContractorType: string;
  contractorData: any;
  closeModal:  () => void;
  onPregressDone: () => void;
  onSubmitRating: (body:any) => void;
  onSubmitSelectedContractor: () => void;
  history: any;
  location: any;
  cardType?: any;
  dashboard?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  progress: number ;
  selectedContractorId: any;
  highlightContractor: any;
  ratingCat: any;
  anythingElse: string;
  enableSubmit: boolean;
  collectFeesData: any;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    getCollectFeesApiCallId: string = ""
    createRoomApiId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      token: "",
      progress: 0,
      selectedContractorId: '',
      highlightContractor: '',
      anythingElse: '',
      ratingCat: [
        {title: "Professionalism", key:"professionalism", value: 0},
        {title: "Efficiency", key:"efficiency", value: 0},
        {title: "Likelihood To Recommend", key:"likelihood_to_recommend", value: 0}
      ],
      enableSubmit: false,
      collectFeesData: {
        Description: "Connect Fee: ",
        ContractorFee: "",
        ServiceFee: "",
        TotalCost: ""
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
       this.handleApiResposes(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.setToken()
    if(this.props.location.state?.contractorId){
      const { contractorId } = this.props.location.state;
      this.setState({ selectedContractorId: contractorId });
    }
  }
  setToken =() => {
    const token = localStorage.getItem('signin-token');
    this.setState({
      token: token || ""
    })
  }
  stratProgressing = () => {
    const interval: any = setInterval(() => this.updateProgressState(interval), 1000);
  }
  updateProgressState = (interval:any) => {
    if (this.state.progress < 100) {
      this.setState((prevProgress) => ({progress: prevProgress.progress + 10}));
    } else{
      clearInterval(interval);
      this.props.onPregressDone()
    }
  }
  onContractorSelect = (id: any, contractorIndex?: number) => {
    this.setState({
      selectedContractorId: id,
      highlightContractor: contractorIndex
    })
    this.getCollectFeesData(id)
  }
  onAcceptContractor = () => {
    this.createRoomMeeting();    
  }

  createRoomMeeting = () => {
    const headers= { 'Content-Type': 'application/json',
      token: this.state.token
    }
    this.createRoomApiId = this.callAnAPiFunction({
      headers: headers,   
      body:{ contractor_id: this.state.selectedContractorId},
      endPoint: "bx_block_video_call/meetings/create_meeting",
      requestType:"POST",
    })    
  }

  callAnAPiFunction = (props:any) => {
    const getMeetingApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getMeetingApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    getMeetingApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(props.headers));
    if(props.body){
      getMeetingApiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(props.body));
    }
    getMeetingApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),props.requestType);
    runEngine.sendMessage(getMeetingApiMsg.id, getMeetingApiMsg);
    return getMeetingApiMsg.messageId;
  }
  onStarClick = (index: any, value:any) => {
    const updatedRatingCat = [...this.state.ratingCat];    
    updatedRatingCat[index].value = value;
    const valueZero = updatedRatingCat.findIndex((obj:any) => obj.value === 0);
    const enableSubmit = valueZero === -1 ? true : false;
    this.setState({ ratingCat: updatedRatingCat, enableSubmit: enableSubmit });
  }
  onFieldChange = (event: any) => {
    this.setState({
      anythingElse: event.target.value
    })
  }
  onRatingSubmit = () => {
    const {ratingCat} = this.state;
    const body = {
      data: {
        attributes: {
          professionalism: ratingCat.find((cat:any) => cat.key === 'professionalism')?.value || 0,
          efficiency: ratingCat.find((cat:any) => cat.key === 'efficiency')?.value || 0,
          likelihood_to_recommend: ratingCat.find((cat:any) => cat.key === 'likelihood_to_recommend')?.value || 0,
          anything_else: this.state.anythingElse,
          account_id: this.state.selectedContractorId,
        },
      },
    };
    this.props.onSubmitRating(body);    
  }

  handleApiResposes = (apiRequestCallId: any, responseJson: any) => {
    if(apiRequestCallId === this.getCollectFeesApiCallId) {
      this.setCollectionfeesRespose(responseJson)
    }
    if(apiRequestCallId === this.createRoomApiId){
      if (responseJson.data) {
        const data = responseJson.data;
        const engdata = {
          roomData: data.meeting.data.attributes, 
          roomId: data.meeting.data.attributes.roomId,
          videoToken: data.videosdk_token          
        }
        this.props.history.replace({
          pathname: "/GroupVideoCall",
          state: { selectedContractorId: this.state.selectedContractorId, engageMentRecords : engdata, userType: "customer" },
        });
      }
    }
  }

  setCollectionfeesRespose = (responseJson: any ) => {
     if(responseJson) {
       const data: any = responseJson;
       const updatedData: any = {};
       for (const key in data) {
         if (Object.prototype.hasOwnProperty.call(data, key)) {
           const newKey = key.replace(/\s+/g, ''); // Remove spaces from the key
           updatedData[newKey] = data[key];
         }
       }

       this.setState({ collectFeesData: updatedData })
     } else {
      this.setState({ collectFeesData: {}})
     }
  }


  getCollectFeesData = (id : any) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCollectFeesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCollectFeesApiEndpoint}?contractor_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGETRequestMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  truthyValue = (key: any) => {
    if (key !== "" || key !== null) {
        return key
    } else {
        return ""
    }     
  }
  // Customizable Area End
}
