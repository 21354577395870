// Customizable Area Start
import React from "react";
import { Button, Typography, Input } from "@material-ui/core";
import {
  ArrowForwardRounded,
  CloseOutlined,
  SentimentSatisfiedAltOutlined
} from "@material-ui/icons"
import MessageList from "./MessageList";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const MeetingChatView = ({ publish, messages, message, setMessage, closeChat, localParticipantId }) => {
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);

  const onEmojiIconClick = () =>{
    setShowEmojiPicker(true);
  }

  return (
    <div style={webStyle.container}>
      <Typography variant="h5" style={webStyle.title}>
        Chat <span style={{float: 'right', cursor:'pointer'}} onClick={closeChat}><CloseOutlined style={{color: '#f8fafc'}} /></span>
      </Typography>
      <MessageList messages={messages} localParticipantId={localParticipantId} />
      <form data-testid="msgSendForm" onSubmit={(event) => {
          event.preventDefault();
          const m = message;
          m.length && publish(m, { persist: true });
          m.length && setMessage("");
          }}          
      >
      <div style={webStyle.textInputWrapper}>
        {showEmojiPicker && <Picker data={data} onEmojiSelect={(emoji) => {
          setShowEmojiPicker(false)
          setMessage(message + emoji.native);
        }} />}
        <Button style={webStyle.btnEmoji} color="transparent" variant="outlined" data-testid="btmEmoji" onClick={onEmojiIconClick}>
          <SentimentSatisfiedAltOutlined 
            style={{color: '#CBD5E1'}}
          />
        </Button>
        <div style={webStyle.afterEmoji}></div>
        <Input
          value={message}
          onChange={e => {
            const v = e.target.value;
            setMessage(v);
          }}
          data-testid="videoMssageTextInput"
          style={webStyle.textInput}
          placeholder=""
        />
        <Button
          style={webStyle.sendBtn}
          color="primary"
          variant="contained"
          data-testid="messageSendBtn"
          onClick={() => {
            const m = message;
              m.length && publish(m, { persist: true });
              m.length && setMessage("");
          }}
        >
          <ArrowForwardRounded />
        </Button>
      </div>
      </form>
    </div>
  );
};

const webStyle = {
  container: {
    width: 344,
    borderRadius: 8,
    backgroundColor: "#333341",
    margin: 0,     
    padding: 0,
    position: 'relative'
  },
  title: {
    margin: 15,
    padding: 8,
    color: '#f8fafc',
    borderBottom: '2px solid #3c3e50'
  },
  textInputWrapper: {
    borderTop: '1px solid #3c3e50',
    padding: 10,
    paddingTop: 15,
    display: "flex",
    position: 'absolute',
    left: 0,
    bottom: 0,
    gap: 20
  },
  textInput: {
    borderRadius: "5px",
    color: 'white'
  },
  sendBtn: {
    marginRight: 0,
    height: 32,
    width: 32,
    minWidth: 32,
    textAlign: "right"
  },
  btnEmoji: {
    height: 32,
    width: 32,
    minWidth: 32,
    border: 'none'
  },
  afterEmoji: {
    borderRight: '1px solid #CBD5E1',
    width: 1,
    height: 30
  }
};

export default MeetingChatView;
// Customizable Area End
