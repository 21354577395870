import React from "react";
// Customizable Area Start
import {
  Typography,
  Box, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Radio,
  Icon,
  Snackbar
} from "@material-ui/core";
import { flagUS, star,Editt, plusIcon, edit, deleteimg } from './assets';
import Header from "../../emailnotifications2/src/TopHeader.web";
import { striprKey } from "../../../components/src/assets"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "../../../components/src/CheckoutForm";
import UpdateCard from "../../../components/src/UpdateCard";
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ProfileController, {
    Props,
  } from "./ProfileController.web";
import SearchContractor from "../../landingpage/src/searchContractor.web";
const stripePromise = loadStripe(striprKey);
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Country }  from 'country-state-city';
import moment from "moment";
import {greenTick} from "../../forgot-password/src/assets"
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

export const ProfileScreenStyle = (theme: Theme) => ({    
    profileCard:{
        background:'#FFF',
        boxShadow:'1px 1px 10px 1px rgba(0, 0, 0, 0.15)',
        display:'flex',
        justifyContent:'space-between',
        borderRadius:'8px',
        padding:'24px',
        margin:'15px 0',
    },
    proifeCardLeft:{
        display:'flex',
        gap:'20px',
        alignItems:'center'
    },
    profileName: {
        color:"#21272A",
        leadingTrim:"both",
        textEdge:"cap",
        fontSize:"24px",
        fontStyle:"normal",
        fontFamily: 'Lato',
        fontWeight:700,
        lineHeight:"32px",
        width: '100%'
    },
    contryName:{
        color:"#64748B",
        leadingTrim:"both",
        textEdge:"cap",
        fontSize:"20px",
        fontStyle:"normal",
        fontWeight:500,
        lineHeight:"32px",
        display:'flex',
        alignItem:'center',
        gap:'10px'
    },
    profileimg: {
        border:"2px solid #2966FF", borderRadius: "100%"
    },
    container:{
        padding: '15px 20px',
        background: '#f8fafc'
        // maxWidth:'1200px',
        // margin:'0 auto',
        // width:'100%'
    },
    row:{
        width:'100%',
        display:'block',
        maxWidth: 1308,
        margin: 'auto'
    },
    editBtn:{
        borderRadius:"8px",
        border:"1px solid #2966FF",
        padding:"10px 16px",
        height:"40px",
        background:"transparent",
        color:"#2966FF",
        display:'flex',
        alignItem:'center',
        marginTop: "50px",
        fontWeight: 700,
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        "@media(max-width: 767px)":{ width: "200px",marginTop: "15px" }
    },
    plusIcon: {      
      padding: '0',
      color:"#2966FF",
    },
    profileSkills:{
        color:"#64748B",
        leadingTrim:"both",
        textEdge:"cap",
        fontSize:"16px",
        fontStyle:"normal",
        fontWeight:400,
        lineHeight:"32px",
        display:"inline-flex",
        gap:"30px",
        paddingLeft:"15px",
        margin:"0",
        "@media(max-width: 767px)": {flexDirection: 'column', gap: "0px"}
    },
    userEmail : {
      color: "#64748B",
      fontFamily: "Lato",
      fontSize: '16px',
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px", /* 150% */
    }, 
    userPhone : {
      color: "#64748B",
      fontFamily: "Lato",
      fontSize: '16px',
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px", /* 150% */
    },  
    lastTimeCardInfo : {
      color : "#0F172A",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px", /* 150% */
    },
    cardNameInfo : {
      color : "#1E293B",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px", /* 150% */
    },
    subCardDetail: {
      color : "#64748B",
      fontFamily: "Lato",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px", /* 150% */
    },
    
    // Delete Card CSS
    deletepopupDeleteCardModal: {
      "& .MuiDialog-paperWidthSm":
      {
          maxWidth: '450px',
      }
  },
    popuptitleDeleteCardModal: {
          color: "#2966FF",
          textAlign: "center" as const,
          leadingTrim: "both",
          textEdge: "cap",
          fontFamily: "Lato",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          marginBottom: '24px'
      },
        
      deleteButtonDeleteCardModal:
      {
          borderRadius: "8px", background: "#DC2626",
          height: "56px", padding: "10px 16px",
          color: "#FFF",
          fontFamily: "Lato",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "24px",
          width: "50%",
          "&:hover": {
            background: "#DC2626",
        },
        "&:focus": {
            background: "#DC2626",
        }
  
      },
      popupdetailDeleteCardModal: {
          color: "#64748B",
          textAlign: "center" as const,
          leadingTrim: "both",
          textEdge: "cap",
          fontFamily: "Lato",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
          marginBottom: '24px',
          width: '100%',
          display: 'block'
  
  
      },
   
      noButtonDeleteCardModal: {
          borderRadius: "8px", border: "1px solid #2966FF",
          background: '#fff',
          padding: "10px 16px",
          color: "#2966FF",
          fontFamily: "Lato",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "24px",
          height: "56px",
          width: "50%"
      },
  userDataContainer: {
    height: 132,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

class Profile extends ProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const flagemojiToPNG = (flag: any) => {
      const countryCode = Array.from(flag, (codeUnit:any) => codeUnit.codePointAt()).map(char => String.fromCharCode(char-127397).toLowerCase()).join('')
      return (<img src={`https://flagcdn.com/24x18/${countryCode}.png`} alt='flag' />)
    }
    const renderCountryFlag = () => {
      const seletedCountryName = this.state.userData?.country
      if(seletedCountryName && seletedCountryName !== "undefined"){       
        const countries =  Country.getAllCountries(); 
        const countryData =  countries.find((val: any) =>  val.name === seletedCountryName);
        return <span style={{height: 'fit-content', marginTop: 'auto', marginBottom: 'auto'}}>{flagemojiToPNG(countryData?.flag)}</span>
      }
    }

    const renderCountrySection = () => {
      const country = this.state.userData?.country;
      return(<div style={{ display: 'flex', gap: '10px' }}>
        {renderCountryFlag()}      
        <Typography className={classes.contryName}>
          {this.renderCity()}
          {country ? country: ""}
        </Typography>
      </div>)
    }
    const { classes} = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Header  />
        <Snackbar 
          style={{ 
            top: "80px", 
            boxShadow: "0px 6px 15px -3px #00000026"
          }} 
          open={this.state.showSuccMsg} 
          onClose={this.onProfileSnackBarClose} 
          autoHideDuration={3000} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert 
          severity={this.state.messageType} 
          style={{ 
            backgroundColor: "white", 
            borderRadius: "8px",
            display: "flex", 
            alignItems: "center"
          }} 
          iconMapping={{ success:<img src={greenTick} width={ 20 } height={20} />}}
          >
            <Typography> {this.state.messageDesctiption} </Typography>
          </Alert>
        </Snackbar>
        <div className= {classes.container}>
          <div className= {classes.row}>
            <Box className= {classes.profileCard}>
              <Box className= {classes.proifeCardLeft}>
                <Box className= {classes.titleWrapStyles}>
                  {this.state.profilePic !== null ?
                    <img src={this.state.profilePic} style={{ height: 120, width: 120, border: "5px solid #2966FF", background: "rgb(203, 213, 225)" }} className={classes.profileimg}></img>
                    :
                    <Icon>
                      <PersonOutlineOutlinedIcon style={{ height: 120, width: 120, border: "5px solid #2966FF", background: "rgb(203, 213, 225)" }} className={classes.profileimg} />
                    </Icon>
                  }
               
                </Box>
                <Box style={{ flexDirection: 'column'}} className= {classes.userDataContainer}>
                  <Typography className= {classes.profileName} >{this.state.userData?.first_name} {this.state.userData?.last_name}</Typography>
                  {renderCountrySection()}                    
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <MailOutlineIcon style={{ height: 25, width: 20, color: "#64748B" }} />
                      <Typography className={classes.userEmail}>
                        {this.state.userData?.email}</Typography>
                    </div>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <PhoneIcon style={{ height: 25, width: 20, color: "#64748B" }} />
                      <Typography className={classes.userPhone}>{this.state.userData?.phone_number}</Typography>
                    </div>
                  </div>
                    <Typography style={{display: 'flex'}}> <img src={star} height={24} width={24}/> {this.state.userData?.rating ?? 0}</Typography>
                </Box>
              </Box>
              <Button onClick={this.redirectToEditProfile} style={{ textTransform: "none"}} className={classes.editBtn}><img src={Editt} /> Edit Profile</Button>
            </Box>
            <Typography variant="h5">Saved Cards</Typography>
            {this.state.cardData.length > 0 && this.state.cardData.map((item: any, index: number) => {
              return (
                <Box key={item.id} className={classes.profileCard} style={{ flexDirection: 'column' as const }}>
                  <Grid container>
                    <Grid item xs={1}>
                      <div style={{ padding: '3px 0 0 0' }}>
                      <Radio checked={item.default} onChange={() => this.updateDefaultCardDetails(item.id)} data-test-id={`updateDefaultCard-${index}`} color="primary" />
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <CreditCardIcon style={{ padding: '10px 0 0 0' }} />
                    </Grid>
                    <Grid item xs={5}>
                      <div style={{ padding: '0px 0 0 0' }}>
                        <Typography className={classes.cardNameInfo}>{this.generateAbbreviation(item.issuer)} {this.capitalizeFirstLetterOfEachWord(item.card_type)} Card  ****{item.card_number}</Typography>
                        <Typography className={classes.subCardDetail}>{item.card_holder_name !== null ? item.card_holder_name : 'Card Holder Name' }</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ padding: '10px 0 0 0' }}>
                        <Typography className={classes.lastTimeCardInfo}>Last time used : {item.last_time_used !== null ? moment.unix(item.last_time_used).format('ddd D MMM, YYYY')  : '-' }</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ display: 'flex', gap: 20, padding: '10px 0 0 0' }}>
                        <img style={{ cursor: 'pointer' }} data-test-id={`editIcon-${index}`} onClick={() => { this.setState({ cardInfo: item, updateCardModel: true})}} src={edit} alt="editIcon" />
                        <img style={{ cursor: 'pointer' }} data-test-id={`deleteIcon-${index}`} onClick={() => { this.setState({ cardInfo: item, deleteCardModel: true})}} src={deleteimg} alt="deleticon" />
                      </div>

                    </Grid>
                  </Grid>
                </Box>
              )

            })}
            <Button data-test-id="addCard" className={classes.plusIcon} onClick={() => this.setState({ cardModal : true})}><img src={plusIcon} width={20} height={20} style={{paddingRight: '10px'}}/> Add More Cards</Button>
            {
              this.state.creditCardsModal && <SearchContractor 
                navigation={""}
                history={() => { } }
                id={""}
                showContractorModal={this.state.creditCardsModal}
                modalContractorType={"crediCard"}
                closeModal={this.closeCreditCardModal}
                location={""} 
                contractorData={undefined} 
                onPregressDone={() => {} } 
                onSubmitRating={() => {}} 
                onSubmitSelectedContractor={() => {}}
              />
            }
          </div>
        </div>
        
        {/* Add Card  Modal */}
        <Dialog
          open={this.state.cardModal}
          keepMounted
          onClose={() => this.handleCardModel()}
          data-test-id='closeCardModel'
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div style={{ padding: 33 }}>
            <DialogTitle id="alert-dialog-slide-title">
              <div style={{
                textAlign: 'center', fontSize: "24px", color: "#2966FF",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                letterSpacing: "-0.12px", padding: "10px 10px 4px 10px", margin: 0
              }}>
                Add New Card
              </div>
            </DialogTitle>
            <DialogContent>
              <Elements stripe={stripePromise}>
                <CheckoutForm data-test-id='checkoutForm' addCardDetail={(token: string) => this.addCardDetails(token)} handleClose={() => this.handleCardModel()} />
              </Elements>
            </DialogContent>
          </div>
        </Dialog>

        {/* Update Card Modal */}
        <Dialog
          open={this.state.updateCardModel}
          keepMounted
          onClose={() => this.handleUpdateCardModel()}
          data-test-id='closeUpdateCardModel'
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div style={{ padding: 33 }}>
            <DialogTitle id="alert-dialog-slide-title">
              <div style={{
                textAlign: 'center', fontSize: "24px", color: "#2966FF",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                letterSpacing: "-0.12px", padding: "10px 10px 4px 10px", margin: 0
              }}>
                Update Card
              </div>
            </DialogTitle>
            <DialogContent>
                <UpdateCard data-test-id="updateForm" cardInfo={this.state.cardInfo} updateCardInfo={(payload: any) => this.editCardDetails(payload)} handleModelClose={() => this.handleUpdateCardModel()} />
            </DialogContent>
          </div>
        </Dialog>

        {/* Delete Card Model */}
        <Dialog className={classes.deletepopupDeleteCardModal}
          open={this.state.deleteCardModel}
          keepMounted
          onClose={this.handleDeleteCardModel}
          data-test-id='closeCardDeleteModel'
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Typography variant='h2' align='center' className={classes.popuptitleDeleteCardModal}>Are you sure?</Typography>

            <DialogContentText id="alert-dialog-slide-description">
              <Typography align="center" className={classes.popupdetailDeleteCardModal}>After deleting record, you won't be able
                to retrieve this information
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={this.handleDeleteCardModel} data-testId='noDeleteBtn' className={classes.noButtonDeleteCardModal}>No</Button>
            <Button className={classes.deleteButtonDeleteCardModal} type="button" data-testId='DeleteBtn' onClick={() => this.deleteCardDetails(this.state.cardInfo.id)}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export { Profile }
export default withStyles(ProfileScreenStyle)(Profile);
// Customizable Area End
