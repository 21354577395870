import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Country, City }  from 'country-state-city';
import { handleErrorMsgText } from "../../../components/src/assets";
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    location?: any;
    history?: any;
    // Customizable Area End
}


interface ErrorObjInterface {
    emailError?: string,
    confirmPasswordError?: string,
    skillError?: string,
    experienceError?: string
    phoneNumberError?: string,
}

interface SkillInterface {
    type: string
    attributes: {
        name: string
    }
    id: string

}

interface S {
    password: string;
    email: string;
    enablePasswordField: boolean;
    enableConfirmPasswordField: boolean;
    checkedRememberMe: boolean;
    imgPasswordVisible: any;
    labelHeader: string;
    btnTxtLogin: string;
    labelRememberMe: string;
    btnTxtSocialLogin: string;
    labelOr: string;
    tabValue: number;
    showPassword: boolean;
    confirmPassword: string;
    termsAndService: boolean;
    firstNameError: boolean;
    lastNameError: boolean;
    phoneNumberError: boolean;
    signUpDisable: true;
    emailError: boolean;
    yearOfExperience: string;
    passwordError: boolean;
    experienceError: boolean;
    confirmPasswordError: boolean;
    skillError: boolean;
    skill: string;
    token: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    signupError: string;
    errorObj: ErrorObjInterface;
    isAllFieldsFilled: boolean;
    signupSuccessMessage: string;
    snackbarOpen: boolean;
    modalOpen: boolean;
    skills: Array<SkillInterface>;
    termsAndCondition: boolean;
    switchTabDataTwo:Array<string>;
    activeSwitchTabTwo: string,
    openPopOver: boolean,
    anchorElePopOver: any,
    countryCodesData: any;
    selectedCountryCode: any;
    countryData: any;
    selectedCountry: any;
    cityData:any;
    selectedcity: any;
    termsAndConditionData : any;
    emailVerifyLoding : boolean;
    msgType: any;
    msgInfo: any;
}

interface SS {
    id: any;

}

export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiEmailLoginCallId: string = "";
    validationApiCallId: string = "";
    signupApiCallId: string = ""
    skillApiCallId: string = "";
    labelTitle: string = "";
    countryCodesApiCallId: string = ""; 
    termsAndConditionApiCallId: string = "";
    emaiVerificationApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            enablePasswordField: false,
            enableConfirmPasswordField: false,
            checkedRememberMe: false,
            imgPasswordVisible: configJSON.imgPasswordVisible,
            labelHeader: configJSON.labelHeader,
            btnTxtLogin: configJSON.btnTxtLogin,
            labelRememberMe: configJSON.labelRememberMe,
            btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
            labelOr: configJSON.labelOr,
            tabValue: 0,
            showPassword: false,
            emailError: false,
            passwordError: false,
            experienceError: false,
            confirmPasswordError: false,
            skillError: false,
            firstNameError: false,
            lastNameError: false,
            skill: '',
            yearOfExperience: '',
            email: "",
            password: "",
            firstName: '',
            lastName: '',
            phoneNumber: '',
            confirmPassword: '',
            termsAndService: false,
            phoneNumberError: false,
            signUpDisable: true,
            token: '',
            signupError: '',
            errorObj: {
                emailError: '',
                skillError: '',
                phoneNumberError: '',
                experienceError: '',
                confirmPasswordError: '',
            },
            isAllFieldsFilled: false,
            snackbarOpen: false,
            modalOpen: false,
            termsAndCondition: false,
            signupSuccessMessage: '',
            skills: [],
            switchTabDataTwo:["Customer","Contractor"],
            activeSwitchTabTwo:"Customer",
            openPopOver: false,
            anchorElePopOver: null,
            countryCodesData: [],
            selectedCountryCode: "+1",
            countryData: [],
            selectedCountry: "",
            cityData: [],
            selectedcity: "",
            termsAndConditionData: "",
            emailVerifyLoding: true,
            msgInfo: "",
            msgType: "",
        };

        this.labelTitle = configJSON.labelTitle;


        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        const { firstName, lastName, email, phoneNumber, password, confirmPassword } = this.state;
        const {
            firstName: prevFirstName,
            lastName: prevLastName,
            email: prevEmail,
            phoneNumber: prevPhone,
            confirmPassword: prevConfirmPassword,
            password: prevPassword,

        } = prevState;

        if (
            lastName !== prevLastName ||
            firstName !== prevFirstName ||
            email !== prevEmail ||
            phoneNumber !== prevPhone ||
            confirmPassword !== prevConfirmPassword ||
            password !== prevPassword

        ) {
            this.checkAllFieldsFilled();
        }
    }

    async componentDidMount() {
        if(this.props.location !== undefined) {
            const activeTab = (this.props.location.state && this.props.location.state.activeTab)|| "Customer";
            this.setState({
                activeSwitchTabTwo : activeTab
            })
        }
        this.getAllSkills();
        this.getCountryCodes();   
        this.getTermsAndConditions(); 
        const searchParam = window.location.search 
        
        if(searchParam) {
            const token = searchParam.split("=")[1]
            this.handleAccountVerification(token)
        }
        const countries =  Country.getAllCountries();   
        this.setState({ countryData: countries })
    }

    onCountryChange=(event: any, newValue: any)=>{
        let cities: any = [];
        if(newValue){
            cities = City.getCitiesOfCountry(newValue.isoCode); 
        }               
        this.setState({ selectedCountry : newValue, cityData: cities, selectedcity: ""})
    }

    onCityChange = (event: any, newValue: any) => {
        this.setState({ selectedcity : newValue})
    }

    checkAllFieldsFilled = () => {
        const { firstName, lastName, email, phoneNumber, password, confirmPassword } = this.state;
        if (firstName && lastName && email && phoneNumber && password && confirmPassword) {
            this.setState({ isAllFieldsFilled: true });
        } else {
            this.setState({ isAllFieldsFilled: false });
        }
    };

    handleSkillChange = (e: any) => {
        this.setState({ skill: e.target.value })
    }
    handleExperienceChange = (e: any) => {
        this.setState({ yearOfExperience: e.target.value })
    }

    handleClickShowPassword = () => {
        this.setState({
            enablePasswordField: !this.state.enablePasswordField,
        });
    };
    handleClickConfirmShowPassword = () => {
        this.setState({
            enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
        });
    };

    handleFirstNameChange = (e: any) => {
        this.setState({ firstName: e.target.value })
    }
    handleLastNameChange = (e: any) => {
        this.setState({ lastName: e.target.value })
    }
    handlePasswordChange = (e: any) => {
        this.setState({
            password: e.target.value
        });
    }

    handleConfirmPassword = (e: any) => {
        this.setState({
            confirmPassword: e.target.value
        })
    }

    setRememberMe = (value: boolean) => {
        this.setState({ checkedRememberMe: value });
    };

    handleTabChange = (e: React.ChangeEvent<{}>, val: number) => {
         /* istanbul ignore next */
        this.setState({
            tabValue: val,
            enablePasswordField: false,
            enableConfirmPasswordField: false,
            checkedRememberMe: false,
            imgPasswordVisible: configJSON.imgPasswordVisible,
            labelHeader: configJSON.labelHeader,
            btnTxtLogin: configJSON.btnTxtLogin,
            labelRememberMe: configJSON.labelRememberMe,
            btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
            labelOr: configJSON.labelOr,
            showPassword: false,
            emailError: false,
            skillError: false,
            passwordError: false,
            confirmPasswordError: false,
            experienceError: false,
            termsAndService: false,
            phoneNumberError: false,
            skill: '',
            signUpDisable: true,
            token: '',
            firstNameError: false,
            lastNameError: false,
            yearOfExperience: '',
            email: "",
            firstName: '',
            password: "",
            lastName: '',
            confirmPassword: '',
            signupError: '',
            phoneNumber: '',
            errorObj: {
                emailError: '',
                skillError: '',
                phoneNumberError: '',
                confirmPasswordError: '',
                experienceError: ''
            },
            isAllFieldsFilled: false,
            snackbarOpen: false,
            modalOpen: false,
            termsAndCondition: false,
            signupSuccessMessage: '',
        })
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            this.handleApiCalls(apiRequestCallId,responseJson)
             /* istanbul ignore next */
            if (apiRequestCallId === this.signupApiCallId) {
                /* istanbul ignore next */
                if (responseJson && !responseJson.errors && responseJson.data) {
                    const { meta } = responseJson;
                    this.setState({ token: meta.token });
                    localStorage.setItem('signup-token', meta.token);
                    this.setState({
                        signupSuccessMessage: "Successfully Signed Up",
                        signupError: ''
                    }, () => {
                        this.setState({ snackbarOpen: true }, () => {
                            setTimeout(() => {
                                this.handleLogInNaviigation()
                            }, 3000)
                        })
                    });
                } else {
                    const errormsg = handleErrorMsgText(responseJson);
                    this.setState({
                        signupError: errormsg,
                    }, () => {
                        this.setState({ snackbarOpen: true })
                    });

                }
            }
            /* istanbul ignore next */
            if (apiRequestCallId === this.skillApiCallId) {
                /* istanbul ignore next */
                if (responseJson && !responseJson.errors && responseJson.data) {
                    const { data } = responseJson;
                    this.setState({ skills: data })
                    //routing to signin page
                }
            }
        }
    }

    validatePhoneNumber = (number: string) => {
        // You can implement your own phone number validation logic here
        // For example, checking for a specific pattern or format
        const phonePattern = /^(\+\d{1,2}\s?)?(\d{3}[-\s]?)?\d{3}[-\s]?\d{4}$/;
        return phonePattern.test(number);
    };

    validateEmail = (email: string) => {
        // You can implement your own email validation logic here
        // For example, using regular expressions to check the email format
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email)
    };

    validatePassword = (password: string): boolean => {
        // Minimum 8 characters, both uppercase and lowercase, at least one digit, and one special character
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{\=\-\)\(\^%`~+\.:;_])\S{8,}$/;
        return passwordPattern.test(password);
    };

    handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        /* istanbul ignore next */
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ snackbarOpen: false });
    };

    validateForm = () => {
        let errorList: ErrorObjInterface = {}
        if (!this.validateEmail(this.state.email)) {
            errorList.emailError = "Invalid email address"
        }
        if (!this.validatePhoneNumber(this.state.phoneNumber)) {
            errorList.phoneNumberError = "Invalid phone number"
        }
        if (this.state.password !== this.state.confirmPassword) {
            errorList.confirmPasswordError = "Password does not match"
        }
        if (this.state.password === this.state.confirmPassword) {
            if (!this.validatePassword(this.state.password) || !this.validatePassword(this.state.confirmPassword)) {
                errorList.confirmPasswordError = "Password should be a minimum of 8 characters long, contain both uppercase and lowercase characters, at least one digit, and one special character."
            }
        }
         /* istanbul ignore next */
        if (this.state.tabValue === 1) {
            /* istanbul ignore next */
            if (!this.state.skill) {
                errorList.skillError = "Please select skill"
            }
            if (!this.state.yearOfExperience) {
                errorList.experienceError = "Please select experience"
            }
        }
         /* istanbul ignore next */
        if (Object.keys(errorList).length == 0) {
            /* istanbul ignore next */
            this.setState({
                errorObj: {
                    emailError: '',
                    phoneNumberError: '',
                    confirmPasswordError: '',
                    skillError: '',
                    experienceError: ''
                }
            });
            return true;
        } else {
            this.setState({ errorObj: errorList });
            return false;
        }
    }
    handlePhoneNumberChange = (e: any) => {
        const value = e.target.value;
        this.setState({ phoneNumber: value });
    };

    handleIconError() {
        /* istanbul ignore next */
        if (this.state.signupError) {
            return <CancelIcon style={{ color: 'red' }} />
        } else {

            return <CheckCircleOutlineOutlinedIcon style={{ color: 'green' }} />
        }
    }

    handleEnablePassword() {
        /* istanbul ignore next */
        if (this.state.enablePasswordField) {
            return <VisibilityOutlinedIcon />
        } else {
            return <VisibilityOffOutlinedIcon />
        }
    }

    handleEnableConfirmPassword() {
        /* istanbul ignore next */
        if (this.state.enableConfirmPasswordField) {
            return <VisibilityOutlinedIcon />
        } else {
            return <VisibilityOffOutlinedIcon />
        }
    }

    handleEmailChange = (event: any) => {
        const value = event.target.value;
        this.setState({ email: value })
    };

    handleModalOpen = () => {
        this.setState({ modalOpen: true })
    };

    handleModalClose = () => {
        this.setState({ modalOpen: false, termsAndCondition: false })
    };
    handleTermsAndCondition = () => {
        if (this.state.checkedRememberMe) {
            this.setRememberMe(false);
            this.setState({ termsAndCondition: false })
        } else {
            this.handleModalOpen()
        }
    }


    newUserSignup = () => {

        if (!this.validateForm()) return;
        if (this.state.checkedRememberMe) {
            const headers = {
                "Content-Type": 'application/json',
            };
            const httpBodyData = {
                "type": "email_account",
                "attributes": {
                    "first_name": this.state.firstName,
                    "last_name": this.state.lastName,
                    "email": this.state.email,
                    "password": this.state.password,
                    "password_confirmation": this.state.confirmPassword,
                    "phone_number":this.state.phoneNumber,
                    "user_type": "customer",
                    "country": this.state.selectedCountry.name,
                    "city": this.state.selectedcity.name,
                    "country_code": this.state.selectedCountryCode
                }
            };
            const httpBodyForContractor = {
                "type": "email_account",
                "attributes": {
                    "first_name": this.state.firstName,
                    "last_name": this.state.lastName,
                    "email": this.state.email,
                    "password": this.state.password,
                    "password_confirmation": this.state.confirmPassword,
                    "phone_number": this.state.phoneNumber,
                    "country_code": this.state.selectedCountryCode,
                    "user_type": "contractor",
                    "year_of_experience": this.state.yearOfExperience,
                    "accounts_skills_attributes": [
                        {
                            "skill_id": this.state.skill
                        },
                    ],
                }

            }
            const httpBody = {
                data: this.state.activeSwitchTabTwo === "Customer" ? httpBodyData : httpBodyForContractor
            };


            const getValidationsMsg = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.signupApiCallId = getValidationsMsg.messageId;

            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                'accounts'
            );

            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "POST"
            );
            runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
        } else {
            this.setState({
                signupError: 'You have to agree with terms & conditions to signup.'
            }, () => {
                this.setState({ snackbarOpen: true })
            });
        }
    }

    getAllSkills = () => {
        const headers = {
            "Content-Type": 'application/json',
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.skillApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'skills'
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    getCountryCodes = () => {
        const headers = {
            "Content-Type": 'application/json',
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.countryCodesApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'accounts/country_codes'
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    getTermsAndConditions = () => {
        const headers = {
            "Content-Type": 'application/json',
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.termsAndConditionApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'terms_and_conditions'
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handleAccountVerification = (token : any) => {
        const headers = {
             token : token
        };
        let formData = new FormData();
        formData.append("activated", "true")
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.emaiVerificationApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'accounts/update_activation_status'
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handleApiCalls = (apiRequestCallId: string, responseJson: any) => {
       if(apiRequestCallId === this.countryCodesApiCallId) {
          this.setCountryCodeApiDataResponse(responseJson)
       } else if(apiRequestCallId === this.termsAndConditionApiCallId) {
         this.setTermsAndConditionApiDataResponse(responseJson)
       } else if(apiRequestCallId === this.emaiVerificationApiCallId) {
         this.setEmailVerificationResponse(responseJson)
      }
    }

    setEmailVerificationResponse = (responseJson: any) => {
        if(responseJson && responseJson.message) {
            this.setState({ emailVerifyLoding : true, snackbarOpen: true, msgInfo: "Email Verified Successfully", msgType: "success"}, ()=> {
                this.toNavigateLogin()
            })
        } else {
            this.setState({ emailVerifyLoding : true, snackbarOpen: true, msgInfo: "Something went wrong Please Try Again later", msgType: "error"}, ()=> {
                this.toNavigateLogin()
            })
        }
    }

    toNavigateLogin = () => {
        setTimeout(() => {
            this.props.navigation.navigate("Home")
        },1000)
    }
    setTermsAndConditionApiDataResponse = (responseJson: any) => {
        if(responseJson && responseJson.data) {
            const termsData = `${responseJson.data.description}`;
            this.setState({ termsAndConditionData : termsData})
        } else {
            this.setState({ termsAndConditionData : ""})
        }
    }

    setCountryCodeApiDataResponse = (responseJson: any) => {
        if(responseJson && responseJson.data) {
            this.setState({ countryCodesData : responseJson.data})
        } else {
            this.setState({ countryCodesData : []})
        }
    } 

    handleCountryChange = (e:any) => {
        const countryCode = e.currentTarget.value
        const selectedVal = `+${countryCode}`

        this.setState({ selectedCountryCode: selectedVal, openPopOver: false})
    } 
    
    switchTab = (tabName: string) => {
        const errorCheck = {
            emailError: '',
            skillError: '',
            phoneNumberError: '',
            experienceError: '',
            confirmPasswordError: '',
        }

        this.setState({
            activeSwitchTabTwo: tabName,
            selectedCountryCode: "+1",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            phoneNumber: "",
            checkedRememberMe: false,
            selectedcity: "",
            selectedCountry: "",
            skill:"",
            yearOfExperience: "",
            errorObj: errorCheck
        });
    }

    handleLogInNaviigation = () => {
        this.props.history.push({
          pathname: "/",
          state: { activeTab: this.state.activeSwitchTabTwo }
        })
      }
}
