// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Collecttransactionfees from "../../blocks/collecttransactionfees/src/Collecttransactionfees";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
// import NewPassword from "../../blocks/forgot-password/src/NewPassword";
// import CheckMail from "../../blocks/forgot-password/src/CheckMail";
import Customform4 from "../../blocks/customform4/src/Customform4";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import DashboardWeb from "../../blocks/dashboard/src/DashboardBlock.web";
import Company from "../../blocks/dashboard/src/Company.web";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import LoginBlockWeb from '../../blocks/email-account-login/src/LoginBlock.web';
import LandingBlock from '../../blocks/landingpage/src/LandingPage.web';
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import UserProfileWeb from "../../blocks/customisableuserprofiles/src/UserProfile.web"
import EditProfile from "../../blocks/customisableuserprofiles/src/EditProfile.web";
import UserEditProfile from "../../blocks/customisableuserprofiles/src/userEditProfile.web";
import GroupVideoCall from "../../blocks/groupvideocall/src/GroupVideoCall.web";
import Profile from "../../blocks/customisableuserprofiles/src/profile.web";

import SignupBlockWeb from '../../blocks/email-account-login/src/SignupBlock.web';
import firebaseApp from './firebaseConfig'; // Adjust the path based on your project structure
import { getMessaging, getToken } from 'firebase/messaging';
import Loader from "../../components/src/Loader.web";
import NotificationWeb from "../../blocks/emailnotifications2/src/NotificationPage.web"
import ReviewScreen from "../../blocks/reviews/src/ReviewScreen.web"

import EngagemenRequest from "../../blocks/dashboard/src/EngagemenRequest.web"
import EngagemenRequestList from "../../blocks/dashboard/src/EngagemenRequestList.web"
import AccountVerification from "../../blocks/email-account-login/src/AccountVerification.web"
import { baseURL } from '../../framework/src/config';

const routeMap = {
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  AccountVerification: {
    component: AccountVerification,
    path: "/accountsVerification"
  },
  Review: {
    component: ReviewScreen,
    exact: true,
    path: "/Review"
  },
  NotificationWeb: {
    component: NotificationWeb,
    path: "/notification"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Collecttransactionfees: {
    component: Collecttransactionfees,
    path: "/Collecttransactionfees"
  },
  RecommendationEngine: {
    component: RecommendationEngine,
    path: "/RecommendationEngine"
  },
  
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  UserStatus: {
    component: UserStatus,
    path: "/UserStatus"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },

  EngagemenRequest :{
    component: EngagemenRequest,
    path: "/EngagemenRequest"
  },
  EngagemenRequestList :{
    component: EngagemenRequestList,
    path: "/EngagemenRequestList"
  },
//   NewPassword: {
//     component: NewPassword,
//     path: "/NewPassword"
//   },
//  CheckMail: {
//     component: CheckMail,
//     path: "/CheckMail"
//   },
  Customform4: {
    component: Customform4,
    path: "/Customform4"
  },
  LandingBlock: {
    component: LandingBlock,
    path: '/landing',
    exact: true
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  UserProfileWeb: {
    component: UserProfileWeb,
    path: "/UserProfile"
  },
  Company: {
    component: Company,
    path: "/Company"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  Home: {
    component: LoginBlockWeb,
    path: '/',
    exact: true
  },
  Signup: {
    component: SignupBlockWeb,
    path: '/signup',
    exact: true
  },
  
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  Company: {
    component: Company,
    path: '/company',
  },
  EditProfile:{
  component: EditProfile,
  path:'/EditProfile',
  },
  ProfileEdit:{
    component: UserEditProfile,
    path:'/ProfileEdit',
  },
  ContractorDashboard:{
    component: DashboardWeb,
    path:'/ContractorDashboard',
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  GroupVideoCall: {
    component: GroupVideoCall,
    path: "/GroupVideoCall"
  },
  UserProfile: {
    component: Profile,
    path: "/profile"
  }
};


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      token: "",
      inactiveTime: 0,
      intervalId: null,
      loading: true, // Set the initial loading state
    };
  }
   
  onComponentLoad = async (event) => {
    const localStorageToken = localStorage.getItem('signin-token');
    if(localStorageToken){
      event.preventDefault();
      event.returnValue = '';
    }   
    this.setState({ modal: true });
    this.changeAvailabilityApi(false);
  }

  changeAvailabilityApi = async (availability) => {
    try {
      const body = {
          data:{
            attributes:{
              is_available:  availability 
            }
          }
      }
      const response = await fetch(baseURL + "account/change_availability_status", {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token || ""
        },
        body: JSON.stringify(body)
      });
    } catch (error) {
      console.error('Error:', error);      
    } 
  }

  handleVisibilityChange = () => {
    if (document.hidden) {
      const intervalId = setInterval(() => {
        this.changeAvailabilityApi(false); 
        clearInterval(intervalId); 
      }, 5000); // Increase inactive time every second
      this.setState({ intervalId });     
    } else {
      clearInterval(this.state.intervalId);
      this.changeAvailabilityApi(true);
    }
  };
  
  async componentDidMount() {
    this.storeFireBaseToken();     
    const localStorageUserData = localStorage.getItem("userData");  
    const token = localStorage.getItem('signin-token')  
    if(localStorageUserData){
      this.setState({
        token: token
      })
      const userData = JSON.parse(localStorageUserData);
      if(userData.user_type === "contractor"){
        const isAvailable = localStorage.getItem("available");
        if(isAvailable === "true"){
          this.changeAvailabilityApi(false);
        }
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        window.addEventListener("beforeunload",this.onComponentLoad, false);
        window.addEventListener("unload",this.onComponentLoad, false);         
      }
    }    
  }

  async componentWillUnmount(){
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener("beforeunload", this.onComponentLoad, false);   
    window.removeEventListener("unload", this.onComponentLoad, false);
    clearInterval(this.state.intervalId);
  }

  storeFireBaseToken = async () => {
    const messaging = getMessaging(firebaseApp);    
    setTimeout(() => this.setState({ loading: false }), 700); 
    try {      
      const fcmToken = localStorage.getItem('fcmToken');
      if(!fcmToken){
        const currentToken = await getToken(messaging);
        console.log('Current FCM Token:', currentToken);
        localStorage.setItem('fcmToken', currentToken.toString())     
      }     
    } catch (error) {
      console.error('Error initializing Firebase Messaging:', error);
    }
    
  }; 
  render() {

    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {this.state.loading ? <Loader loading={true} />: WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
