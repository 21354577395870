Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.httpGETRequestMethod= "GET";
exports.httpPOSTRequestMethod= "POST";
exports.getEngagemnetApiEndPoint="engagement_records/show_recent_engagement_record"
exports.getCollectFeesApiEndpoint="accounts/collecting_fees"
exports.getCardsRecordsApiEndPoint="bx_block_stripe_integration/cards"
exports.addCardsRecordsApiEndPoint="bx_block_stripe_integration/cards"
// Customizable Area End