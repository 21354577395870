// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any
  id: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notificationList:any[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any
  // Customizable Area End
}

export default class NotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationApiCallId: string = "";
  readNotificationApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      notificationList:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        this.handleAPICalls(apiRequestCallId, responseJson)

    }

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
       this.getNotificationListData()
  }


  // Customizable Area Start

  handleAPICalls = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getNotificationApiCallId) {
        this.setNotificationDataResponse(responseJson)
    } else if(apiRequestCallId === this.readNotificationApiCallId) {
        this.readNotificationDataResponse(responseJson)
    }
  }

  readNotificationDataResponse = (responseJson: any) => {
    if(responseJson && responseJson.data) {
       this.getNotificationListData()
    } else {
       console.log("er")
    }
 }

  setNotificationDataResponse = (responseJson: any) => {
     if(responseJson && responseJson.data) {
        this.setState({ notificationList: responseJson.data})
     } else {
        this.setState({ notificationList: []})
     }
  }

  readNotificationData = (id: any) => {
    const header = {
        "Content-Type": configJSON.searchApiContentType,
        token: localStorage.getItem('signin-token')
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.readNotificationApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.readNotificationApiEndPoint}?notification_id=${id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  getNotificationListData = () => {
    const header = {
        "Content-Type": configJSON.searchApiContentType,
        token: localStorage.getItem('signin-token')
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getNotificationApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getNotificationListApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
// Customizable Area End