Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.passworErro = "Incorrect password ";
exports.passworErr2 = "Please type password ";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.email="Email";
exports.infor ="Password";
exports.login ="Login";
exports.remember ="Remember me"
exports.forgotPassword ="Forgot password?"
exports.dont ="Don't have an account?"
exports.years= "Years of Experience"
exports.firstName ="First Name"
exports.lastname ="Last Name"
exports.phoneNum ="Phone Number"
exports.confirm="Confirm Password"
exports.accept ="Accept the Terms of Service"
exports.SignUp ="Sign up"
exports.terms="Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary." 
exports.skills="Skills"
exports.selectSkill ="Select Skill"
exports.experience="Experience"
exports.one="1"
exports.two="2"
exports.three="3"
exports.four="4"
exports.five="5"
exports.six="6"
exports.seven="7"
exports.eignt="8"
exports.nine="9"
exports.ten="10"
exports.tenPlus="10+"
// Customizable Area End