import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Country }  from 'country-state-city';
import { toast } from "react-toastify"
import { cropImage } from "../../emailnotifications2/src/assets";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

interface CertificateFields {
  name: string,
  issuing_organization: string,
  credential_id: string,
  credential_url: string,
}

interface CertificateFieldsErrors {
  name: boolean,
  issuing_organization: boolean,
  credential_id: boolean,
  credential_url: boolean,
  issue_date: boolean,
  expiration_date?: boolean
}

interface EditProfileFieldsErrors {
  first_name: boolean,
  last_name: boolean,
  phone_number: boolean,
  country: boolean,
  city: boolean,
  year_of_experience: boolean,
  contractor_price: boolean,
  password: boolean
}
interface AddAccountInfo {
  bankName: string,
  accNo: string,
  routingNo: string,
  accHolderName: string,
}
interface AddAccountInfoError {
  bankName: boolean,
  accNo: boolean,
  routingNo: boolean,
  accHolderName: boolean,
}
interface EditAccountInfoError {
  accHolderName: boolean,
}

export interface CountryCodesInterface {
  id: string;
  type: string;
  attributes: {
    name: string;
    country_code: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  IsOpen: boolean
  open: boolean;
  handleClose: any;
  handleDialogClose: any;
  display: boolean;
  Isdisplay: boolean;
  Isvisible: boolean;
  visible: boolean;
  show: boolean;
  handleEditBankClose: any;
  handleDeleteBankClose: any;
  handleEditcertificateClose: any;
  handleDeleteCertificationClose: any;
  handleEditcertificateModalClose: any;
  certificateData?: any
  history?: any;
  location?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;
  open: boolean;
  Isopen: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;
  display: boolean;
  Isdisplay: boolean;
  Isvisible: boolean;
  visible: boolean;
  show: boolean;
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;
  saveButtonDisable: boolean;
  enableField: boolean;
  addCertificateForm: CertificateFields;
  certificateErrors: CertificateFieldsErrors;
  issueDate: string;
  expDate: string;
  minDateExp : any;
  certificateData : any;
  certificateId : any;
  certificateItem: any;
  editCertificateForm: CertificateFields
  editCertificateErrors: CertificateFieldsErrors;
  contractorProfileData : any;
  editProfileData: any;
  skillsData: any[];
  country: string;
  region: string;
  cityData: string;
  editProfileErrorFields: EditProfileFieldsErrors;
  profileImg: any;
  hasImgChange: boolean,
  bankAcoountData : any;
  addAccountData : AddAccountInfo;
  addAccountDataErr: AddAccountInfoError;
  bankAccountInfo: any;
  editAccountErr: EditAccountInfoError;
  subSkillsData: any;
  skillId: any;
  subSkillsId: any;
  defaultSubskills: any;
  pageLoader: boolean;
  countryFlag: any,
  addCardCustomErrMsg : string,
  selectedDomain: any;

  // NEw
  countryCodes:string
  selectedCountryCode: string,
  openPopOver: boolean,
  anchorElePopOver: HTMLElement | null,
  countryCodesData: Array<CountryCodesInterface>;
  showSuccMsg: boolean;
  messageType: any;
  messageDesctiption: string;
  updatePassword: string;
  updatePasswordShow: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  addCertificateApiCallId: string = "";
  getCertificateApiCallId: string = "";
  deleteCertificateApiCallId: string = "";
  editCertificateApiCallId: string = "";
  getSkillsApiCallId: string = "";
  editContractorProfileApiCallId : string = "";
  getBankAccountDetailsApiCallId : string = "";
  addBankAccountDetailsApiCallId : string = "";
  deleteBankAccountDetailsApiCallId: string = "";
  editBankAccountDetailsApiCallId: string = "";
  countryCodesApiCallId:string="";
  selectedCountryCode: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      open: false,
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      Isopen: false,
      display: false,
      Isdisplay: false,
      Isvisible: false,
      visible: false,
      show: false,
      addCertificateForm: {
        name: "",
        issuing_organization: "",
        credential_id: "",
        credential_url: "",
      },
      certificateErrors: {
        name: false,
        issuing_organization: false,
        credential_id: false,
        credential_url: false,
        issue_date: false,
        expiration_date: false,
      },
      issueDate: "",
      expDate: "",
      minDateExp: new Date(),
      certificateData: [],
      certificateId: "",
      certificateItem: {},
      editCertificateForm: {
        name: "",
        issuing_organization: "",
        credential_id: "",
        credential_url: "",
      },
      editCertificateErrors: {
        name: false,
        issuing_organization: false,
        credential_id: false,
        credential_url: false,
        issue_date: false,
        expiration_date: false,
      },
      contractorProfileData: {},
      editProfileData: {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        year_of_experience: "",
        contractor_price: "",
        skills:[]
      },
      skillsData: [],
      country:"",
      region: "",
      cityData: "",
      editProfileErrorFields: {
        first_name: false,
        last_name: false,
        phone_number: false,
        country: false,
        city: false,
        year_of_experience: false,
        contractor_price: false,
        password: false
      },
      profileImg: "",
      hasImgChange: false,
      bankAcoountData: [],
      addAccountData: {
        bankName: "",
        accHolderName: "",
        accNo: "",
        routingNo: "",
      },
      addAccountDataErr: {
        bankName: false,
        accHolderName: false,
        accNo: false,
        routingNo: false,
      },
      bankAccountInfo: {},
      editAccountErr: {
        accHolderName: false,
      },
      subSkillsData: [],
      skillId: "",
      subSkillsId: "",
      defaultSubskills: [],
      pageLoader: true,
      countryFlag: "",
      addCardCustomErrMsg: "",
      selectedDomain: "",

      //New
      countryCodes:"",
      selectedCountryCode: "+1",
      openPopOver: false,
      anchorElePopOver: null,
      countryCodesData: [],
      showSuccMsg: false,
      messageType: "",
      messageDesctiption: "",
      updatePassword: "",
      updatePasswordShow: true
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
     this.getCountryCodes();
     this.getCertificateData()
     this.getSkillsData()
     this.getBankAccountData();
     this.checkMessageObject();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
      // Customizable Area Start
      this.setContactorProfileDataResponse(responseJson)
      // Customizable Area End
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (responseJson) {
         this.checkOtherResponses(message);
        this.checkProfileAccount(message);
        this.handleAllAPIResponses(apiRequestCallId, responseJson)
        this.handleApiCalls(apiRequestCallId, responseJson)
      } else {
        console.log("here>>>>>>>>>>>>>>>>", responseJson)
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onPasswordUpdateChange = (event: any) => {
    const value = event.target.value;
    this.setState({
      updatePassword: value
    })
  }
  onShowPasswordIconClick = () => {
    this.setState({
      updatePasswordShow: !this.state.updatePasswordShow
    })
  }
  checkMessageObject = () => {
    if(this.props.location.state.messageObj){
      const messageObj = this.props.location.state.messageObj;
      this.setState({
        showSuccMsg: true,
        messageType: messageObj.messageType,
        messageDesctiption: messageObj.messageDesctiption
      })
      window.scroll(0,0);
      setTimeout(() => this.props.history.replace(window.location.pathname), 1500)
    } 
  }

  onSnackBarClose = () => {
    this.setState({ showSuccMsg : false, messageType: "", messageDesctiption:""})    
  }

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };


  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };


  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
    this.fetchAttributes();
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = (onApiresponse?: boolean) => {
    if(onApiresponse){

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
          
    }
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClose = () => {
    this.setState({
      open: false, 
      addCertificateForm: {
        name: "",
        issuing_organization: "",
        credential_id: "",
        credential_url: "",
      },
      certificateErrors: {
        name: false,
        issuing_organization: false,
        credential_id: false,
        credential_url: false,
        issue_date: false,
        expiration_date: false,
      },
      issueDate: "",
      expDate: "",
      minDateExp: new Date()
    });
  };

  openAddCertificationModal = (e: any) => {
    e.preventDefault();
    this.setState({ open: true });
  };
  openEditCertificateModal = () => {
    const editModalValues = {
        name: this.state.certificateItem?.name,
        issuing_organization: this.state.certificateItem?.issuing_organization,
        credential_id: this.state.certificateItem?.credential_id,
        credential_url: this.state.certificateItem?.credential_url,
    }
    this.setState({ show: true, editCertificateForm: editModalValues, certificateId: this.state.certificateItem?.id, issueDate: this.state.certificateItem?.issue_date, expDate: this.state.certificateItem?.expiration_date });
    
  }

  openBankDetailsModal = () => {
    this.setState({ Isopen: true });
  }
  openEditBankDetailsModal = (item: any) => {
    this.setState({ display: true, bankAccountInfo: item });
  }
  openDeleteBankDetailsModal = (item: any) => {
    this.setState({ Isdisplay: true, bankAccountInfo: item });
  }
  openEditcertificate = (item : any) => {
    this.setState({ Isvisible: true, certificateItem: item });
  }
  handleDialogClose = () => {
    const errMsg = {
        bankName: false,
        accHolderName: false,
        accNo: false,
        routingNo: false,
    }
    this.setState({ Isopen: false, addCardCustomErrMsg : "", addAccountDataErr : errMsg });
  }
  handleEditcertificateClose = () => {
    this.setState({ Isvisible: false });
  }
  handleEditcertificateModalClose = () => {
    this.setState({ show: false });
  }
  handleEditBankClose = () => {
    this.setState({ display: false });
  }
  handleDeleteBankClose = () => {
    this.setState({ Isdisplay: false });
  }
  handleEditProfileClick = () => {
    this.props.navigation.navigate('EditProfile')
  }
  handleCloseEdit = () => {
    this.props.navigation.navigate('UserProfileWeb')
  }
  openDeleteCertificationModal = () => {
    this.setState({ visible: true });
  }
  handleDeleteCertificationClose = () => {
    this.setState({ visible: false });
  }

  handleAllAPIResponses = (apiRequestCallId: any, responseJson: any ) => {
    switch (apiRequestCallId) {

      case this.addCertificateApiCallId:
        this.addCertificateDataResponse(responseJson)
        break;
      case this.getCertificateApiCallId:
        this.setCertificateDataResponse(responseJson)
        break;
      case this.deleteCertificateApiCallId:
        this.deleteCertificateDataResponse(responseJson)
        break;
      case this.editCertificateApiCallId:
        this.editCertificateDataResponse(responseJson)
        break;
      case this.editContractorProfileApiCallId:
        this.editContractorProfileDataResponse(responseJson)
        break;
      case this.getSkillsApiCallId:
        this.setSkillsDataResponse(responseJson)
        this.getCustomizableProfile(true)
        break;
      case this.addBankAccountDetailsApiCallId:
        this.addBankAccountDataResponse(responseJson)
        break;
      case this.getBankAccountDetailsApiCallId:
        this.setBankAccountData(responseJson)
        break;
      case this.deleteBankAccountDetailsApiCallId:
        this.deleteBankAccountDataResponse(responseJson)
        break;
      case this.editBankAccountDetailsApiCallId:
        this.editBankAccountDataResponse(responseJson)
        break;  
    }
  }
  setUserProfilePhoto = (responseJson: any) => {
    let oldUserData = localStorage.getItem("userData")
    if (oldUserData !== null) {
      let oldUserDataObj = JSON.parse(oldUserData)
      if (responseJson.data.attributes.photo && oldUserData) {
        const newProfileImage = responseJson.data.attributes.photo
        oldUserDataObj.photo = newProfileImage
      }
      localStorage.setItem("userData", JSON.stringify(oldUserDataObj))
    }
  }

  editContractorProfileDataResponse = (responseJson: any) => {
    let messageObj;
    if (responseJson?.data) {      
      messageObj = {
        showSuccMsg: true, 
        messageType: 'success',
        messageDesctiption: "Profile Updated Successfully"
      }
      this.setUserProfilePhoto(responseJson)       
    } else {     
      messageObj = {
        showSuccMsg: true, 
        messageType: 'error',
        messageDesctiption: "Something went wrong"
      }
    }
    this.props.history?.push("/UserProfile", {messageObj: messageObj});
  }

  setSkillsDataResponse = (responseJson: any) => {
    if(responseJson && responseJson.data) {
      const skillsData = responseJson.data
      const selectedPlatform = skillsData.find((val: any) => val.attributes.checked);
      const dataList = selectedPlatform?.attributes?.sub_skills.data||[]; 
      let skillObj = {};
      const subSkillsData = dataList.filter((val: any) => val.attributes.checked);
      if(selectedPlatform){
        skillObj= {name: selectedPlatform?.attributes.name, id:selectedPlatform?.id}  
      }
      let subSkillIds: any = [];
      let defaultSubskills:any = [];       
      if(subSkillsData?.length >0) {
        subSkillsData.map((subSkillVal: any) => {
          defaultSubskills.push(subSkillVal);
          subSkillIds.push({ "sub_skill_id": subSkillVal.id, "checked": true })
        })                
      } 
      this.setState({ skillsData : responseJson.data, 
        subSkillsData: dataList, 
        selectedDomain: skillObj,
        defaultSubskills: defaultSubskills, 
        subSkillsId: subSkillIds,
      })
    } else {
     this.setState({ skillsData : []})
    }
 }

  setCertificateDataResponse = (responseJson: any) => {
     if(responseJson && responseJson.data) {
        this.setState({ certificateData : responseJson?.data})
     } else {
      this.setState({ certificateData : []})
     }
  }

  addCertificateDataResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.getCertificateData()
      toast.success("Certificate Added Successfully")
      this.handleClose()
    } else {
      toast.error("Something went wrong")
    }
  }

  deleteCertificateDataResponse = (responseJson: any) => {
    if(responseJson && responseJson.message){
      this.setState({ Isvisible: false, visible: false}, () =>{
        
        toast.success(`${responseJson.message}`)
      })
    } else {
      toast.error("Something went wrong")
    }
    this.getCertificateData()
  }

  editCertificateDataResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.getCertificateData()
      toast.success("Certificate Updated Successfully")
      this.setState({
        show: false, Isvisible: false, issueDate: "",
        expDate: "",
        minDateExp: new Date()
      })
    } else {
      toast.error("Something went wrong")
    }
  }

  setContactorProfileDataResponse = async (responseJson: any) => {
    if(responseJson?.data) {
      let region:string = "";
      let countryFlag: any = "";
      const selectedCountry = responseJson?.data?.attributes.country
      if(selectedCountry && selectedCountry !== "null" && selectedCountry !== "undefined"){       
        const allCountries = Country.getAllCountries(); 
        const countries = allCountries.find((obj:any) => obj.name === selectedCountry) 
        region = countries?.isoCode || "";   
        countryFlag = countries?.flag;
      }
      let profilePic = responseJson?.data?.attributes.photo;
      if(responseJson?.data?.attributes.photo){
        profilePic = await cropImage(responseJson?.data?.attributes.photo, false)
      }
      this.setState({ 
        contractorProfileData : responseJson?.data, 
        editProfileData: responseJson?.data?.attributes, 
        profileImg: profilePic, 
        hasImgChange: false, 
        country: selectedCountry, 
        cityData: responseJson?.data?.attributes.city, 
        region: region,       
        pageLoader: false, countryFlag:countryFlag,
        selectedCountryCode : `+${responseJson?.data?.attributes.country_code}`
      })      
    } else {
     this.setState({ contractorProfileData : []})
    }
 }

  handleFormFields = (e: any) => {
    const { name, value } = e.target;

    if (value === "") {
      this.setState({ certificateErrors: { ...this.state.certificateErrors, [name]: true } })
    } else {
      this.setState({ certificateErrors: { ...this.state.certificateErrors, [name]: false } })
    }

    this.setState({ addCertificateForm: { ...this.state.addCertificateForm, [name]: value } })
  }

  handleEditFormFields = (e: any) => {
    const { name, value } = e.target;

    if (value === "") {
      this.setState({ editCertificateErrors: { ...this.state.editCertificateErrors, [name]: true } })
    } else {
      this.setState({ editCertificateErrors: { ...this.state.editCertificateErrors, [name]: false } })
    }

    this.setState({ editCertificateForm: { ...this.state.editCertificateForm, [name]: value } })
  }

  checkValidationsOnSubmit = () => {
    let newError = {
      name: false,
      issuing_organization: false,
      credential_id: false,
      credential_url: false,
      issue_date: false,
      expiration_date: false,
    }
    let error = false
    if (this.state.addCertificateForm.name === "") {
      newError.name = true
      error = true
    }
    if (this.state.addCertificateForm.issuing_organization === "") {
      newError.issuing_organization = true
      error = true
    }
    if (this.state.issueDate === "") {
      newError.issue_date = true
      error = true
    }
    if (this.state.addCertificateForm.credential_id === "") {
      newError.credential_id = true
      error = true
    }
    this.setState({ certificateErrors: newError })
    return error
  }

  addCertificateData = () => {
    if (this.checkValidationsOnSubmit()) {
      return false

    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };

    const httpBody = {
      "data": {
        "type": "email_account",
        "attributes": {
          ...this.state.addCertificateForm,
          "issue_date": this.state.issueDate,
          "expiration_date": this.state.expDate
        }
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.addCertificateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCertificateApiEndPoint
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleIssueDateFields = (originalDateString : any, isEdit?: boolean) => {
    const originalDate = new Date(originalDateString);

    // Extract day, month, and year components
    const day = originalDate.getDate();
    const month = originalDate.getMonth() + 1; // Months are zero-indexed, so adding 1
    const year = originalDate.getFullYear();

    // Pad day and month with leading zeroes if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Create the desired formatted date string
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

    if(isEdit) {
      this.setState({ issueDate : formattedDate, minDateExp: originalDate ,editCertificateErrors: { ...this.state.editCertificateErrors , "issue_date" : false}})
    } else {
      this.setState({ issueDate : formattedDate, minDateExp: originalDate ,certificateErrors: { ...this.state.certificateErrors , "issue_date" : false}})
    }


  }

  handleExpDateFields = (originalDateString : any, isEdit?: boolean) => {
    const originalDate = new Date(originalDateString);

    // Extract day, month, and year components
    const day = originalDate.getDate();
    const month = originalDate.getMonth() + 1; // Months are zero-indexed, so adding 1
    const year = originalDate.getFullYear();

    // Pad day and month with leading zeroes if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Create the desired formatted date string
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

     if(isEdit) {
      this.setState({ expDate : formattedDate, editCertificateErrors: { ...this.state.editCertificateErrors , "expiration_date" : false}})
     } else {
       this.setState({ expDate : formattedDate, certificateErrors: { ...this.state.certificateErrors , "expiration_date" : false}})
     }


  }

  getCertificateData = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCertificateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCertificateApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteCertificateData = (id: any) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteCertificateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCertificateApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editCertificateData = () => {
    if (this.checkValidationsOnEditSubmit()) {
      return false

    }

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };

    const httpBody = {
      "data": {
        "type": "email_account",
        "attributes": {
          ...this.state.editCertificateForm,
          "issue_date": this.state.issueDate,
          "expiration_date": this.state.expDate
        }
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.editCertificateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editCertificateApiEndPoint}/${this.state.certificateId}`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkValidationsOnEditSubmit = () => {
    let newError = {
      name: false,
      issuing_organization: false,
      credential_id: false,
      credential_url: false,
      issue_date: false,
      expiration_date: false,
    }
    let error = false
    if (this.state.editCertificateForm.name === "") {
      newError.name = true
      error = true
    }
    if (this.state.editCertificateForm.issuing_organization === "") {
      newError.issuing_organization = true
      error = true
    }
    if (this.state.editCertificateForm.credential_id === "") {
      newError.credential_id = true
      error = true
    }
    if (this.state.editCertificateForm.credential_url === "") {
      newError.credential_url = true
      error = true
    }
    this.setState({ editCertificateErrors : newError })
    return error
  }

  convertDateFormat = (dateString: any) => {
    // Split the input string by the hyphen to get month and year
    const [day, month, year] = dateString?.split('-');

  
    // Create a new Date object with the given month and year
    const convertedDate = new Date(year, parseInt(month) - 1);
  
    // Get the month name from the Date object
    const monthName = convertedDate.toLocaleString('default', { month: 'short' });    
  
    // Construct the formatted date
     
    const formattedDate = `${day}/`+`${monthName} ${year}`;

    const displayMonthYear = formattedDate.split("/")[1]
  
    return displayMonthYear;
  }

  getSkillsData = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSkillsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSkillsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onEditFieldChange = (event:any) => {
    const name = event.target.name
    const value = event.target.value
    const editProfileData = this.state.editProfileData;
    const editProfileError  = {
      first_name: false,
      last_name: false,
      phone_number: false,
      country: false,
      city: false,
      contractor_price: false,
      year_of_experience: false,
      password: false
    }
   
    if(value !== ""){
      this.setState({
        editProfileErrorFields: {
          ...editProfileError,
          [name]: false,
      }})
    } else {
      this.setState({
        editProfileErrorFields: {
          ...editProfileError,
          [name]: true,
        },
      });
    }
    editProfileData[name] = value;
    this.setState({ editProfileData:editProfileData })
  }
  onDomainChange = (value:any) => {
    console.log(value, "editProfileData", this.state.defaultSubskills)
    this.setState({ selectedDomain: {id: value.id, name: value.attributes.name }, subSkillsData:  value.attributes.sub_skills.data, subSkillsId: [], defaultSubskills: []})
  }

  validateUserForm = () => {
    if (this.checkValidationsOnEditProfileSubmit()) {
      return false
    }
    this.updatedContractorProfile()
  }

  updatedContractorProfile = () =>  {  
   
    const header = {
       token: localStorage.getItem('signin-token')
    };
    let formdata = new FormData();
    formdata.append("data[attributes][first_name]",this.state.editProfileData.first_name);
    formdata.append("data[attributes][last_name]",this.state.editProfileData.last_name);
    formdata.append("data[attributes][phone_number]",this.state.editProfileData.phone_number);
    formdata.append("data[attributes][year_of_experience]",this.state.editProfileData.year_of_experience);
    formdata.append("data[attributes][hour_rate]",this.state.editProfileData.contractor_price);

    formdata.append("data[attributes][country_code]",this.state.selectedCountryCode);
    
    this.state.hasImgChange && formdata.append("data[attributes][photo]",this.state.editProfileData.photo);
    formdata.append("data[attributes][country]", this.state.country||"")       
    formdata.append("data[attributes][city]", this.state.cityData|| "")

    if(this.state.updatePassword!==""){
      formdata.append("data[attributes][password]", this.state.updatePassword||"");
    }
    
    let selectedId;
    if(this.state.selectedDomain.id){      
      selectedId = this.state.selectedDomain.id;      
    }else{
      const getDefaultDomainObj = this.state.skillsData.filter((val) => val.attributes.name === this.state.selectedDomain.name);
      selectedId = getDefaultDomainObj[0].id;
    } 
    const subSkillsArr: number[] = [];
    this.state.subSkillsId.map((val: any) => {
      subSkillsArr.push(Number(val.sub_skill_id))
    });     

    formdata.append("data[attributes][skills]", selectedId)
    formdata.append("data[attributes][sub_skills]", JSON.stringify(subSkillsArr))

    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
    this.editContractorProfileApiCallId = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getCustomizableProfileEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpPutMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkValidationsOnEditProfileSubmit = () => {
    let newError = {
      first_name: false,
      last_name: false,
      phone_number: false,
      country: false,
      city: false,
      year_of_experience: false,
      contractor_price: false,
      password: false
    }
    let error = false
    if (this.state.editProfileData.first_name === "") {
      newError.first_name = true
      error = true
    }
    if (this.state.editProfileData.last_name === "") {
      newError.last_name = true
      error = true
    }
    if (this.state.editProfileData.phone_number === "") {
      newError.phone_number = true
      error = true
    }
    if (this.state.editProfileData.year_of_experience === "") {
      newError.year_of_experience = true
      error = true
    }
    if (this.state.editProfileData.contractor_price === "") {
      newError.contractor_price = true
      error = true
    }
    if(this.state.updatePassword!==""){
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{\=\-\)\(\^%`~+\.:;_])\S{8,}$/;
      const errorAvailable = passwordPattern.test(this.state.updatePassword);
      console.log(errorAvailable,"errorAvailable")
      newError.password = !errorAvailable;
      error = !errorAvailable;
    }
    this.setState({ editProfileErrorFields : newError })
    return error
  }

  handleEditImageChange = (event:any) => {
    const selectedImage = event.target.files[0];
    const userData = this.state.editProfileData
    userData['photo'] = selectedImage;
    const validFormats = ['image/jpeg', 'image/png'];
    if (selectedImage && !validFormats.includes(selectedImage.type)) {
      window.alert("Invalid file format. Only JPEG and PNG are allowed.");
      return;
    }
    if (selectedImage&&selectedImage.size>1024*1024) {
      window.alert(" File size exceeds 1MB limit ")       
      return ;
    }
    if (selectedImage) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.setState({ profileImg : e.target?.result, hasImgChange: true});
      };

      reader.readAsDataURL(selectedImage);
    }
  };

  setBankAccountData = (responseJson: any) => {
     if(responseJson && responseJson.length ) {
       this.setState({ bankAcoountData : responseJson})
     } else {
      this.setState({ bankAcoountData : []})
     }

  }

  addBankAccountDataResponse = (responseJson: any) => {
    if(responseJson && responseJson.id) {
      this.getBankAccountData()
      this.setState({ Isopen : false, addCardCustomErrMsg : ""})
      toast.success("Bank Account Added Successfully")
    } else if(responseJson && responseJson.errors) {
      let errMsg = responseJson.errors[0]?.bank_detail as string;
      if(errMsg.startsWith("You must use a test bank account number in test mode.")) {
         errMsg = "Invalid Account Number"
      }
      this.setState({ addCardCustomErrMsg : errMsg}) 
     } else {
      const errMsg = responseJson?.bank_detail[0]?.success
      this.setState({ addCardCustomErrMsg : errMsg})
    }

 }

 editBankAccountDataResponse = (responseJson: any) => {
  if(responseJson && responseJson.id) {
    this.getBankAccountData()
    this.setState({ display : false})
    toast.success("Bank Account Updated Successfully")
  } else {
    toast.error("Some Error Occured")
  }

}

 deleteBankAccountDataResponse = (responseJson: any) => {
  if(responseJson && responseJson.message) {
    this.getBankAccountData()
    this.setState({ Isdisplay : false})
    toast.success("Bank Account Deleted Successfully")
  } else {
    toast.error("Some Error Occured")
  }

}

  getBankAccountData = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBankAccountDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBankAccountApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addBankAccountData = () => {
    if (this.checkValidationonAddBankAccount()) {
      return false
    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };

    const httpBody = {
      "data" : {
        "attributes" : {
            "account_number": this.state.addAccountData.accNo,
            "routing_number": this.state.addAccountData.routingNo,
            "account_name": this.state.addAccountData.accHolderName
        }
    }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.addBankAccountDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addBankAccountApiEndPoint
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteBankAccountData = (id: any) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteBankAccountDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteBankAccountApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editBankAccountData = () => {

    if (this.checkValidationonEditBankAccount()) {
      return false
    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };

    const httpBody = {
      "bank_detail_id": this.state.bankAccountInfo.bank_detail_id,
      "data": {
        "account_holder_name": this.state.bankAccountInfo.account_name
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.editBankAccountDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editBankAccountApiEndPoint
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isAccountValid = (val: string):boolean => {
    let result = true  
    if(val && /^\d{12}$/.test(val) ) {
      result = false
    } 
    return result
  }

  isRoutingNoValid = (val: string):boolean => {
    let result = true  
    if(val && /^\d{9}$/.test(val) ) {
      result = false
    } 
    return result
  }

  handleAddBankAccoutDataFormFields = (e: any) => {
    const { name, value } = e.target;

    if (value === "") {
      this.setState({ addAccountDataErr: { ...this.state.addAccountDataErr, [name]: true } })
    } else if (name === "accNo" && this.isAccountValid(value)) {
      this.setState({ addAccountDataErr: { ...this.state.addAccountDataErr, ["accNo"]: true } })
    } else if (name === "routingNo" && this.isRoutingNoValid(value)) {
      this.setState({ addAccountDataErr: { ...this.state.addAccountDataErr, ["routingNo"]: true } })
    } else {
      this.setState({ addAccountDataErr: { ...this.state.addAccountDataErr, [name]: false } })
    }

    this.setState({ addAccountData: { ...this.state.addAccountData, [name]: value } })
  }

  handleUpdatedAccountName = (e: any) => {
    const { name, value } = e.target;

    if (value === "") {
      this.setState({ editAccountErr: { ...this.state.editAccountErr, [name]: true } })
    }  else {
      this.setState({ editAccountErr: { ...this.state.editAccountErr, [name]: false } })
    }


    this.setState({ bankAccountInfo : { ...this.state.bankAccountInfo, 'account_name' : e.target.value}})
  }

  checkValidationonAddBankAccount = () => {
    let newError = {
      bankName: false,
      accNo: false,
      routingNo: false,
      accHolderName: false,
    }
    let error = false
    if (this.state.addAccountData.bankName === "") {
      newError.bankName = true
      error = true
    }
    if (this.state.addAccountData.accNo === "") {
      newError.accNo = true
      error = true
    }
    if (this.state.addAccountData.accHolderName === "") {
      newError.accHolderName = true
      error = true
    }
    if (this.state.addAccountData.routingNo === "") {
      newError.routingNo = true
      error = true
    }
    this.setState({ addAccountDataErr : newError })
    return error
  }

  checkValidationonEditBankAccount = () => {
    let newError = {
      accHolderName: false,
    }
    let error = false

    if (this.state.bankAccountInfo.account_name === "") {
      newError.accHolderName = true
      error = true
    }
    this.setState({ editAccountErr : newError })
    return error
  }

  calculateMonthDuration = (startDateStr: string, endDateStr: string) => {

    if (startDateStr !== undefined && endDateStr !== undefined) {
      // Splitting the date strings to extract day, month, and year
      const startDateParts = startDateStr.split('-').map(Number);
      const endDateParts = endDateStr.split('-').map(Number);

      // Creating date objects from the provided strings
      const startDate = new Date(startDateParts[2], startDateParts[1] - 1, startDateParts[0]);
      const endDate = new Date(endDateParts[2], endDateParts[1] - 1, endDateParts[0]);

      // Calculating the difference in months
      const diffInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

      return diffInMonths;
    } else {
      return 0
    }
    
  }

  changeSubDomain = (newVal: any) => {
    const ids = newVal.map((item: any) => ({ sub_skill_id: Number(item.id), checked: true }));
    this.setState({ subSkillsId : ids, defaultSubskills: newVal})
  }

  getCountryCodes = () => {
    const headers = {
        "Content-Type": configJSON.searchApiContentType,
    };
    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodesApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.countrtApiCall
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}

handleApiCalls = (apiRequestCallId: string, responseJson: {data:Array<CountryCodesInterface>}) => {
  if(apiRequestCallId === this.countryCodesApiCallId) {
     this.setCountryCodeApiDataResponse(responseJson)
  }
}

setCountryCodeApiDataResponse = (responseJson: {data:Array<CountryCodesInterface>}) => {
  if(responseJson && responseJson.data) {
    this.setState({ countryCodesData : responseJson.data})
  } else {
    this.setState({ countryCodesData : []})
  }
} 

handleCountryChange = (event:React.MouseEvent<HTMLLIElement, MouseEvent>) => {
  const countryCode = event.currentTarget.value
  const selectedVal = `+${countryCode}`
  this.setState({ selectedCountryCode: selectedVal, openPopOver: false})} 


  // Customizable Area End
}
