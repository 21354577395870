// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCalling } from "../../../components/src/assets";


// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any
  id: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rating : number,
  accId: string,
  reviewData: any;
  ratingGraphData: any;
  ratingStarData: any;
  snacbarHandle : boolean;
  msgInfo: string;
  msgType: any;
  replyMsg : string;
  loader: boolean;
  currentIndex: number;
  selectedYear: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any
  // Customizable Area End
}

export default class NotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAccountDetailsApiCallId: string = "";
  getReviewDataApiCallId: string = "";
  getRatingGraphApiCallId: string = "";
  saveReplyApiCallId: string = "";
  saveLikeApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      rating: 0,
      accId: "",
      reviewData: [],
      ratingGraphData: {
        professionalism : 0,
        efficiency : 0,
        likelihood : 0
      },
      ratingStarData: {
          one: 0,
          two: 0,
          three: 0,
          four: 0,
          five: 0,
          oneCount: 0,
          twoCount: 0,
          threeCount: 0,
          fourCount: 0,
          fiveCount: 0
      },
      snacbarHandle: false,
      msgInfo: "",
      msgType: "",
      replyMsg: "",
      loader: true,
      currentIndex: 0,
      selectedYear: new Date().getFullYear(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        this.handleAPICalling(apiRequestCallId, responseJson)
    }

    // Customizable Area Start
    // Customizable Area End
  }

  
  async componentDidMount(): Promise<void> {
       this.getAccountData()
       this.getAccountRatingGraphData()
  }


  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.accId !== this.state.accId) {
         this.getAccountRatingData()
    }
    if(prevState.selectedYear !== this.state.selectedYear) {
      this.getAccountRatingGraphData()
      this.getAccountRatingData()
    }
  }

  handleAPICalling = (apiRequestCallId: any, responseJson: any) => {
  
    switch(apiRequestCallId) {
       case this.getAccountDetailsApiCallId :
        this.setRatingApiRespose(responseJson)
        break;
       case this.getReviewDataApiCallId: 
       this.setRatingDataApiRespose(responseJson)
       break;
       case this.getRatingGraphApiCallId: 
       this.setRatingGraphApiRespose(responseJson)
       break;
       case this.saveReplyApiCallId: 
       this.setSavedReplyApiRespose(responseJson)
       break;
       case this.saveLikeApiCallId: 
       this.setSavedLikeApiRespose(responseJson)
       break;
    }
  }

  setRatingGraphApiRespose = (responseJson: any) => {
    if(responseJson && responseJson.reviews) {
      const graphData = {
        professionalism : responseJson.reviews.professionalism,
        efficiency : responseJson.reviews.efficiency,
        likelihood : responseJson.reviews.likelihood
      }

      const ratingStar = {
          one: responseJson.reviews.star.one,
          two: responseJson.reviews.star.two,
          three: responseJson.reviews.star.three,
          four: responseJson.reviews.star.four,
          five: responseJson.reviews.star.five,
          oneCount: responseJson.reviews.star.one_c,
          twoCount: responseJson.reviews.star.two_c,
          threeCount: responseJson.reviews.star.three_c,
          fourCount: responseJson.reviews.star.four_c,
          fiveCount: responseJson.reviews.star.five_c
      }
      this.setState({ ratingGraphData : graphData, ratingStarData: ratingStar, rating: responseJson.reviews.rating})
    } else {
      const initiVal = {
        professionalism : 0,
        efficiency : 0,
        likelihood : 0
      }

      const ratingStarInitData =  {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        five: 0,
        oneCount: 0,
        twoCount: 0,
        threeCount: 0,
        fourCount: 0,
        fiveCount: 0
    }
     this.setState({ ratingGraphData : initiVal, ratingStarData: ratingStarInitData})
    }
  }

  setRatingApiRespose = (responseJson: any) => {
    if(responseJson && responseJson.data ) {
      this.setState({ rating : responseJson.data.attributes.rating, accId: responseJson.data.id})
    } else {
     this.setState({ rating : 0})
    }
  }

  setSavedReplyApiRespose = (responseJson: any) => {
    if(responseJson && responseJson.data ) {
      this.setState({ snacbarHandle : true, msgType: "success" , msgInfo: "Successfully Reply sent to Customer"})
    } else {
      this.setState({ snacbarHandle : true, msgType: "error" , msgInfo: "Something went wrong. Please Try again."})
    }
  }

  setSavedLikeApiRespose = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const newDataArray = [...this.state.reviewData]; // Make a copy of the original array
      newDataArray[this.state.currentIndex].likeBtn = true; // Update the value of the specific item
      this.setState({ snacbarHandle: true, msgType: "success", msgInfo: "Succesfully liked review", reviewData: newDataArray })
    } else {
      this.setState({ snacbarHandle: true, msgType: "error", msgInfo: "Something went wrong. Please Try again." })
    }
  }

  setRatingDataApiRespose = (responseJson: any) => {
    if(responseJson && responseJson.data ) {
      const reviewsData = responseJson.data.map((item: any) => {
        return {
          ...item,
          replyText : "",
          likeBtn : item.attributes.like
        }
      })
      this.setState({ reviewData : reviewsData , loader: false})
    } else {
     this.setState({ reviewData : []})
    }
  }

  getAccountData = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAccountDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRatingsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAccountRatingData = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getReviewDataApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.getRatingDataApiEndPoint}?account_id=${this.state.accId}&year=${this.state.selectedYear}&order=desc` ,
      method:configJSON.httpGetMethod ,
    })

  }

  getAccountRatingGraphData = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getRatingGraphApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.getRatingGeaphApiEndPoint}?year=${this.state.selectedYear}` ,
      method:configJSON.httpGetMethod ,
    })
  }

  handleReplyChange =(e: any, index: any) => {
    const newDataArray = [...this.state.reviewData]; // Make a copy of the original array
    newDataArray[index].replyText = e.target.value; // Update the value of the specific item
    this.setState({reviewData : newDataArray}); // Update the state with the modified array
  }

  clearReplyText = (index: any) => {
    const newDataArray = [...this.state.reviewData]; // Make a copy of the original array
    newDataArray[index].replyText = ""; // Update the value of the specific item
    this.setState({reviewData : newDataArray}); // Update the state with the modified array
  }

  handleLikeBtnFunctionality = (index: any, isLike: boolean) => {

    if(isLike) {

    this.setState({ currentIndex: Number(index)})

    const likeArr = this.state.reviewData[index];
      const header = {
        token: localStorage.getItem("signin-token")
      }

      const formData = new FormData();
      formData.append("receiver_id", likeArr.attributes.reviewer.data.id)
      formData.append("review_id", likeArr.attributes.id)

      this.saveLikeApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.httpPostMethod,
        endPoint: configJSON.saveLikeApiEndPoint,
        body: formData
      })
    } else {
      this.setState({ snacbarHandle : true, msgType: "error" , msgInfo: "You have already like this review"})
    }
    
  }

  sendReplyBtn = (id:any, index: any) => {
    if (id.replyText !== "") {
      const replyArr = this.state.reviewData[index];

      const header = {
        token: localStorage.getItem("signin-token")
      }

      const formData = new FormData();

      formData.append("message", replyArr.replyText);
      formData.append("receiver_id", replyArr.attributes.reviewer.data.id)


      this.saveReplyApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.httpPostMethod,
        endPoint: configJSON.saveReplyApiEndPoint,
        body: formData
      })
      this.clearReplyText(index)
    }  else {
      this.setState({ snacbarHandle : true, msgType: "error" , msgInfo: "Please enter message"})
    }
    
  }

  handleChangedYear = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newVal = e.target.value as number;
    this.setState({ selectedYear : newVal})
  }

 
  // Customizable Area End
}
// Customizable Area End