// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";

const MessageListView = ({ messages, formatAMPM, localParticipantId }) => {
  return (
    <div className="message-container" style={webStyle.messageBoxContainer}>
      {messages?.map((message, i) => {
        const { senderName, message: text } = message;
        const isCurrentUserMsg = localParticipantId === message.senderId;
        return (
          <div style={isCurrentUserMsg ? webStyle.currentUserMsgBox :webStyle.messageBox} key={i}>
            <Typography
              variant="subtitle1"
              style={webStyle.senderName}
              data-testid="senderName"
            >
              {senderName}
            </Typography>
            <h3 style={webStyle.messageText} data-testid="messageText">
              {text}
            </h3>             
          </div>
        );
      })}
    </div>
  );
};

const webStyle = {
  messageBoxContainer: {
    overflow: 'auto',
    padding: 8
  },
  messageBox: {
    margin: 8,
    backgroundColor: "#3b3c4e",
    borderRadius: 8,
    overflow: "hidden",
    padding: 8,
    color: "#fff",
    width: '80%'
  },
  currentUserMsgBox: {
    margin: 8,
    backgroundColor: "#3b3c4e",
    borderRadius: 8,
    overflow: "hidden",
    padding: 8,
    color: "#fff",
    width: '80%',
    marginLeft: "auto"
  },
  senderName: {
    margin: 0,
    padding: 0,
    color: '#acaeb6',
    fontSize: 14
  },
  messageText: {
    margin: 0,
    padding: 0,
    marginTop: 4,
    fontSize: 14,
  },
  time: {
    margin: 0,
    padding: 0,
    opacity: 0.6,
    marginTop: 4,
    fontSize: 14,
    color: 'white',
  },
};

export default MessageListView;
// Customizable Area End
