import React from "react";
// Customizable Area Start
import { Grid,Box,Typography} from "@material-ui/core";
import DashboardController, { Props, configJSON } from "./DashboardController";
import Header from "../../emailnotifications2/src/TopHeader.web";
import { groupbanner, groupicon, expertsicon, founder, globalcustomers, star , profileLogo} from './assets'
import { createTheme, withStyles, Theme, ThemeProvider } from "@material-ui/core/styles";

//Customizable Area End
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const CompanyStyle = (theme: Theme) => ({
    companyBannerTitle: {
        color: "#21272A",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "48px",
        marginBottom: "10px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "110%",
        "@media(max-width: 960px)": {
            fontSize: '30px',
            textAlign: 'center'
        }
    },
 
    Reach: {
        padding: '40px 0',
        display: 'block'
    },
    card: {
        borderRadius: "8px",
        background: "#fff",
        boxShadow:
            "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        display: 'flex',
        gap: '15px',
        padding: '20px',
        "@media(max-width: 960px)": {
            margin: "15px 0"
        }
    },
    cardTitle: {
        color: "black",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "36px",
        fontStyle: "normal",
        fontweight: 800,
        lineHeight: "147.4%",
        letterSpacing: "0.72px"
    },
    cardDetail: {
        color: "#64748B",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "147.4%",
        letterSpacing: "0.48px"
    },

    founder: {
        width: '100%'
    },

    founderName: {
        color: "#2B3377",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "147.4%",
        letterSpacing: "0.48px",
        paddingTop: '10px'
    },

    expertCardProfile: {
        display: "flex",
        gap: '10px'
    },

    founderText: {
        color: "#2B3377",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "30.614px"
    },
    topExpertMember:
    {
        color: "#2B3377",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "147.4%",
        letterSpacing: "0.36px",
    },
    imageTag:
    {
        height:"50px",
        width:"50px", 
        borderRadius:"25px",
        marginTop: "-4"
    },
    expertCard: {
        borderRadius: "8px",
        background: "#FFF",
        boxShadow:
            "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        padding: '15px',
        "@media(max-width: 960px)": {
            margin: "15px 0"
        }
    },
    topExpertMemberdec: {
        color: "#2B3377",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
    },
    
    companyBannerDetail:
    {
        color: "#64748B",
        fontFamily: "Lato",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px"
    },

 expertCardDetail: {
        color: "#64748B",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px"
    },
    rateUs: {
        display: "flex",
        gap: "5px",
        color: "#1E293B",
        fontFamily: "Lato",
        fontSize: "20px",
        fontStyle: "normal",
        fontweight: 500,
        lineHeight: "24px",
        marginTop: '20px'
    },

    landingWrapper: {
        backgroundColor: 'rgb(236,253,253)',
        padding: '128px 0',
        display: 'block',
        position: 'relative',
        "@media(max-width: 960px)": {
            paddingTop: '0 !important',
            marginTop: "0 !important"

        }
    },

  
     container: {
        zIndex: 2,
        position: 'relative',
        maxWidth: '1200px',
        margin: '0 auto !important',
        width: '100% !important',
    },

    titleWrapStyles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    welcomeFormFieldInner: {
        width: '60%'
    },

    landingWrapperBg: {
        position: "absolute",
        bottom: "0",
        right: "20px",
        height: "100%",
        "@media(max-width: 960px)": {
            position: "unset",
            width: '100%',
            height: "400px"

        }
    },

    reachTitle: {
        color: "#21272A",
        textAlign: "center",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "110%",
        marginBottom: "30px",
        "@media(max-width: 599px)": {
            fontSize: '30px'
        }
    }
});

class Company extends DashboardController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }
    render() {
        const { classes } = this.props;
        const { companydata } = this.state
        return (
            <>
                <Header />
                <ThemeProvider theme={theme}>
                    <>
                        <Box className={classes.landingWrapper}>
                           
                            <Box>
                                <img src={groupbanner} className={classes.landingWrapperBg} />
                            </Box>

                            <Grid className={classes.container} justifyContent="space-between" container spacing={3}>
                                <Grid item md={5} sm={12} >
                                    <Box className={classes.titleWrapStyles} >
                                        <Typography  className={classes.companyBannerTitle}>
                                            {configJSON.aboutUs}
                                        </Typography>
                                        <Typography className={classes.companyBannerDetail}>
                                        {companydata?.attributes?.description}
                                       </Typography>           
                                    </Box>                                           
                                </Grid>
                                <Grid item md={5} sm={12}>
                                    <Box>
                                    </Box>
                                </Grid>
                            </Grid>                       
                        </Box>
                        <Box className={classes.Reach}>
                            <Box className={classes.container}>     
                                <Typography align="center" className={classes.reachTitle }>
                                    {configJSON.ourReach}
                                </Typography>                       
                            </Box>
                            <Grid container className={classes.container} spacing={5}>
                                <Grid item md={4} sm={12} xs={12}>
                                    <Box className={ classes.card }>
                                        <Box>
                                            <img src={expertsicon} /></Box>
                                        <Box>
                                            <strong className={ classes.cardTitle }>
                                            {companydata?.attributes?.industry_experts}
                                            </strong>
                                            <Typography className={classes . cardDetail}>
                                                {configJSON.industryExperts}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <Box className={classes. card}>
                                        <Box>
                                            <img src={globalcustomers} />
                                        </Box>
                                        <Box>
                                            <strong className={ classes. cardTitle }>
                                            {companydata?.attributes?.global_customers}
                                            </strong>
                                            <Typography className={ classes.cardDetail }>
                                                {configJSON.globalCustomers}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <Box className={classes.card}>
                                        <Box>
                                            <img src={groupicon} /></Box>
                                        <Box>
                                            <strong className={ classes.cardTitle }>
                                            {companydata?.attributes?.total_engagements}
                                            </strong>
                                            <Typography className={ classes.cardDetail }>
                                                {configJSON.totalEngagements}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid className={ classes.container } justifyContent="space-between" container spacing={5}>
                                <Grid item sm={4} xs={12}>
                                    <img className={classes.founder} src={companydata?.attributes?.image} />
                                </Grid>
                                <Grid className="verticalCenter" item sm={8} xs={12}>
                                    <Box  className={classes.titleWrapStyles}>
                                        <Typography className={classes.reachTitle}>
                                            {configJSON.ourFounder}
                                        </Typography>
                                        <Typography className={classes.companyBannerDetail}>
                                        {companydata?.attributes?.founder_message}   
                                        </Typography>
                                    </Box>
                                    <Box className={classes. welcomeFormFieldInner} >
                                        <Typography className={classes. founderName }>
                                            {configJSON.paul}
                                        </Typography>
                                        <Typography className={classes. founderText}>      
                                            CEO & Founder
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    <Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={classes.Reach} >
                            <Typography align="center" className={ classes.reachTitle}>
                                {configJSON.topExperts}
                                </Typography>
                            <Box>
                            </Box>
                            <Grid container className={classes.container} spacing={4}>
                            {companydata?.attributes?.top_experts.data.map((item: any) =>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Box className ={classes.expertCard}>
                                        <Box className={classes.expertCardProfile}>
                                            <Box>
                                                <img className={classes.imageTag} src={item.attributes.photo === null ? profileLogo : item.attributes.photo } />
                                            </Box>
                                        <Box>
                                                <strong 
                                                className={classes.topExpertMember}>
                                                    {item.attributes.name}
                                                </strong>
                                                <Typography className={classes.topExpertMemberdec}>
                                                  {item.attributes.platform}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography className={classes.expertCardDetail}>
                                            {item.attributes.description.slice(0,200)}

                                        </Typography>
                                        <Box className={classes.rateUs}>
                                            <img src={star} />
                                            <Typography>{item.attributes.rating}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                             )}
                            </Grid>
                        </Box>
                    </>
                </ThemeProvider>
            </>
        )
    }
}
export { Company }
// @ts-ignore
export default withStyles(CompanyStyle)(Company);