// Customizable Area Start
import React from "react";

import {
    Snackbar,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import SignupAccountController, {
    Props,
} from "./SignupAccountController";
import Loader from "../../../components/src/Loader.web";

export default class AccountVerification extends SignupAccountController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.state.emailVerifyLoding && <Loader loading={true} />}
                <Snackbar open={this.state.snackbarOpen} data-test-id="closeSnackBar" onClose={() => this.setState({ snackbarOpen: false })} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert variant="filled" style={{ background: "white", color: this.state.msgType === 'error' ? "red" : "green"}} severity={this.state.msgType}>
                        {this.state.msgInfo}
                    </Alert>
                </Snackbar>
            </>
        );
    }
}

// Customizable Area End