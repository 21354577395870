import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
//@ts-ignore
import firebaseApp from '../../../web/src/firebaseConfig';
import { getMessaging, onMessage } from 'firebase/messaging';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  roomId: any;
  roomData: any;
  token: string;
  videoToken: string;
  contractorId: any;
  loading: boolean;
  userType: string;
  customer: any;
  startTime: any;
  contractorIsJoined: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GroupVideoCallController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createRoomId: string= "";
  startRecordingApiId: string = "";
  stopRecordingRoomId: string = "";
  saveEngagementRecordId: string = "";
  disableMettingId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {   
      roomId: '',
      token:'',
      videoToken: '',
      contractorId: '',
      roomData: {},
      loading: true,
      userType: "",
      customer:{},
      startTime: new Date().toISOString(),
      contractorIsJoined: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.status === 500){         
        return "";
      }
      if (apiRequestCallId === this.createRoomId) {
        if (responseJson.data) {
          const data = responseJson.data;
          this.setState({
            roomData: data.meeting.data.attributes, 
            roomId: data.meeting.data.attributes.roomId,
            videoToken: data.videosdk_token,
            loading: false
          })
        }
      }
      if(apiRequestCallId === this.disableMettingId){
        setTimeout(() => {
          this.recordingStopedSuccessfully();
        },1500)
      }
      if(apiRequestCallId === this.stopRecordingRoomId){
        setTimeout(() => {
          this.recordingStopedSuccessfully();
        },1500)
      }
       
    }
    // Customizable Area End
  }

  // Customizable Area Start
  recordingStopedSuccessfully = () => {
    if(this.state.userType !== "customer"){
      setTimeout(() => {
        this.props.history.replace({
          pathname: "/contractorDashboard" ,
          state: { modalType: 'rating', contractorId: this.state.customer.id, },
        });
      },1000)
      
    }else{         
      let stateObj={};
      if(this.state.contractorIsJoined){
        stateObj = { modalType: 'rating', contractorId: this.state.contractorId }
      }
      this.props.history.replace({
        pathname: "/landing",
        state: stateObj
      });
    }
  }

  onBackButtonEvent = (e:any) => { 
    if(!this.state.contractorIsJoined){     
      e.preventDefault();    
      this.disableMetting();
      e.returnValue = ''
      return ""
    }
  }

  async componentWillUnmount() {     
    window.removeEventListener('popstate', this.onBackButtonEvent);     
  }
  async componentDidMount(){    
    const token = localStorage.getItem('signin-token');
    let userData: any = localStorage.getItem('userData');
    if(userData && userData!==""){
      userData = JSON.parse(userData);
    }
    this.loadContent(userData, token)
    this.initializeFirebaseMessaging();
    this.getBroadcastMessage();
  }
  catchBrowserEvent = () => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);     
  }

  loadContent = (userData: any, token: any) => {
    this.catchBrowserEvent();
    const contractorId = this.props.location.state?.selectedContractorId;
    const engageMentRecords = this.props.location.state?.engageMentRecords;
    if(!this.props.location.state){
      this.props.history.replace({ pathname: userData.user_type === "customer"? "/landing" : "/contractorDashboard" });
    }else if(contractorId){
      const contractorJoined = this.props.location.state?.contractorJoined
      this.setState({ 
        token: token || "", 
        contractorId: contractorId, 
        userType: userData.user_type,
        videoToken: engageMentRecords.videoToken, 
        roomId: engageMentRecords.roomId,
        roomData: engageMentRecords.roomData,
        loading: !contractorJoined
      });
    }else if(engageMentRecords){
      this.setState({ 
        videoToken: engageMentRecords.videosdk_token, 
        roomId: engageMentRecords.meeting_id,
        roomData: {account: userData},
        loading: false,
        userType: userData.user_type,
        customer: engageMentRecords.customer,
        token: token || ""
      },() => {
        this.startRecordingRoom(engageMentRecords.meeting_id);
      });
    }    
  }
  initializeFirebaseMessaging = async () => {
    const messaging = getMessaging(firebaseApp);
    onMessage(messaging, (payload: any) => {
      this.onNotificationMessage(payload)
    });
  };
  getBroadcastMessage = () => {
    const channel = new BroadcastChannel('background-msg-channel');    
    channel.addEventListener('message', (event) => {
      const payload = event.data;
      this.onNotificationMessage(payload)
      // Handle the payload as needed
    });
  }
  onNotificationMessage = (payload: any) => {
    console.log(payload,"video-onNotificationMessage")
    const data = payload.data;     
    if(data["meeting_id"] === this.state.roomId){
      window.alert('Sorry! our Contractor Denied your request');
      this.props.history.replace("/landing")
    }else{
      const msg = payload.notification.body;
      const AcceptedMsg = msg.indexOf("Accepted");
      const contractor = data.contractor ? JSON.parse(data.contractor): {id: ""}
      console.log(AcceptedMsg, contractor,"AcceptedMsg")

      if(contractor.id == this.state.contractorId && AcceptedMsg !== -1){
        this.setState({
          loading: false
        })
      }

    }
  }

  onMeetingLeave = (type?: string) => {
    this.setState({
      loading: true,
    }, () => {
      if(type === "stoprecording"){
        this.stopScreenRecording();
      }else if(type === "disableMeeting"){
        this.disableMetting();      
      } else{
        this.recordingStopedSuccessfully();
      }
    })
  }
  disableMetting = () => {
    const token = this.state.token;
    const headers= {
      'Content-Type': 'application/json',
      token: token
    }
    const httpBody = { "roomId": this.state.roomId };

    this.disableMettingId = this.callAnGroupApi({
      headers: headers,      
      endPoint: "bx_block_video_call/meetings/deactivate_meeting",
      requestType: "POST",
      body: httpBody
    })  
  }
  checkUserType = () => {
    if(this.state.userType !== "customer"){
      this.stopScreenRecording();
    }else{
      this.disableMetting();
    }
  }
  updateContracterIsJoined =(participant:any) => {
    if(!participant.local){
      this.setState({
        contractorIsJoined: true,
        loading: false
      })
    } 
  }
  saveEngagementRecord = () => {
    const token = this.state.token;
    const headers= {
      'Content-Type': 'application/json',
      token: token
    }
    let userId: any = ""
    if(token){       
      userId = localStorage.getItem('userId');
    }              
        
    const httpBody = {
      "data": {
          "attributes": {
              "payment_status": "pending",
              "platform_id": this.props.location.state?.engageMentRecords?.platform_id[0],
              "total_cost": 50,
              "service_start_time": this.state.startTime,
              "engagement_request": this.props.location.state?.engageMentRecords?.message,
              "customer_id": this.state.customer.id,
              "contractor_id": userId,
              "room_id": this.state.roomId
          }
      }
  }
    this.saveEngagementRecordId = this.callAnGroupApi({
      headers: headers,      
      endPoint: "engagement_records",
      requestType: "POST",
      body: httpBody
    })
  }
  callAnGroupApi = (props:any) => {
    const getGroupCallApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getGroupCallApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    getGroupCallApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(props.headers));
    if(props.body){
      getGroupCallApiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(props.body));
    }
    getGroupCallApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),props.requestType);
    runEngine.sendMessage(getGroupCallApiMsg.id, getGroupCallApiMsg);
    return getGroupCallApiMsg.messageId;
  }

  stopScreenRecording = () => {
    const headers= {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    this.stopRecordingRoomId = this.callAnGroupApi({
      headers: headers,      
      endPoint: "bx_block_video_call/meetings/stop_recording",
      requestType: "POST",
      body:{ roomId: this.state.roomId}
    })
  }
  startRecordingRoom = (roomid: any) => {
    const headers= {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    this.startRecordingApiId = this.callAnGroupApi({
      headers: headers,      
      endPoint: "bx_block_video_call/meetings/start_recording",
      requestType: "POST",
      body:{ roomId: roomid}
    })
  }
  updateContractorNotJoinedTheCall = () => {
    window.alert("Contractor Not Joined the call")
  }
  createRoom = () => {
    const headers= {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    this.createRoomId = this.callAnGroupApi({
      headers: headers,      
      endPoint: "bx_block_video_call/meetings/create_meeting",
      requestType: "POST",
      body:{ contractor_id: this.state.contractorId}
    })
    
  }
  // Customizable Area End
}
