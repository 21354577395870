import React from "react";
// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import GroupVideoCallController, { Props } from "./GroupVideoCallController.web";
import Loader from "../../../components/src/Loader.web";
// @ts-ignore
import MeetingContainer from './MeetingContainer.web.js';
// Customizable Area End


export default class GroupVideoCallWeb extends GroupVideoCallController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <React.Fragment>
        {this.state.loading ? <Loader loading={true} />:
        <div>
          {(this.state.roomId && this.state.roomData.account)&& <MeetingContainer 
            meetingId= {this.state.roomId}
            defaultMicOn={false}
            defaultWebcamOn= {false}
            participantName ={`${this.state.roomData.account.first_name} ${this.state.roomData.account.last_name}`}
            onMeetingLeave={this.onMeetingLeave}
            token={this.state.videoToken} 
            updateContracterIsJoined={this.updateContracterIsJoined} 
          />}
        </div>}     
      </React.Fragment>          
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  modalButtonView: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 8,
    boxShadow: 24,   
  },
};
// Customizable Area End
