// Customizable Area Start
import React, { useState }from "react";
import { usePubSub } from "@videosdk.live/react-sdk";

import MeetingChatView from "./MeetingChatView";

const MeetingChat = ({closeChat, localParticipantId}) => {
  const { publish, messages } = usePubSub("CHAT", {});
  const [message, setMessage] = useState("");

  const viewProps = {
    publish,
    messages,
    message,
    setMessage,
    closeChat,
    localParticipantId
  };

  return <MeetingChatView testID="MeetingChatView" {...viewProps} />;
};

export default MeetingChat;
// Customizable Area End
