// Customizable Area Start
import React from "react";


// Customizable Area End

import ReviewController , {
  Props,
} from "./ReviewController";
import Header from "../../emailnotifications2/src/TopHeader.web";
import { Box, Typography, Select, MenuItem, Grid, Snackbar, IconButton } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import { CircularProgressbar } from "react-circular-progressbar";
import { styled, createTheme, ThemeProvider, withStyles, Theme, createStyles} from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from "moment";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import Alert from '@material-ui/lab/Alert';
import Loader from "../../../components/src/Loader.web";
import { profileLogo } from "../../../components/src/assets"
import {greenTick} from "../../forgot-password/src/assets"

const theme = createTheme({
  palette: {
      primary: {
          main: "#2966FF",
          contrastText: "#fff",
      },
  },
});

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#6eebff',
        },
    }),
)(LinearProgress);
export default class NotificationPage extends ReviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  avgRating = (val1 : number, val2: number, val3: number) => {
    const ratingVal = (val1 + val2 + val3)/3 
    return ratingVal.toFixed(1)
  }

  likeIconHandle = (like: boolean, index: any) => {
    return (
      like ? <svg data-test-id={`dislike-${index}`} onClick={() => this.handleLikeBtnFunctionality(index, false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M22.0961 3.4021C20.8537 2.05463 19.149 1.3125 17.2956 1.3125C15.9102 1.3125 14.6415 1.75049 13.5245 2.6142C12.9609 3.05017 12.4503 3.58356 12 4.20612C11.5499 3.58374 11.0391 3.05017 10.4753 2.6142C9.35852 1.75049 8.08978 1.3125 6.70441 1.3125C4.85101 1.3125 3.14612 2.05463 1.90375 3.4021C0.676208 4.73383 0 6.55316 0 8.52521C0 10.5549 0.756409 12.4129 2.38037 14.3725C3.83313 16.1254 5.92108 17.9048 8.33899 19.9653C9.16461 20.6689 10.1005 21.4666 11.0722 22.3162C11.3289 22.541 11.6583 22.6648 12 22.6648C12.3415 22.6648 12.6711 22.541 12.9274 22.3165C13.8992 21.4667 14.8356 20.6688 15.6616 19.9647C18.0791 17.9046 20.1671 16.1254 21.6198 14.3723C23.2438 12.4129 24 10.5549 24 8.52502C24 6.55316 23.3238 4.73383 22.0961 3.4021Z" fill="#2966FF" />
      </svg>
        :
        <svg data-test-id={`like-${index}`} onClick={() => this.handleLikeBtnFunctionality(index, true)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M11.5948 4.49911L12 5.05935L12.4051 4.49913C12.8296 3.91225 13.3075 3.41423 13.8304 3.00973C14.8616 2.21233 16.0232 1.8125 17.2956 1.8125C19.0111 1.8125 20.5819 2.49751 21.7285 3.74103C22.8632 4.97189 23.5 6.66666 23.5 8.52502C23.5 10.4113 22.8038 12.1602 21.2348 14.0533L21.2348 14.0533C19.8158 15.7656 17.7661 17.5144 15.3373 19.5842L15.3372 19.5842L15.3165 19.6019C14.4958 20.3014 13.5646 21.0951 12.5983 21.9401L12.598 21.9404C12.433 22.0848 12.2206 22.1648 12 22.1648C11.7794 22.1648 11.5671 22.0849 11.4016 21.94L11.4013 21.9397C10.4279 21.0887 9.49075 20.29 8.66603 19.587L8.66332 19.5847L8.66329 19.5847C6.2341 17.5146 4.18441 15.7657 2.76535 14.0535C1.19634 12.1602 0.5 10.4113 0.5 8.52521C0.5 6.66666 1.1368 4.97189 2.27135 3.74103C3.41786 2.49752 4.98889 1.8125 6.70441 1.8125C7.97679 1.8125 9.1384 2.21234 10.1694 3.00971L10.1694 3.00973C10.6925 3.41421 11.1706 3.91241 11.5948 4.49911Z" stroke="#2966FF" />
        </svg>)
  }

  RoundedRating = (rating : any ) => {
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5
    return roundedRating;
  };

  handleImage = (item: any) => {
    let image : string = profileLogo
    if(item) {
       image = item
    }

    return image
  }

  previousYearArray = () => {
    const currentYear: number = new Date().getFullYear();
    let last3Years: number[] = [];

    for (let i = 0; i < 3; i++) {
        last3Years.push(currentYear - i);
    }

    return last3Years
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Header/>
        <Loader loading={this.state.loader} />
        <Snackbar open={this.state.snacbarHandle} data-test-id="closeSnackBar" style={{ top: "80px", boxShadow: "0px 6px 15px -3px #00000026"}} onClose={()=> this.setState({ snacbarHandle : false}) } autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={this.state.msgType} style={{ backgroundColor: "white", borderRadius: "8px",display: "flex", alignItems: "center"}} 
        iconMapping={{ success: <img src={greenTick} width={20} height={20} />}}>
          <Typography>{this.state.msgInfo}</Typography>
        </Alert>
        </Snackbar>
        <ThemeProvider theme={theme}>
        <Box className="reviewsMainWrapper">
          <Box className="headerDiv">
            <Typography className="reviewText">Reviews</Typography>
            <Select className="reviewYear" data-test-id="yearChange" value={this.state.selectedYear} onChange={this.handleChangedYear} disableUnderline>
                {this.previousYearArray().map((item: number) => {
                  return (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  )
                })}
            </Select>
          </Box>

          <Grid className="grids" container>
            <Grid item md={6} sm={12}>
              <Box className="reviewsGrid" style={{ marginRight: "10px"}}>
              <Typography className="totalReviewsText">Total Reviews</Typography>
              <Typography className="totalReviewsCount">{this.state.reviewData.length}</Typography>
              <Typography className="totalReviewsInfo"><span className="totalReviewsInfoPercentage">0%</span> Growth in reviews this year</Typography>
              </Box>
              
            </Grid>
            <Grid item md={6} sm={12}>
              <Box className="ratingGrid" style={{ marginLeft: "10px"}}>
              <Typography className="avgRatingText">Average Rating</Typography>
              <div style={{ display: 'flex', gap: 10 }}>
                <Typography className="avgRatingCount" >{this.state.rating}</Typography>
                <Box style={{ marginTop: "7px"}}>
                  <Rating readOnly name="half-rating" style={{ fontSize: "2rem"}} value={this.RoundedRating(this.state.rating)} precision={0.5} emptyIcon={<StarBorderIcon fontSize="inherit" />} />
                </Box>
              </div>
              <Typography className="avgRatingInfo">Average Rating for this year</Typography>
              </Box>
              
            </Grid>
          </Grid>

          <Grid className="grids" container>
            <Grid item md={6} sm={12}>
              <Box className="overAllRatingGrid" style={{ marginRight: "10px"}}>
              
                <Box style={{ display: "flex", margin: "auto" }}>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                      <path d="M65.4919 30.982L49.3586 29.582L43.0586 14.7486C41.9253 12.0486 38.0586 12.0486 36.9253 14.7486L30.6253 29.6153L14.5253 30.982C11.5919 31.2153 10.3919 34.882 12.6253 36.8153L24.8586 47.4153L21.1919 63.1486C20.5253 66.0153 23.6253 68.282 26.1586 66.7486L39.9919 58.4153L53.8253 66.782C56.3586 68.3153 59.4586 66.0486 58.7919 63.182L55.1253 47.4153L67.3586 36.8153C69.5919 34.882 68.4253 31.2153 65.4919 30.982Z" fill="#6EEBFF" />
                    </svg>
                  </div>
                  <Typography className="overAllRating">{this.state.rating}</Typography>
                </Box>

                <div>
                <div style={{ display: "flex", alignItems: "center", padding: "8px 15px", justifyContent: "space-between"}}>
                   <div style={{ display: "flex", gap: "10px", alignItems: "center"}}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                          <path d="M15.0009 21.8997L20.1884 25.0372C21.1384 25.6122 22.3009 24.7622 22.0509 23.6872L20.6759 17.7872L25.2634 13.8122C26.1009 13.0872 25.6509 11.7122 24.5509 11.6247L18.5134 11.1122L16.1509 5.53721C15.7259 4.52471 14.2759 4.52471 13.8509 5.53721L11.4884 11.0997L5.45088 11.6122C4.35088 11.6997 3.90088 13.0747 4.73838 13.7997L9.32588 17.7747L7.95088 23.6747C7.70088 24.7497 8.86338 25.5997 9.81338 25.0247L15.0009 21.8997Z" fill="#DDC026" />
                        </svg>
                      </div>
                      <Typography className="ratingStarDisplayText">5</Typography>
                   </div>

                   <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.ratingStarData.five} />
                   <Typography className="ratingStarDisplayText">{this.state.ratingStarData.fiveCount}</Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center", padding: "8px 15px", justifyContent: "space-between"}}>
                   <div style={{ display: "flex", gap: "10px", alignItems: "center"}}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                          <path d="M15.0009 21.8997L20.1884 25.0372C21.1384 25.6122 22.3009 24.7622 22.0509 23.6872L20.6759 17.7872L25.2634 13.8122C26.1009 13.0872 25.6509 11.7122 24.5509 11.6247L18.5134 11.1122L16.1509 5.53721C15.7259 4.52471 14.2759 4.52471 13.8509 5.53721L11.4884 11.0997L5.45088 11.6122C4.35088 11.6997 3.90088 13.0747 4.73838 13.7997L9.32588 17.7747L7.95088 23.6747C7.70088 24.7497 8.86338 25.5997 9.81338 25.0247L15.0009 21.8997Z" fill="#DDC026" />
                        </svg>
                      </div>
                      <Typography className="ratingStarDisplayText">4</Typography>
                   </div>

                   <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.ratingStarData.four} />
                   <Typography className="ratingStarDisplayText">{this.state.ratingStarData.fourCount}</Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center", padding: "8px 15px", justifyContent: "space-between"}}>
                   <div style={{ display: "flex", gap: "10px", alignItems: "center"}}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                          <path d="M15.0009 21.8997L20.1884 25.0372C21.1384 25.6122 22.3009 24.7622 22.0509 23.6872L20.6759 17.7872L25.2634 13.8122C26.1009 13.0872 25.6509 11.7122 24.5509 11.6247L18.5134 11.1122L16.1509 5.53721C15.7259 4.52471 14.2759 4.52471 13.8509 5.53721L11.4884 11.0997L5.45088 11.6122C4.35088 11.6997 3.90088 13.0747 4.73838 13.7997L9.32588 17.7747L7.95088 23.6747C7.70088 24.7497 8.86338 25.5997 9.81338 25.0247L15.0009 21.8997Z" fill="#DDC026" />
                        </svg>
                      </div>
                      <Typography className="ratingStarDisplayText">3</Typography>
                   </div>

                   <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.ratingStarData.three} />
                   <Typography className="ratingStarDisplayText">{this.state.ratingStarData.threeCount}</Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center", padding: "8px 15px", justifyContent: "space-between"}}>
                   <div style={{ display: "flex", gap: "10px", alignItems: "center"}}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                          <path d="M15.0009 21.8997L20.1884 25.0372C21.1384 25.6122 22.3009 24.7622 22.0509 23.6872L20.6759 17.7872L25.2634 13.8122C26.1009 13.0872 25.6509 11.7122 24.5509 11.6247L18.5134 11.1122L16.1509 5.53721C15.7259 4.52471 14.2759 4.52471 13.8509 5.53721L11.4884 11.0997L5.45088 11.6122C4.35088 11.6997 3.90088 13.0747 4.73838 13.7997L9.32588 17.7747L7.95088 23.6747C7.70088 24.7497 8.86338 25.5997 9.81338 25.0247L15.0009 21.8997Z" fill="#DDC026" />
                        </svg>
                      </div>
                      <Typography className="ratingStarDisplayText">2</Typography>
                   </div>
                   <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.ratingStarData.two} />
                   <Typography className="ratingStarDisplayText">{this.state.ratingStarData.twoCount}</Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center", padding: "8px 15px", justifyContent: "space-between"}}>
                   <div style={{ display: "flex", gap: "10px", alignItems: "center"}}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                          <path d="M15.0009 21.8997L20.1884 25.0372C21.1384 25.6122 22.3009 24.7622 22.0509 23.6872L20.6759 17.7872L25.2634 13.8122C26.1009 13.0872 25.6509 11.7122 24.5509 11.6247L18.5134 11.1122L16.1509 5.53721C15.7259 4.52471 14.2759 4.52471 13.8509 5.53721L11.4884 11.0997L5.45088 11.6122C4.35088 11.6997 3.90088 13.0747 4.73838 13.7997L9.32588 17.7747L7.95088 23.6747C7.70088 24.7497 8.86338 25.5997 9.81338 25.0247L15.0009 21.8997Z" fill="#DDC026" />
                        </svg>
                      </div>
                      <Typography className="ratingStarDisplayText">1</Typography>
                   </div>

                   <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.ratingStarData.one} />
                   <Typography className="ratingStarDisplayText">{this.state.ratingStarData.oneCount}</Typography>
                </div>
                </div>
              </Box>
              
            </Grid>
            <Grid item md={6} sm={12}>
              <Box className="graphGrid" style={{ marginLeft: "10px"}}>
                
                {/* Review Graph here */}
                <Box style={{ height: "215px", display: "flex", justifyContent: "center", alignItems: "center", margin: "auto" }}>
                  <div style={{ height: "202px", width: "202px", position: "relative", }}>
                    <div
                      style={{
                        height: "200px",
                        width: "200px",
                        position: "absolute",
                        borderRadius: "100%",
                      }}
                    >
                      <CircularProgressbar
                        value={this.state.ratingGraphData.professionalism}
                        strokeWidth={10}
                        styles={{
                          path: { stroke: "#2966FF" },
                          trail: { stroke: "#F1F5F9" },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        height: "150px",
                        width: "150px",
                        position: "absolute",
                        top: "25px",
                        left: "25px",
                        borderRadius: "100%",
                      }}
                    >
                      <CircularProgressbar
                        value={this.state.ratingGraphData.efficiency}
                        strokeWidth={13}
                        styles={{
                          path: { stroke: "#DB2777" },
                          trail: { stroke: "#fff" },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        height: "95px",
                        width: "95px",
                        position: "absolute",
                        top: "53px",
                        left: "53px",
                        borderRadius: "100%",
                      }}
                    >
                      <CircularProgressbar
                        value={this.state.ratingGraphData.likelihood}
                        strokeWidth={20}
                        styles={{
                          path: { stroke: "#312E81" },
                          trail: { stroke: "#fff" },
                        }}
                      />
                    </div>
                  </div>
                </Box>

                {/* Text Here */}
                <Box style={{ padding : "0 25px"}}>
                  <Box style={{ display: "flex",  justifyContent: "space-between", marginTop: "23px" }}>
                    <Box style={{ display: "flex", columnGap: "5px", alignItems: "center" }}>
                      <PointTag />
                      <ProfessionalismText component={"span"}>Professionalism</ProfessionalismText>
                      <PercentText component={"span"}>{this.state.ratingGraphData.professionalism}%</PercentText>
                    </Box>
                    <Box style={{ display: "flex", columnGap: "5px", alignItems: "center" }}>
                      <PointTagThree />
                      <ProfessionalismText component={"span"}>Likelihood</ProfessionalismText>
                      <PercentTextTwo component={"span"}>{this.state.ratingGraphData.likelihood}%</PercentTextTwo>
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", columnGap: "5px", alignItems: "center", marginTop: "20px" }}>
                    <PointTagTwo />
                    <ProfessionalismText component={"span"}>Efficiency</ProfessionalismText>
                    <PercentText component={"span"}>{this.state.ratingGraphData.efficiency}%</PercentText>
                  </Box>

                </Box>  


              </Box>
            </Grid>
          </Grid>

          <Box className="reviewerDataClass">
            <Box style={{ display: 'flex', justifyContent: "space-between", marginBottom: 40 }}>
              <Typography className="reviewsTextClass"> {this.state.reviewData.length} Reviews </Typography>
              <Box>
                <Select className="reviewTextClassr" value={10} disableUnderline>
                  <MenuItem value={10}>Latest</MenuItem>
                </Select>
              </Box>
            </Box>

             {/* user reviews data */}
              {this.state.reviewData.map((item: any, index: number) => {
                const { first_name, last_name, photo, rating } = item.attributes.reviewer.data.attributes;
                return (
                  <Box key={item.id} className="reviewerClass">
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                        <img alt="img" src={this.handleImage(photo)} className="userImgClasses" />
                        <Typography className="userNameClass">{first_name} {last_name}</Typography>
                      </Box>
                      <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                        <Typography className="userReadTimeClass">{moment(item.attributes.created_at).fromNow()}</Typography>
                        <div className="userClassLikeBtn">
                          {this.likeIconHandle(item.likeBtn, index)}
                        </div>
                      </Box>
                    </Box>

                    <Box style={{ margin: "20px 0" }}>
                      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                        
                        <Rating name="half-rating" readOnly style={{ fontSize: "2rem" }} value={this.RoundedRating(this.avgRating(item.attributes.professionalism, item.attributes.efficiency, item.attributes.likelihood_to_recommend))} precision={0.5} emptyIcon={<StarBorderIcon fontSize="inherit" />} />
                        <Typography className="ratingStarDisplayText">
                          {this.avgRating(item.attributes.professionalism, item.attributes.efficiency, item.attributes.likelihood_to_recommend)}
                        </Typography>
                      </div>
                    </Box>

                    <Box>
                      <p className="desctext">{item.attributes.anything_else === null ? "" : item.attributes.anything_else}</p>
                      <Box style={{ display: 'flex', gap: 5, alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M11.9975 9.24994H5.8075L8.5275 6.52994C8.8175 6.23994 8.8175 5.75994 8.5275 5.46994C8.2375 5.17994 7.7575 5.17994 7.4675 5.46994L3.4675 9.46994C3.1775 9.75994 3.1775 10.2399 3.4675 10.5299L7.4675 14.5299C7.6175 14.6799 7.8075 14.7499 7.9975 14.7499C8.1875 14.7499 8.3775 14.6799 8.5275 14.5299C8.8175 14.2399 8.8175 13.7599 8.5275 13.4699L5.8075 10.7499H11.9975C14.8975 10.7499 17.2475 13.1099 17.2475 15.9999V17.9999C17.2475 18.4099 17.5875 18.7499 17.9975 18.7499C18.4075 18.7499 18.7475 18.4099 18.7475 17.9999V15.9999C18.7475 12.2799 15.7175 9.24994 11.9975 9.24994Z" fill="#64748B" />
                        </svg>

                        <input name={item.id} data-test-id={`reply-${index}`} onChange={(e) => this.handleReplyChange(e, index)} value={item.replyText} type="text" placeholder="Reply" className="replyTextFields" style={{ border: "none", width: "100%"}} />
                        <IconButton disableRipple data-test-id={`clearBtn-${index}`} onClick={() => this.clearReplyText(index)}>
                          <CancelIcon color="error" />
                        </IconButton>
                        <IconButton disableRipple data-test-id={`sendBtn-${index}`} onClick={() => this.sendReplyBtn(item, index)}>
                          <SendIcon color="primary" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>)
              }
              )}

          </Box>

        </Box>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

const PointTagThree = styled(Box)({
  height: "20px",
  width: "20px",
  backgroundColor: "#312E81",
  borderRadius: '10px'

});

const PointTagTwo = styled(Box)({
  height: "20px",
  width: "20px",
  backgroundColor: "#DB2777",
  borderRadius: '10px'

})

const PointTag = styled(Box)({
  height: "20px",
  width: "20px",
  backgroundColor: "#2966FF",
  borderRadius: '10px'

})

const PercentTextTwo = styled(Box)({
  color: "#21272A",
  fontFamily: "Lato",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "-0.14px",
  marginLeft: "7px",
})


const PercentText = styled(Box)({
  color: "#21272A",
  fontFamily: "Lato",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "-0.14px",
  marginLeft: "7px"

})

const ProfessionalismText = styled(Box)({
  color: "#21272A",
  fontFamily: "Lato",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "-0.14px",
  marginLeft:"10px"

})