export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const girlImg = require("../assets/group_.png");
export const boyImg = require('../assets/group_(1).png');
export const landing_image = require('../assets/image_.jpg');
export const image = require('../assets/img1.svg');
export const star = require('../assets/star.svg');
export const contractor = require('../assets/contractor.png');
export const Group_top = require("../assets/group_top.png");
export const landing = require("../assets/landingbg.png");
export const backgroundImage = require("../assets/backgroundImage.png");
export const personLogo = require("../assets/personLogo.png");
export const signUpbackgroundTwo = require("../assets/backgroundTwo.png");
export const notificationPermission = require("../assets/notification-permission.png");


