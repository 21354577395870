Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "DELETE";
exports.httpPatchMethod = "PATCH";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "account";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.addCertificateApiEndPoint = "certificates"
exports.getCertificateApiEndPoint = "certificates"
exports.deleteCertificateApiEndPoint = "certificates"
exports.editCertificateApiEndPoint = "certificates"
exports.getSkillsApiEndPoint = "skills"
exports.addCardDetailApiEndPoint = "bx_block_stripe_integration/cards"
exports.getCardDetailsApiEndPoint = "bx_block_stripe_integration/cards"
exports.deleteCardDetailsApiEndPoint = "bx_block_stripe_integration/cards"
exports.editCardDetailsApiEndpoint="bx_block_stripe_integration/cards"
exports.updateDefaultCardDetailsApiEndpoint="bx_block_stripe_integration/cards/update_defalut_card"
exports.getBankAccountApiEndPoint="bx_block_stripe_integration/payment_methods/user_bank_list"
exports.addBankAccountApiEndPoint="bx_block_stripe_integration/payment_methods/create_customer_bank"
exports.deleteBankAccountApiEndPoint="bx_block_stripe_integration/payment_methods"
exports.editBankAccountApiEndPoint="bx_block_stripe_integration/payment_methods/update_account_details"
exports.countrtApiCall ="accounts/country_codes"
exports.updateSubSkillsApiEndPoint="account"
// Customizable Area End
