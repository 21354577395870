// Customizable Area Start
import React, { useEffect, useMemo, useRef } from "react";
import { useParticipant } from "@videosdk.live/react-sdk";

import ParticipantView from "./ParticipantView.web";

const Participant = ({ participantId, displayType, participantsList }) => {
  const webcamRef = useRef(null);
  const micRef = useRef(null);
  const screenShareRef = useRef(null);
  const onStreamEnabled = (stream) => {
    console.log(stream, "onStreamEnabled")
    if(setQuality){
      setQuality("high")
    }    
  };
  const onStreamDisabled = (stream) => {};
  const onVideoQualityChanged = (stream) => {
    console.log("onVideoQualityChanged", stream , stream.currentQuality);
  }

  const {
    displayName,
    participant,
    webcamStream,
    micStream,
    screenShareStream,
    webcamOn,
    micOn,
    screenShareOn,
    isLocal,
    isActiveSpeaker,
    isMainParticipant,
    switchTo,
    pinState,
    setQuality,
    enableMic,
    disableMic,
    enableWebcam,
    disableWebcam,
    pin,
    unpin,
  } = useParticipant(participantId, {
    onStreamEnabled,
    onStreamDisabled,
    onVideoQualityChanged,
  });
 
  const webcamMediaStream = useMemo(() => {
    if (webcamOn && webcamStream && webcamStream?.track) {
      const mediaStream = new MediaStream();
      webcamStream?.track && mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  const screenShareMediaStream = useMemo(() => {
    if (screenShareOn) {
      const mediaStream = new MediaStream();
      screenShareStream?.track &&
        mediaStream?.addTrack(screenShareStream.track);
      return mediaStream;
    }
  }, [screenShareStream, screenShareOn]);

  useEffect(() => {
    checkMediaStream();
  }, [micStream, micOn]);

  useEffect(() => {
    setQuality("high")
  },[]);
  
  const checkMediaStream = () => {
    try {
      if (!micRef.current) {
        return;
      }

      if (micOn) {
        const mediaStream = new MediaStream();
        micStream?.track && mediaStream?.addTrack(micStream?.track);

        micRef.current.srcObject = mediaStream;
        return micRef.current
          ?.play()
          .catch((error) => console.error("mic play() failed", error));
      }

      micRef.current.srcObject = null;
    } catch (error) {
      console.log("error", error);
    }
  };

  const onError = (err) => {
    console.log(err, "participant video error");
  };

  const viewProps = {
    webcamRef,
    micRef,
    screenShareRef,
    displayName,
    webcamOn,
    screenShareOn,
    isLocal,
    webcamMediaStream,
    screenShareMediaStream,
    onError,
    displayType,
    participantsList
  };

  return <ParticipantView testID="ParticipantView" {...viewProps} />;
};

export default Participant;
// Customizable Area End
