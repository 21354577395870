import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location:any;
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start   
  loading: boolean;
  mailSending: boolean;
  email: string;
  emailError: string;
  emailSent: boolean;
  alertMsgType: any;
  snackbarOpen: boolean;
  alertMsg: string;
  pageType: string;
  disableResetPassBtn: boolean;
  password: string;
  confirmPassword: string;
  token: string;
  passwordNotMatch: string;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  msgList: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createRoomId: string= "";
  sendForgotPasswordMailApiId: string = "";
  restPasswordApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {      
      loading: true,
      email: "",
      mailSending: false,
      emailError: "",
      emailSent: true,
      alertMsgType: '',
      snackbarOpen: false,
      alertMsg: '',
      pageType: '',
      disableResetPassBtn: true,
      password: "",
      confirmPassword: "",
      passwordNotMatch: "",
      token: "",
      enablePasswordField: false,
      enableConfirmPasswordField: false,
      msgList: {
        passResetMsg: "Password Successfully Changed"
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );       
      
      if (responseJson.data) {        
        if(apiRequestCallId === this.restPasswordApiId){
          this.setState({
            emailSent: true,
            snackbarOpen: true,
            alertMsgType: 'success',
            alertMsg: this.state.msgList.passResetMsg,
            mailSending: false
          }, () => {
            this.navigateTo("/")
          })  
        }
      } else {
        console.log(responseJson,"responseJson")
        this.onApiMessage(responseJson, apiRequestCallId)
      }
    }
  
    // Customizable Area End
  }

  // Customizable Area Start
  onApiMessage = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson.message){
      if(apiRequestCallId === this.sendForgotPasswordMailApiId){
        this.setState({        
          snackbarOpen: true,
          alertMsgType: 'success',
          alertMsg: responseJson.message,
          mailSending: false,
          pageType: "emailSentMessage"
        })  
      }
      if(apiRequestCallId === this.restPasswordApiId){
        this.setState({ emailSent: true, snackbarOpen: true, alertMsgType: 'success', alertMsg: this.state.msgList.passResetMsg, mailSending: false }, () => {
          this.navigateTo("/")
        })  
      }
      
    } else if(responseJson.errors){
      if(apiRequestCallId === this.sendForgotPasswordMailApiId || this.restPasswordApiId){
      this.setState({        
        snackbarOpen: true,
        alertMsgType: 'error',
        alertMsg: responseJson.errors[0].account || "something went wrong",
        mailSending: false,
        disableResetPassBtn: false
      })  
      }
    }
  }
  async componentDidMount(){
    const token = localStorage.getItem('signin-token');     
    if(token){
      let userData: any = localStorage.getItem('userData');
      if(userData && userData!==""){
        userData = JSON.parse(userData);
      }  
      this.props.history.replace({ pathname: userData.user_type === "customer"? "/landing" : "/contractorDashboard" });
    }else{
      this.getTokenFromUrl()
    }   
  }
  getTokenFromUrl = () => {
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const tokenFromUrl = urlSearchParams.get('token');
    const emailFromUrl = urlSearchParams.get('email') || '';
    console.log(tokenFromUrl,"pagePath")
    if(tokenFromUrl){
      this.setState({
        pageType: "resetPassword",
        token: tokenFromUrl,
        email: emailFromUrl,
        loading: false,
      })
    } else{
      setTimeout(() =>this.setState({
        loading: false,
        pageType: "forgotPassword"
      }), 500) 
    }   
  }
  callAnApi = (props:any) => {
    const sendForgotPasswordMailAPI = new Message(getName(MessageEnum.RestAPIRequestMessage));
    sendForgotPasswordMailAPI.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    sendForgotPasswordMailAPI.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(props.headers));
    if(props.body){
      sendForgotPasswordMailAPI.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(props.body));
    }
    sendForgotPasswordMailAPI.addData(getName(MessageEnum.RestAPIRequestMethodMessage),props.requestType);
    runEngine.sendMessage(sendForgotPasswordMailAPI.id, sendForgotPasswordMailAPI);
    return sendForgotPasswordMailAPI.messageId;
  }
  navigateTo = (url:any) => {    
    // this.props.navigation.navigate(url)
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => this.props.history.push(url), 500) 
    })
  }
  closeAlertMsg = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      snackbarOpen: false,
      alertMsgType: '',
      alertMsg: ''

    })
  }
  validateLoginEmail = () => { 
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validMail = emailPattern.test(this.state.email) 
    console.log(validMail)
    if(validMail){
      this.setState({
        emailError: ""
      })
      this.sendEmailForForgotPassword()
    } else {
      this.setState({
        emailError: "Please enter Valid Email"
      })
    }
  };

  enterBtnClicked = (e:any) => {
    e.preventDefault();
    this.validateLoginEmail()
  }
   
  handleEmailChange = (event:any) => {
    const value = event.target.value;
    this.setState({
      email: value
    })
  }
  handlePasswordChange = (event:any) => {
    const value = event.target.value;
    const name = event.target.name;
    if(name === "password"){
      const containUppercase = /[A-Z]/.test(value);
      const containLowercase = /[a-z]/.test(value);
      const containNumber = /\d/.test(value); 
      const expectedLength = value.length >=8; 
      const containSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const disableResetPassBtn= !(expectedLength && containUppercase && containLowercase && containNumber && containSpecialChar)
      this.setState({
        password: value,
        disableResetPassBtn: disableResetPassBtn
      })
    }else{
      this.setState({
        confirmPassword: value,
      })
    }   
  }
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField
    })
  }
  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField
    })
  }
  sendEmailForForgotPassword = async () => {
    this.setState({
      mailSending: true
    })
    const headers= {
      'Content-Type': 'application/json',      
    }     
    const postBody = {
      email: this.state.email,      
    }
    this.sendForgotPasswordMailApiId = this.callAnApi({
      headers: headers, 
      body: postBody,
      endPoint: "password/forgot",
      requestType: "POST"
    })
  }
  resetPasswordApi = () => {
    if(this.state.password !== this.state.confirmPassword){
      this.setState({
        passwordNotMatch: configJSON.passNotMatchMsg
      })
      return "";
    }
    this.setState({
      disableResetPassBtn: true
    })
    const headers= {
      'Content-Type': 'application/json',   
      token: this.state.token   
    }     
    const postBody = {
      new_password: this.state.password,
      confirm_new_password: this.state.confirmPassword,
      token: this.state.token 
    }
    this.restPasswordApiId = this.callAnApi({
      headers: headers, 
      body: postBody,
      endPoint: "password",
      requestType: "PUT"
    })
  }
  onClickResendEmail = () => {
    this.setState({
      pageType: "forgotPassword"
    })
  }
  // Customizable Area End
}
