import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { Country, City }  from 'country-state-city';
import { apiCalling } from '../../../components/src/assets'
import { cropImage } from "../../emailnotifications2/src/assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history?: any;
  location?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  userData: any;
  token: string | null;
  creditCardsModal: boolean;
  profilePic: any;
  cardModal: boolean;
  hasImgChanged: boolean;
  cardData: any;
  cardInfo: any;
  deleteCardModel: boolean;
  updateCardModel: boolean;
  pageLoader: boolean;
  countryData: any;
  selectedcity: any;
  cityData:any;
  selectedCountry: any;


  //New
  countryCodesNew:string
  selectedCountryCodeNew: string,
  openPopOverNew: boolean,
  anchorElePopOverNew: HTMLElement | null,
  countryCodesDataNew: Array<CountryCodesInterface>;
  showSuccMsg: boolean;
  messageType: any;
  updatePassword: string;
  updatePasswordShow: boolean;
  passwordError: boolean;
  messageDesctiption: string;
  // Customizable Area End
}

interface SS {
  id: any;
}
export interface CountryCodesInterface {
  id: string;
  type: string;
  attributes: {
    name: string;
    country_code: string;
  };
}
export default class ProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDataId: string = "";
  editProfileApiCallId : string = "";
  addCardApiCallId: string = "";
  getCardApiCallId: string = "";
  deleteCardApiCallId: string = "";
  editCardApiCallId: string = "";
  updateDefaultCardApiCallId : string = "";
  countryCodeApiCallId: string ="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {
      token: "",
      creditCardsModal: false,
      userData: {},
      profilePic: "",
      cardModal: false,
      hasImgChanged: false,
      cardData: [],
      cardInfo: {},
      deleteCardModel: false,
      updateCardModel: false,
      pageLoader: true,
      selectedCountry: "",
      selectedcity: "",
      cityData: [],
      countryData: [],


      //New
      countryCodesNew:"",
      selectedCountryCodeNew: "+1",
      openPopOverNew: false,
      anchorElePopOverNew: null,
      countryCodesDataNew: [],
      showSuccMsg: false,
      messageType: "",
      messageDesctiption: "",
      updatePassword: "",
      passwordError: false,
      updatePasswordShow: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCardAPIsCall(apiRequestCallId,responseJson)
      this.handleApiCalls(apiRequestCallId, responseJson)
      if(responseJson.status === 500){        
        return "";
      }
      if (apiRequestCallId === this.getUserDataId) {
        this.updateUserData(responseJson);
      }
      if (apiRequestCallId === this.editProfileApiCallId) {
        let messageObj;
        if (responseJson.data) {
          let profilePic = responseJson.data.attributes.photo;
          if(responseJson.data.attributes.photo){
            profilePic = await cropImage(responseJson.data.attributes.photo, false)
          }
           
          this.setState({
            userData: responseJson.data.attributes,
            profilePic: profilePic,
            hasImgChanged: false
          })
          messageObj = {
            showSuccMsg: true, 
            messageType: 'success',
            messageDesctiption: "Profile Updated Successfully"
          }
          this.setUserProfilePhoto(responseJson)
        } else {
          messageObj = {
            showSuccMsg: true, 
            messageType: 'error',
            messageDesctiption: "Something went wrong"
          }
        }
        this.props.history?.push("/profile", {messageObj: messageObj});
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getCountryCodes();
    this.getUserData();
    this.getCardDetails();  
    this.checkSnackBarMsgs(); 
  }
  renderCity = () => {
    let cityName: string = ""
    const city = this.state.userData?.city;
    if(city && city !== undefined){
      cityName = `${city}, `;        
    }
    return cityName
  }

  checkSnackBarMsgs = () => {
    if(this.props.location?.state?.messageObj){
      window.scroll(0,0);
      const snackBarMsg = this.props.location.state.messageObj;
      this.setState({
        messageType: snackBarMsg.messageType,
        showSuccMsg: true,

        messageDesctiption: snackBarMsg.messageDesctiption
      })
      setTimeout(() => this.props.history.replace(window.location.pathname), 1500)
    } 
  }
  onProfileSnackBarClose = () => {
    this.setState({ 
      showSuccMsg : false, 
      messageType: "", 
      messageDesctiption:""
    })    
  }
  updateUserData = async (responseJson: any) => {
    if (responseJson.data) {
      let country: any="";
      let city: any="";
      let cityList: any = [];
      const countries =  Country.getAllCountries(); 
      const data = responseJson.data.attributes;
      if(data.country && data.country!== undefined){
        country =  countries.find((val: any) =>  val.name === data.country)||{};
        cityList = City.getCitiesOfCountry(country?.isoCode); 
        if(data.city){
          city = cityList.find((val: any) =>  val.name === data.city);
        }
      }
      let profilePic = data.photo;
      if(data.photo){
        profilePic = await cropImage(data.photo, false)
      }
       
      this.setState({
        userData: data,
        profilePic: profilePic,        
        selectedCountry: country,
        selectedcity: city,
        countryData: countries,
        cityData: cityList,
        hasImgChanged: false,
        pageLoader: false,
        selectedCountryCodeNew: `+${data.country_code}`
      })
    }    
  }
  showCardModal = () => {
    this.setState({
      creditCardsModal: true
    })
  }
  onCountryChange=(e: any, val: any)=>{
    let citiList: any = [];
    if(val){
      citiList = City.getCitiesOfCountry(val.isoCode); 
    }               
    this.setState({ selectedCountry : val, cityData: citiList, selectedcity: ""})
  }

  onCityChange = (e: any, val: any) => {
    this.setState({ 
      selectedcity : val
    })
  }

  closeCreditCardModal = () => {
    this.setState({
      creditCardsModal: false
    })
  }
  callAnApi(props:any){
    const profileApiCall = new Message(getName(MessageEnum.RestAPIRequestMessage));
    profileApiCall.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    if(props.formData){
      profileApiCall.addData(getName(MessageEnum.RestAPIRequestBodyMessage),props.formData);
    }
    profileApiCall.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(props.headers));
    profileApiCall.addData(getName(MessageEnum.RestAPIRequestMethodMessage),props.requestType);
    runEngine.sendMessage(profileApiCall.id, profileApiCall);
    return profileApiCall.messageId;
  }
  getUserData = async () =>  {
    const token = localStorage.getItem('signin-token');
    if(token){
      const headers= {
        'Content-Type': 'application/json',
        token: token
      }
      this.getUserDataId = this.callAnApi({
        headers: headers,      
        endPoint: "account",
        requestType: "GET"
      })
      this.setState({ token: token})
    }
  }
  redirectToEditProfile = () => {
    this.props.navigation.navigate("ProfileEdit")
  }
  handleImageChange = (event:any) => {
    const selectedImage = event.target.files[0];
    const userData = this.state.userData     
    const validFormats = ['image/jpeg', 'image/png'];
    if (selectedImage &&!validFormats.includes(selectedImage.type)) {
        window.alert(" Invalid file format. Only JPEG and PNG are allowed. ");
        return "";
    }

    if (selectedImage && selectedImage.size > 1024 * 1024) {
      window.alert("File size exceeds 1MB limit")       
      return "";
    }
    userData['photo'] = selectedImage;
    if (selectedImage) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.setState({ profilePic : e.target?.result, hasImgChanged: true});
      };

      reader.readAsDataURL(selectedImage);
    }
  };
  onFieldChange = (event:any) => {
    const name = event.target.name
    const value = event.target.value
    const userData = this.state.userData;
    if(name){
      userData[name] = value;
      this.setState({
        userData:userData
      })
    }
  }

  onPasswordUpdateChange = (event: any) => {
    const value = event.target.value;
    let passwordError = false
    if(value !== ""){
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{\=\-\)\(\^%`~+\.:;_])\S{8,}$/;
      const errorAvailable = passwordPattern.test(value);
      passwordError = !errorAvailable;
    }
    this.setState({
      updatePassword: value,
      passwordError: passwordError
    })
  }
  onShowPasswordIconClick= () => {
    this.setState({ updatePasswordShow: !this.state.updatePasswordShow
    })
  }

  updatedProfile = () =>  {   
    if(this.state.passwordError){
      return ""
    }
    const header = {
       token: this.state.token
    };
    let formdata = new FormData();
    formdata.append("data[attributes][first_name]",this.state.userData.first_name);
    formdata.append("data[attributes][last_name]",this.state.userData.last_name);
    formdata.append("data[attributes][phone_number]",this.state.userData.phone_number);
    formdata.append("data[attributes][country]",this.state.selectedCountry.name);
    formdata.append("data[attributes][city]",this.state.selectedcity.name);

    formdata.append("data[attributes][country_code]", this.state.selectedCountryCodeNew);
    if(this.state.updatePassword!==""){
      formdata.append("data[attributes][password]", this.state.updatePassword||"");
    }
    this.state.hasImgChanged && formdata.append("data[attributes][photo]",this.state.userData.photo);       
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
    this.editProfileApiCallId = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),"account");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"put");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCardModel = () => {
    this.setState({ cardModal : false})
  }

  handleUpdateCardModel = () => {
    this.setState({ updateCardModel : false})
  }

  handleCardAPIsCall = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {

      case this.getCardApiCallId:
        this.setCardAPIresponse(responseJson)
        break;
      case this.addCardApiCallId:
        this.addCardAPIresponse(responseJson)
        break;
      case this.deleteCardApiCallId:
        this.deleteCardAPIresponse(responseJson)
        break;
      case this.editCardApiCallId:
        this.editCardAPIresponse(responseJson)
        break;
      case this.updateDefaultCardApiCallId:
        this.updateDefaultCardCardAPIresponse(responseJson)
        break;

    }
  }

  deleteCardAPIresponse(responseJson: any) {
    if(responseJson && responseJson.message) {
      this.getCardDetails()
      this.handleDeleteCardModel()
      toast.success("Card details deleted successfully")
    } else {
      this.handleDeleteCardModel()
      toast.error("Something went wrong!")
    }
  }
  addCardAPIresponse(responseJson: any) {
    if(responseJson && responseJson.message) {
      this.getCardDetails()
      this.handleCardModel()
      toast.success("Card details added successfully")
    } else {
      this.handleCardModel()
      let errMsg = "Something went wrong!"
      if(responseJson && responseJson.errors && responseJson.errors[0].stripe) {
         errMsg = responseJson.errors[0].stripe
      }
      toast.error(errMsg)
    }
  }

  editCardAPIresponse(responseJson: any) {
    if(responseJson && responseJson.message) {
      this.getCardDetails()
      this.handleUpdateCardModel()
      toast.success("Card details updated successfully")
    } else {
      this.handleUpdateCardModel()
      toast.error("Something went wrong!")
    }
  }

  updateDefaultCardCardAPIresponse(responseJson: any) {
    if(responseJson && responseJson.message) {
      this.getCardDetails()
      toast.success("Default Card updated successfully")
    } else {
      toast.error("Something went wrong!")
    }
  }

  setCardAPIresponse(responseJson: any) {
    if(responseJson && responseJson.cards) {
      this.setState({ cardData : responseJson.cards})
    } else {
      this.setState({ cardData : []})
    }
  }

  addCardDetails = (token: string) => {

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };

    const httpBody = {
      "data": {
        "attributes": {
          "token": token
        }
      }
    }

    this.addCardApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.addCardDetailApiEndPoint,
      method: configJSON.httpPostMethod,
      body: JSON.stringify(httpBody)
    }
    )
  }

  getCardDetails = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };

    this.getCardApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.getCardDetailsApiEndPoint,
      method: configJSON.httpGetMethod,
    }
    )
  }

  deleteCardDetails = (id: string) => {

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };

    this.deleteCardApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.deleteCardDetailsApiEndPoint}/${id}`,
      method: configJSON.httpDeleteMethod,
    }
    )
  }

  editCardDetails = (payLoad: any) => {

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };

    const httpBody = {
      "data": payLoad.body
    }

    this.editCardApiCallId = apiCalling({
      header: JSON.stringify(header),
      body: JSON.stringify(httpBody),
      endPoint: `${configJSON.editCardDetailsApiEndpoint}/${payLoad.id}`,
      method: configJSON.httpPatchMethod,
    })
  }

  updateDefaultCardDetails = (id: string) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };

    const httpBody = {
      "card_id": id
    }

    this.updateDefaultCardApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.updateDefaultCardDetailsApiEndpoint}`,
      method: configJSON.httpPatchMethod,
      body: JSON.stringify(httpBody)
    }
    )
  }

  generateAbbreviation = (companyName: string)  => {
    // Split the company name into words
    const words = companyName.split(' ');
  
    // Take the first letter of each word and convert it to uppercase
    const abbreviation = words.map(word => word.charAt(0).toUpperCase()).join('');
  
    return abbreviation;
  }

  capitalizeFirstLetterOfEachWord = (str: string) => {
    return str.replace(/\b\w/g, match => match.toUpperCase());
  }

  handleDeleteCardModel = () => {
    this.setState({ deleteCardModel : false})
  }

  setUserProfilePhoto = (responseJson: any) => {
    let oldUserData = localStorage.getItem("userData")
    if (oldUserData !== null) {
      let oldUserDataObj = JSON.parse(oldUserData)
      if (responseJson.data.attributes.photo && oldUserData) {
        const newProfileImage = responseJson.data.attributes.photo
        oldUserDataObj.photo = newProfileImage
      }
      oldUserDataObj.first_name = responseJson.data.attributes.first_name
      oldUserDataObj.last_name = responseJson.data.attributes.last_name
      localStorage.setItem("userData", JSON.stringify(oldUserDataObj))
    }
  }

  handleCountryChange = (event:React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const countryCode = event.currentTarget.value
    const selectedVal = `+${countryCode}`
    this.setState({ selectedCountryCodeNew: selectedVal, openPopOverNew: false})
  } 

  getCountryCodes = () => {
    const headers = {
        "Content-Type": configJSON.searchApiContentType,
    };
    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.countrtApiCall
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}

handleApiCalls = (apiRequestCallId: string, responseJson: {data:Array<CountryCodesInterface>}) => {
  if(apiRequestCallId === this.countryCodeApiCallId) {
     this.setCountryCodeApiDataResponse(responseJson)
  }
}

setCountryCodeApiDataResponse = (responseJson: {data:Array<CountryCodesInterface>}) => {
  if(responseJson && responseJson.data) {
    this.setState({ countryCodesDataNew : responseJson.data})
  } else {
      this.setState({ countryCodesDataNew : []})
  }
} 

  // Customizable Area End
}
