// Customizable Area Start
import React from "react";


// Customizable Area End

import NotificationController, {
  Props,
} from "./NotificationController";
import Header from "./TopHeader.web";
import { Box, Typography } from "@material-ui/core"
import moment from "moment";
import { profileLogo  } from "./assets"

export default class NotificationPage extends NotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleImage = (item: any) => {
    let image : string = profileLogo
    if(item.attributes.photo) {
       image = item.attributes.photo
    }

    return image
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Header/>
        <div style={{ background: '#F8FAFC', height: "100vh"}}>
            <Box style={{ padding: "25px 65px"}}>
                    <div className='notificationListCom' style={{ display: 'flex' , gap: "20px"}}>
                        <div className='notoficationBackIcon' data-test-id="backIcon" onClick={() => this.props.navigation.goBack()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18.7895 11.0046H7.61953L12.4995 6.12459C12.8895 5.73459 12.8895 5.09459 12.4995 4.70459C12.3127 4.51734 12.059 4.41211 11.7945 4.41211C11.53 4.41211 11.2764 4.51734 11.0895 4.70459L4.49953 11.2946C4.10953 11.6846 4.10953 12.3146 4.49953 12.7046L11.0895 19.2946C11.4795 19.6846 12.1095 19.6846 12.4995 19.2946C12.8895 18.9046 12.8895 18.2746 12.4995 17.8846L7.61953 13.0046H18.7895C19.3395 13.0046 19.7895 12.5546 19.7895 12.0046C19.7895 11.4546 19.3395 11.0046 18.7895 11.0046Z" fill="#2966FF" />
                            </svg>
                        </div>
                        <Typography className='notificationTitleText'> Notification</Typography>
                    </div>

                    {this.state.notificationList.length > 0 && this.state.notificationList.map((item: any) =>
                        <div key={item} className='notificationCard'>
                            <Box style={{ display: 'flex', gap: "15px", alignItems: "center" }}>
                                <img className='notifyUserPhoto' src={this.handleImage(item)} />
                                <Typography className='notifyUserMsg'> <b> {item.attributes.headings} </b> {item.attributes.contents}</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: "15px"}}>
                                <Typography className='notificationReadTime'>
                                    {moment(item.attributes.created_at).format("hh:mm A")}
                                </Typography>
                            </Box>
                            
                        </div>
                    )}


            </Box>
                
         </div>
      </>
      // Customizable Area End
    );
  }
}
