// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";
import ReactPlayer from "react-player";

const ParticipantView = ({
  webcamRef,
  micRef,
  screenShareRef,
  displayName,
  webcamOn,
  screenShareOn,
  isLocal,
  webcamMediaStream,
  screenShareMediaStream,
  displayType,
  onError,
  participantsList
}) => {
  console.log('webcamMediaStream', screenShareMediaStream, webcamMediaStream)
  return (
    <React.Fragment>
     
      {(displayType==="screenShare" && screenShareMediaStream )&& <div style={webStyles.screenShareContainer}>
        <div className="video-webcam" style={webStyles.screenShare}>
          <>
            <ReactPlayer
              ref={screenShareRef}
              playsinline
              playIcon={<></>}
              pip={false}
              light={false}
              controls={false}
              muted
              playing
              url={screenShareMediaStream}
              width="100%"
              onError={onError}
              data-testid="screenShare"
            />
          </>
          <div style={webStyles.screenSharingTextWrapper}>
            <p
              style={{
                ...webStyles.screenSharingText,
                color: screenShareOn ? "green" : "red"
              }}
            >
              SCREEN SHARING
            </p>
          </div>
        </div>
      </div>}      
    
      {displayType==="participant" && <div style={webStyles.container} className={(isLocal  && participantsList.length > 1 )?"local-video-user":"video-user"}>
    <div className="video-call-cam-container" style={webStyles.camContainer}>
      <audio ref={micRef} autoPlay muted={isLocal} />
        <div className="video-webcam" style={webStyles.video}>
          <>
            <ReactPlayer
              ref={webcamRef}
              playsinline
              playIcon={<></>}
              pip={false}
              light={false}
              controls={false}
              muted
              playing
              url={webcamMediaStream}
              width="100%"
              onError={onError}
              data-testid="webCam"
            />
          </>
          {!webcamOn && <div className="displayName" style={webStyles.displayName}>
            <Typography>{isLocal ? "You" :displayName}</Typography>
          </div>}
        </div>
      </div>
    </div>}
    </React.Fragment>
  );
};

const webStyles = {
  container: {    
    overflow: "hidden",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    borderRadius: 8,
    padding: 0
  },
  camContainer: {
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "black",
    width: "100%",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  webCamPopup: {
    position: "absolute",
    top: 8,
    right: 8
  },
  webCamPopupText: {
    fontSize: 16,
    fontWeight: "bold",
    opacity: 1
  },
  displayName: {
    position: "absolute",
    bottom: 10,
    left: 10,
    padding: '4px 10px',
    backgroundColor: '#1E293B80',
    color: 'white',
    borderRadius: 3.2
  },
  screenShareContainer: {
    marginBottom: 8,
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "black",
    width: "100%",
    height: "100%"
  },
  screenShare: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  screenSharingTextWrapper: {
    position: "absolute",
    top: 8,
    right: 8,
    visibility: 'hidden'
  },
  screenSharingText: {
    fontSize: 16,
    fontWeight: "bold",
    opacity: 1
  }
};
export default ParticipantView;
// Customizable Area End
