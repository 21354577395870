Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.httpGetMethod= "GET";
exports.httpPostMethod= "POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.companyEndPoint = "bx_block_about_us/about_us"
exports.getEngagementDataApiEndpoint = "engagement_records/show_recent_engagement_record"
exports.getTotalCostApiEndpoint="accounts/collecting_fees"
exports.getEngagementDataApiEndpointIndex = "engagement_records"
exports.dashboarApi ="/bx_block_dashboard/dashboards"
exports.lifetimeEarning ="Lifetime Earning"
exports.thisMonth ="this month"
exports.totalEnagagements ="Total Engagement Time"
exports.thisweek ="this week"
exports.viewAll ="View All"
exports.fiveStar="5 star"
exports.fourStar ="4 star"
exports.threeStar ="3 star"
exports.twoStar ="2 star"
exports.oneStar ="1 star"
exports.professionalism ="Professionalism"
exports.efficiency="Efficiency"
exports.likelihood ="Likelihood"
exports.reviwes ="Reviews"
exports.paymentStatus ="Payment Status"
exports.request ="Request"
exports.date= "Date"
exports.duration="Duration"
exports.Platform="Platform"
exports.total="Total Cost"
exports.customer="Customer"
exports.CustomerName="Customer Name"
exports.platform="Platform:"
exports.salesforce="salesforce"
exports.hourly="Hourly rate:"
exports.price="$200"
exports.totalcost="Total cost"
exports.prices="$2000"
exports.rateNumber="4.3"
exports.engagementRequest ="Engagement Request"
exports.paymentDetails ="Payment Details"
exports.totalTime="Total Time"
exports.hours="200 hrs"
exports.paymentStatusTwo="Payment Status"
exports.serviceStartTime="Service Start Time"
exports.timeAnddate="12 july, 2023, 12:00 am"
exports.serviceEndTime ="Service End Time" 
exports.serviceEndDate="22 july, 2023, 12:00 am"
exports.video="Video"
exports.noDataFound ="No Engagement Record Found"
exports.engagementRecord ="Engagement Record"
exports.videoRecordingApiEndPoint ="bx_block_video_call/meetings/list_recording"
exports.tagLine="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos, reprehenderit! Laboriosam optio iusto rerum veritatis obcaecati labore cumque fugit recusandae distinctio quae sunt aliquam dolorum, placeat officiis libero a, vitae quaerat modi! Nam dignissimos magni porro. Neque maxime deserunt unde amet rem expedita earum voluptatibus officia quos. Libero, voluptates? Iure."
exports.hourly ="Hourly rate: $"
exports.recentEngagementRecords ="Recent Engagement Records"
exports.select="Select"
exports.city="City"
exports.next="Next"
exports.cancel="Cancel"
exports.ourReach ="Our Reach"
exports.industryExperts="Industry Experts"
exports.globalCustomers ="Global Customers"
exports.totalEngagements="Total Engagements"
exports.ourFounder="Our Founder"
exports.paul="Paul"
exports.topExperts="Top Experts"
exports.aboutUs="About Us"
exports.welcomeAboard="Welcome Aboard!"
exports.tagList="Please fill in the details below to continue"
exports.subdomain="Subdomain"
exports.Country="Country"
exports.noVideo ="No Video Recording Available"
exports.pending="Pending"
exports.hourRate="Hour rate"
exports.contractor="Contractor"
exports.contractorFee ="Contractor Fee"
exports.connectionFee="Connection Fee"
exports.serviceFee="Service Fee"
exports.totalCost="Total Cost"
exports.getMyEarningApiEndPoint="bx_block_stripe_integration/payment_methods/my_earnings"
// Customizable Area End
