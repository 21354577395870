import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  location?: any;
  // Customizable Area End
}
interface ErrorLoginInterface {
  emailError?: string,
  passwordError?: string
}
interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordFieldLogin: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  tabValue: number;
  showPassword: boolean;
  emailError: boolean;
  emailErrorText: string;
  passwordError: boolean;
  passwordErrorText: string;
  emailErrorContractor: boolean;
  emailErrorTextForContractor: string;
  passwordErrorContractor: boolean;
  passwordErrorTextForContractor: string;
  errorLoginObj: ErrorLoginInterface;
  snackbarOpen: boolean;
  signinError: string;
  isAllFieldsFilled: boolean;
  token: string;
  signinSuccessMessage: string;
  loginLoading: boolean;
  switchTabData: Array<string>;
  activeSwitchTab: string;
  fcmModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationApiCallId: string = "";
  apiEmailLoginCallId: string = "";
  signinApiCallId: string = "";
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordFieldLogin: false,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      tabValue: 0,
      showPassword: false,
      emailError: false,
      emailErrorText: '',
      passwordError: false,
      passwordErrorText: '',
      emailErrorContractor: false,
      emailErrorTextForContractor: '',
      passwordErrorContractor: false,
      passwordErrorTextForContractor: '',
      errorLoginObj: {
        emailError: '',
        passwordError: ''
      },
      snackbarOpen: false,
      signinError: '',
      isAllFieldsFilled: false,
      token: '',
      signinSuccessMessage: '',
      loginLoading: false,
      activeSwitchTab: "Customer",
      switchTabData: ["Customer","Contractor"],
      fcmModal: false
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    if(this.props.location !== undefined) {
      const activateTab = (this.props.location.state && this.props.location.state.activeTab) || "Customer";
      this.setState({
          activeSwitchTab :activateTab
      })
  }
    this.getUserData();    
    // Customizable Area End
  }

  // Customizable Area Start
  // Web Event Handling
  navigateForgotPassword = () => {
    this.props.navigation.navigate("ForgotPassword");
  }
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordFieldLogin: !this.state.enablePasswordFieldLogin,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  handleTabChange = (e: React.ChangeEvent<{}>, val: number) => {
    /* istanbul ignore next */
    this.setState({
      tabValue: val,
      email: "",
      password: "",
      enablePasswordFieldLogin: false,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showPassword: false,
      emailError: false,
      emailErrorText: '',
      passwordError: false,
      passwordErrorText: '',
      emailErrorContractor: false,
      emailErrorTextForContractor: '',
      passwordErrorContractor: false,
      passwordErrorTextForContractor: '',
      errorLoginObj: {
        emailError: '',
        passwordError: ''
      },
      snackbarOpen: false,
      signinError: '',
      isAllFieldsFilled: false,
      token: '',
      signinSuccessMessage: ''
    })
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      /* istanbul ignore next */
      if (apiRequestCallId === this.signinApiCallId) {
        /* istanbul ignore next */
        if (responseJson && !responseJson.errors) {
          const { meta } = responseJson;
          console.log(responseJson,"responseJson")
          this.setState({ token: meta.token });
          localStorage.setItem('signin-token', meta.token);
          localStorage.setItem('userData', JSON.stringify(responseJson.data.attributes));
          localStorage.setItem('available', responseJson.data.attributes.is_available);
          localStorage.setItem('userId', responseJson.data.id);
          this.setState({
            signinSuccessMessage: "Successfully Logged In",
            signinError: ''
          }, () => {
            const userType = responseJson.data.attributes.user_type;
            this.setState({ snackbarOpen: true }, () => {
              setTimeout(() => {
                this.props.navigation.navigate(userType === "customer" ? "LandingBlock": "ContractorDashboard");
              }, 1000)
            })
          });

        } else {
          const errormsg = this.handleErrorMsg(responseJson);
          this.setState({
            signinError: errormsg,
            loginLoading: false
          }, () => {
            this.setState({ snackbarOpen: true })
          });

        }
      }
    }
    // Customizable Area End
  }


  handleIconError() {
    /* istanbul ignore next */
    if (this.state.signinError) {
      return <CheckCircleOutlineOutlinedIcon style={{ color: 'red' }} />
    } else {

      return <CheckCircleOutlineOutlinedIcon style={{ color: 'green' }} />
    }
  }

  handleEnablePassword() {
    /* istanbul ignore next */
    if (this.state.enablePasswordFieldLogin) {
      return <VisibilityOutlinedIcon />;
    } else {
      return <VisibilityOffOutlinedIcon />
    }
  }

  handleSigninClose = (event?: React.SyntheticEvent, reason?: string) => {
    /* istanbul ignore next */
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  };


  isAuthenticated() {
    const token = typeof window !== 'undefined' ? localStorage.getItem('signin-token') : null;
    return !!token;
  }

  getUserData = () => {
    const localStorageUserData:any = localStorage.getItem("userData");     
    if(localStorageUserData){
      const userData = JSON.parse(localStorageUserData);
      const userType = userData.user_type;
    }
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    const { email, password, checkedRememberMe } = this.state;
    const {
      email: prevEmail,
      password: prevPassword,
    } = prevState;

    if (
      email !== prevEmail ||
      password !== prevPassword
    ) {
      this.checkAllFieldsFilled();
    }
  }


  checkAllFieldsFilled = () => {
    const { email, password } = this.state;

    if (email && password) {
      this.setState({ isAllFieldsFilled: true });
    } else {
      this.setState({ isAllFieldsFilled: false });
    }
  };

  handleEmailLoginChange = (event: any) => {
    const value = event.target.value;
    this.setState({ email: value })
  };

  handleLoginPasswordChange = (e: any) => {
    this.setState({
      password: e.target.value
    });
  }


  validateLoginEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email)
  };

  validateLoginPassword = (password: string): boolean => {
    // Minimum 8 characters, both uppercase and lowercase, at least one digit, and one special character
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&*?<>',\[\]\}\{\=\-\)\(\^%`~+\.:;_])\S{8,}$/;
    return passwordPattern.test(password);
  };

  validateLoginForm = () => {
    let errorList: ErrorLoginInterface = {}
    if (!this.validateLoginEmail(this.state.email)) {
      errorList.emailError = "Invalid email address"
    }
    /* istanbul ignore next */
    if (!this.state.password) {
      errorList.passwordError = configJSON.passworErr2
    }
    if (this.state.password) {
      /* istanbul ignore next */
      if (!this.validateLoginPassword(this.state.password)) {
        errorList.passwordError = configJSON.passworErro
      }
    }

    if (Object.keys(errorList).length == 0) {
      /* istanbul ignore next */
      this.setState({
        errorLoginObj: {
          emailError: '',
          passwordError: ''
        }
      });
      return true;
    } else {
      this.setState({ errorLoginObj: errorList });
      return false;
    }
  }

  closeFcmModal = () => {
    this.setState({
      fcmModal: false
    })
  }

  handleUserLogin = async (e:any) => {
    e.preventDefault();
    if (!this.validateLoginForm()) return;
    this.setState({loginLoading: true});
    const fcmToken = await localStorage.getItem('fcmToken');
    const headers = {
      "Content-Type": 'application/json',
    };
    const bodyData = {
      "type": "email_account",
      "attributes": {
        "email": this.state.email,
        "password": this.state.password,
        "user_type": "customer",
        "device_id": fcmToken
      }
    };
    const bodyForContractor = {
      "type": "email_account",
      "attributes": {
        "email": this.state.email,
        "password": this.state.password,
        "user_type": "contractor",
        "device_id": fcmToken
      }
    }
    if(!fcmToken){
      this.setState({
        loginLoading: false,
        fcmModal: true
      })
      return ""
    }
    const httpBody = {
      data: this.state.activeSwitchTab === "Customer" ? bodyData : bodyForContractor
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signinApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'login'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  switchTab = (tabName: string) => {
    this.setState({ 
      activeSwitchTab: tabName,
      email:"",
      password:""
    });
  }

  handleErrorMsg = (responseJson: any) => {
       const err = responseJson.errors[0].user_type || responseJson.errors[0].failed_login
       return err
  }

  handleSignUpNaviigation = () => {
    this.props.history.push({
      pathname: "/signup",
      state: { activeTab: this.state.activeSwitchTab }
    })
  }
}
