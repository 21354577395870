// Customizable Area Start
import React from "react";
import moment from "moment";
import MessageListView from "./MessageListView";

const MessageList = ({ messages, localParticipantId }) => {
  const formatAMPM = (date) => {
    return moment(date).format('hh:mm A')
  };
  
  const viewProps = {
    messages,
    formatAMPM,
    localParticipantId
  };

  return <MessageListView testID="MessageListView" {...viewProps} />;
};

export default MessageList;
// Customizable Area End
