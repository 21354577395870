import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { Color } from '@material-ui/lab';
import { cropImage } from "../../emailnotifications2/src/assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  showEngagementModal: boolean;
  engageMentRecords?: any;
  engagementModalType: string;
  closeModal:  (id?: any) => void;   
  onSubmitRating: (body:any) => void;
  onAcceptEngagement: () => void;
  showAlertMsg?: (msg: string, type: Color) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ratingCat: any;
  anythingElse: string;
  engageMentRecords: any;
  disableEngagementAcceptBtn: boolean;
  pageLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EngagementModalController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
   declineRequestApiId: string = "";
   acceptRequestApiId: string = "";
   validateRooomIdApiId: string="";
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      anythingElse: '',
      engageMentRecords: {},
      ratingCat: [
        {title: "Professionalism", key:"professionalism", value: 0},
        {title: "Efficiency", key:"efficiency", value: 0},
        {title: "Likelihood To Recommend", key:"likelihood_to_recommend", value: 0}
      ],
      disableEngagementAcceptBtn: false,
      pageLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );      
      if(responseJson.status === 500){        
        return "";
      }  
      if (apiRequestCallId === this.declineRequestApiId) {
        this.props.closeModal(this.state.engageMentRecords.meeting_id);
      }
      if (apiRequestCallId === this.acceptRequestApiId) {
         this.onAccEngagement();
      }
      if(apiRequestCallId === this.validateRooomIdApiId){        
        if(!responseJson.data.disabled){
          this.onAcceptReq();
        }else{
          this.props.closeModal();  
          this.props.showAlertMsg && this.props.showAlertMsg("Customer Already left the Meeting", "error")        
        }     
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.checkEngagementrecord(this.props.engageMentRecords) 
  }

  checkEngagementrecord = async (engageMentRecords: any) => {
    const engRecords = engageMentRecords
    if(engageMentRecords?.photo){     
      engRecords.photo = await cropImage(engRecords.photo, false);
    }
    this.setState({
      engageMentRecords: engRecords,
      disableEngagementAcceptBtn: false
    }) 
  }

  callAnApi(props:any){
    const delclineRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    delclineRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    delclineRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(props.headers));
    if(props.body){
      delclineRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(props.body));
    }
    delclineRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage),props.requestType);
    runEngine.sendMessage(delclineRequest.id, delclineRequest);
    return delclineRequest.messageId;
  }
  onDeclineReq = () => {
    this.setState({
      pageLoading: true
    })
    const token= localStorage.getItem('signin-token');
    const customerId = this.state.engageMentRecords.customer.id;
    const roomId = this.state.engageMentRecords.meeting_id
    const headers= {
      'Content-Type': 'application/json',
      token: token
    }
    const postBody = {
      customer_id: customerId,
      room_id:roomId
    }
    this.declineRequestApiId = this.callAnApi({
      headers: headers, 
      body: postBody,
      endPoint: `bx_block_video_call/meetings/request_deny`,
      requestType: "POST"
    })
  }
  validateRooomId = () => {
    this.setState({
      disableEngagementAcceptBtn: true
    })
    const token= localStorage.getItem('signin-token');
    const headers= {
      'Content-Type': 'application/json',
      token: token
    }
    const httpBody = { "roomId": this.state.engageMentRecords.meeting_id };
    this.validateRooomIdApiId = this.callAnApi({
      headers: headers,      
      endPoint: "bx_block_video_call/meetings/validate_meeting",
      requestType: "POST",
      body: httpBody
    })  
  }
  onAcceptReq = () => {    
    const token= localStorage.getItem('signin-token');
    const customerId = this.state.engageMentRecords.customer.id;
    const roomId = this.state.engageMentRecords.meeting_id
    const headers= {
      'Content-Type': 'application/json',
      token: token
    }
    const postBody = {
      customer_id: customerId,
      room_id:roomId
    }
    this.acceptRequestApiId = this.callAnApi({
      headers: headers, 
      body: postBody,
      endPoint: `/bx_block_notifications/request_accept`,
      requestType: "POST"
    })
  }
  onAccEngagement = () => {
    this.props.history.replace({
      pathname: "/GroupVideoCall",
      state: {engageMentRecords : this.props.engageMentRecords},
    });
  }
  onFieldChange = (event: any) => {
    this.setState({ anythingElse: event.target.value })
  }
  onStarClick = (index: any, value:any) => {
    const updatedRatingCat = [...this.state.ratingCat];
    updatedRatingCat[index].value = value;
    this.setState({ 
      ratingCat: updatedRatingCat 
    });
  }
 
  onRatingSubmit = () => {
    const { ratingCat } = this.state;
    const body = {
      data: {
        attributes: {
        //   account_id: this.state.selectedContractorId,
          efficiency: ratingCat.find((cat:any) => cat.key === 'efficiency')?.value || 0,
          professionalism: ratingCat.find((cat:any) => cat.key === 'professionalism')?.value || 0,
          likelihood_to_recommend: ratingCat.find((cat:any) => cat.key === 'likelihood_to_recommend')?.value || 0,
          anything_else: this.state.anythingElse,
        },
      },
    };
    
    this.props.onSubmitRating(body);    
  }
  // Customizable Area End
}
