import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    InputAdornment,
    Box,
    Button,
    Typography,
    IconButton,
    Checkbox,
    Grid,
    TextField,
    Snackbar,
    InputLabel,
    Link,
    styled,
    Modal,
} from "@material-ui/core";
import './login.css';
import { backgroundImage, boyImg, girlImg, notificationPermission, personLogo } from './assets';
import EmailAccountController, {
    configJSON,
    Props,
} from "./EmailAccountController";
import { Alert as MuiAlert } from '@material-ui/lab';
import Header from "../../emailnotifications2/src/TopHeader.web";

// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

// Customizable Area Start
export default class LoginBlock extends EmailAccountController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    // Customizable Area Start

    render() {
        return (
            <>
                <Header />
                <ThemeProvider theme={theme}>
                    <MainBox>
                        <Snackbar open={this.state.snackbarOpen}
                            autoHideDuration={4000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            onClose={this.handleSigninClose}>
                            <MuiAlert icon={this.handleIconError()} onClose={this.handleSigninClose} style={{ width: 421, backgroundColor: "white", color: 'black', position: 'absolute', top: '70px',marginLeft:"300px", borderRadius:"8px", height:"42px" }} variant="filled">
                                <Typography>{this.state.signinError || this.state.signinSuccessMessage}</Typography>
                            </MuiAlert>
                        </Snackbar>
                        <Grid
                            justifyContent="center"
                            container
                            style={{ margin: '100px 0', width: 'unset' }}
                        >
                            <Grid item md={4}
                                style={{
                                    border: '0.5px solid #e7eff7',
                                    boxShadow: '0 10px 20px 0 rgb(216, 255, 255)',
                                    borderRadius: '10px',
                                    backgroundColor: '#ffffff'
                                }}
                            >
                                <CustomTab>
                                    {
                                        this.state.switchTabData.map((tabname: string, index: number) => {
                                            return (
                                                <>
                                                    {
                                                        this.state.activeSwitchTab === tabname ?
                                                            <ActiveButton
                                                                data-test-id="tabId"
                                                                key={index} onClick={() => this.switchTab(tabname)}>{tabname}</ActiveButton>
                                                            :
                                                            <UnActiveButton data-test-id="unactivetabId" key={index} onClick={() => this.switchTab(tabname)}>{tabname}</UnActiveButton>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </CustomTab>
                                {
                                    this.state.activeSwitchTab === "Customer" &&
                                    <Box>
                                         <form onSubmit={this.handleUserLogin}>
                                        <InputBox>
                                            <CustomInputLable>{configJSON.email}</CustomInputLable>
                                            <TextField
                                                data-test-id="txtInputEmail"
                                                variant="outlined"
                                                id="outlined-start-adornment"
                                                placeholder="xyz@gmail.com"
                                                value={this.state.email}
                                                onChange={this.handleEmailLoginChange}
                                                error={!!this.state.errorLoginObj.emailError}
                                                helperText={this.state.errorLoginObj.emailError}
                                                style={{ marginTop: '15px', width: '100%' }}
                                            />
                                        </InputBox>
                                        <InputBox>
                                            <CustomInputLable htmlFor="outlined-adornment-password"
                                            >{configJSON.infor}</CustomInputLable>
                                            <TextField
                                                data-test-id="txtInputPassword"
                                                variant="outlined"
                                                id="outlined-adornment-password"
                                                style={{ marginTop: '15px', width: '100%' }}
                                                type={this.state.enablePasswordFieldLogin ? 'text' : 'password'}
                                                error={!!this.state.errorLoginObj.passwordError}
                                                helperText={this.state.errorLoginObj.passwordError}
                                                value={this.state.password}
                                                placeholder="*******"
                                                onChange={this.handleLoginPasswordChange}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={this.handleClickShowPassword}
                                                            edge="end"
                                                            style={{ fontSize: "small" }}
                                                        >
                                                            {this.handleEnablePassword()}
                                                        </IconButton>
                                                    </InputAdornment>

                                                }}
                                            />
                                        </InputBox>
                                        <Box
                                            style={{
                                                marginRight: '50px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: '50px',
                                                justifyContent: 'space-between',
                                                marginTop: 20,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    fontSize: "13px",
                                                    alignItems: 'center',
                                                    fontWeight: "bold",
                                                    display: 'flex',
                                                }}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    data-test-id={"btnRememberMe"}
                                                    style={{
                                                        padding: 0
                                                    }}
                                                    onClick={() =>
                                                        this.setRememberMe(!this.state.checkedRememberMe)
                                                    }
                                                    icon={<CustomCheckBoxIcon />}
                                                    checked={this.state.checkedRememberMe}
                                                />{" "}
                                                <Typography style={{ marginLeft: '8px', fontSize: '13px' }}>{configJSON.remember}</Typography>
                                            </Box>
                                            <Typography data-test-id={"btnForgotPassword"}><ForgetPasswordLink underline="none" onClick={this.navigateForgotPassword} style={{ color: "#2966FF" }} >{configJSON.forgotPassword}</ForgetPasswordLink></Typography>
                                        </Box>
                                        <LoginButtonBoxTwo>
                                            <LoginButton type="submit" disabled={this.state.loginLoading} data-test-id={"btnEmailLogIn"} style={{ textTransform: 'capitalize', width: '100%', backgroundColor: this.state.loginLoading ? '' : '#2967ff' }} variant="contained" color="primary">
                                                {configJSON.login}
                                            </LoginButton>
                                        </LoginButtonBoxTwo>
                                        </form>
                                        <DontHavenAccountBox>
                                            <DontHavenAccountText>{configJSON.dont}<SignUpText data-test-id="custSignUp" onClick={this.handleSignUpNaviigation} underline="none"  style={{ fontSize: '14px', fontWeight: 'bold', cursor: "pointer" }}>{` Sign up`}</SignUpText></DontHavenAccountText>
                                        </DontHavenAccountBox>
                                    </Box>
                                }
                                {
                                    this.state.activeSwitchTab === "Contractor" &&
                                    <Box>
                                        <form onSubmit={this.handleUserLogin}>
                                        <InputBox>
                                            <CustomInputLable>{configJSON.email}</CustomInputLable>
                                            <TextField
                                                style={{ marginTop: '15px', width: '100%' }}
                                                data-test-id="txtInputEmail1"
                                                variant="outlined"
                                                id="outlined-start-adornment"
                                                placeholder="xyz@gmail.com"
                                                value={this.state.email}
                                                helperText={this.state.errorLoginObj.emailError}
                                                onChange={this.handleEmailLoginChange}
                                                error={!!this.state.errorLoginObj.emailError}
                                            />
                                        </InputBox>
                                        <InputBox>
                                            <CustomInputLable htmlFor="outlined-adornment-password">{configJSON.infor}</CustomInputLable>
                                            <TextField
                                                data-test-id="txtInputPassword1"
                                                variant="outlined"
                                                id="outlined-adornment-password"
                                                type={this.state.enablePasswordFieldLogin ? 'text' : 'password'}
                                                style={{ marginTop: '15px', width: '100%', textAlign: 'center' }}
                                                error={!!this.state.errorLoginObj.passwordError}
                                                helperText={this.state.errorLoginObj.passwordError}
                                                value={this.state.password}
                                                onChange={this.handleLoginPasswordChange}
                                                placeholder="*******"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={this.handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {this.handleEnablePassword()}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                            />
                                        </InputBox>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 20,
                                                marginLeft: '50px',
                                                marginRight: '50px'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    fontSize: "13px",
                                                    fontWeight: "bold",
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Checkbox
                                                    onClick={() =>
                                                        this.setRememberMe(!this.state.checkedRememberMe)
                                                    }
                                                    data-test-id={"btnRememberMe1"}
                                                    color="primary"
                                                    style={{
                                                        padding: 0
                                                    }}
                                                    icon={<CustomCheckBoxIcon />}
                                                    checked={this.state.checkedRememberMe}
                                                />{" "}
                                                <Typography style={{ marginLeft: '8px', fontSize: '13px' }}>{configJSON.remember}</Typography>
                                            </Box>
                                            <Typography data-test-id={"btnForgotPassword1"}><ForgetPasswordLink underline="none" onClick={this.navigateForgotPassword} style={{ color: "#2966FF" }}>{configJSON.forgotPassword}</ForgetPasswordLink></Typography>
                                        </Box>
                                        <LoginButtonBox>
                                            <LoginButton type="submit" disabled={this.state.loginLoading} data-test-id={"btnEmailLogIn1"} style={{ textTransform: 'capitalize', width: '100%', backgroundColor: this.state.loginLoading ? 'gray' : '#2967ff' }} variant="contained" color="primary">
                                                {configJSON.login}
                                            </LoginButton>
                                        </LoginButtonBox>
                                        </form>
                                        <ContractorDontHavenAccountBox>
                                            <ContractorDontHavenAccountText>{configJSON.dont}<SignUpText underline="none" data-test-id="contraSignUp" onClick={this.handleSignUpNaviigation}  style={{ fontSize: '14px', fontWeight: 'bold', cursor: "pointer" }}>{` Sign up`}</SignUpText></ContractorDontHavenAccountText>
                                        </ContractorDontHavenAccountBox>
                                    </Box>
                                }
                            </Grid>
                            <Grid item md={4}>
                                <ImageBox>
                                    <PersonIcon className="toHideInMobile" src={personLogo} />
                                    <GirlImage className="toHideInMobile" src={girlImg} />
                                    <BoyImage className="toHideInMobile" src={boyImg} />
                                </ImageBox>
                            </Grid>
                        </Grid>
                        {this.state.fcmModal &&  <Modal
                            open={this.state.fcmModal}
                            // onClose={this.props.closeModal}
                            >
                            <Box sx={webStyles.modalContainer}>
                                
                                 
                                    <Typography variant="h5" className="modal-Engagement-header" component="div">Notification not enabled, please enable Notification by clicking on the additional permission and allowing it. After Allowing Please refresh the page</Typography>
                                 
                                <div className="modal-image-container">
                                    <img src={notificationPermission} style={{width:"60%"}}/>
                                </div>

                                <Box sx={webStyles.modalButtonView}>                                    
                                    <Button variant="contained" size="large" color="primary"className="custom-button-padding custom-button-contained" onClick={this.closeFcmModal}> Okay </Button>
                                </Box>  
                                                
                            </Box>      
                        </Modal>}
                        <LoginBackgroudImage className="toHideInMobile" src={backgroundImage} />
                    </MainBox>
                </ThemeProvider >
            </>
        );
    }
}

// Customizable Area End

// Customizable Area Start

const CustomTab = styled(Box)({
    display: "flex",
})

const ActiveButton = styled("button")({
    display: "flex",
    width: "100%",
    height: "72px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    border: "none",
    backgroundColor: "#ffffff",
    gap: "8px",
    color: "#2966FF",
    fontFamily: "Lato",
    fontSize: "16px",
})

const UnActiveButton = styled("button")({
    width: "100%",
    height: "72px",
    padding: "16px",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    color: "#64748B",
    fontFamily: "Lato",
    fontSize: "16px",
    border: "none",
    display: "flex",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    background: "#F8FAFC",
    boxShadow: "4px -4px 4px 0px rgba(130, 133, 148, 0.05) inset",
    cursor: "pointer"
})

const LoginButton = styled(Button)({
    color: "#FFF",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    display: "flex",
    width: "358px",
    height: "56px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    backgrounColord: "#2966FF",
})

const DontHavenAccountText = styled(Typography)({
    color: "#1E293B",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
})

const ForgetPasswordLink = styled(Link)({
    color: "#2966FF !importent",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    cursor: 'pointer',
    fontWeight: 400,

})

const ContractorDontHavenAccountText = styled(Typography)({
    color: "#1E293B",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
})

const SignUpText = styled(Link)({
    color: "#2966FF",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
})

const CustomInputLable = styled(InputLabel)({
    color: "#1E293B",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
})

const CustomCheckBoxIcon = styled(Box)({
    width: "20px",
    height: "20px",
    borderRadius: "6px",
    border: "1px solid #CBD5E1"
})

const MainBox = styled(Box)({
    backgroundColor: 'rgb(236, 253, 253)',
    position: "relative"
})

const InputBox = styled(Box)({
    margin: "20px 50px"
})

const LoginButtonBox = styled(Box)({
    margin: "20px 50px"
})

const LoginButtonBoxTwo = styled(Box)({
    margin: "20px 50px"
})


const DontHavenAccountBox = styled(Box)({
    textAlign: 'center',
    marginTop: "100px",
    marginBottom: "20px"
})


const ContractorDontHavenAccountBox = styled(Box)({
    textAlign: 'center',
    marginTop: "100px",
    marginBottom: "20px"
})

const ImageBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    position: "relative",
})

const GirlImage = styled("img")({
    height: "400px",
    position: "absolute",
    top: 60,
    left: 80,
    zIndex: 5
})

const BoyImage = styled("img")({
    height: "400px",
    position: "absolute",
    top: -24,
    right: -30,
    zIndex: 5
})

const LoginBackgroudImage = styled("img")({
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 1,
    height: "500px",
    width: "544px",
})

const PersonIcon = styled("img")({
    right: "175px",
    top: "60px",
    zIndex: 1,
    position: "absolute",
    height: "50px",
    width: "50px"
})
const webStyles = {
    engagementContent: {
        flexDirection: "column",
        display: "flex",
        gap: 10,
        borderBottom: "1px solid #1E293B3D",
        padding: 25,
    },    
    modalContainer: {
      display: "flex",
      top: "50%",
      left: "50%",
      flexDirection: "column",
      position: "absolute" as "absolute",
      bgcolor: "background.paper",
      borderRadius: 8,
      boxShadow: 24,
      transform: "translate(-50%, -50%)",
    },
    modalButtonView: {
        display: "flex",
        justifyContent: 'right',
        paddingRight: 25,
        gap: 10,
        paddingBottom: 15,
        paddingTop: 20,
    }    
  };
// Customizable Area End