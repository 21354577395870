import React from "react";

import { createTheme, ThemeProvider, withStyles, Theme, createStyles, styled } from "@material-ui/core/styles";
import {
    IconButton,
    Box,
    Typography,
    Grid,
    Switch,
    LinearProgress,
    Table,
    Button,
    MenuItem,
    Modal,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    CircularProgress,
    Chip,
    Tooltip,
    Snackbar,
    InputLabel,
    Select,
    Checkbox,
    TextField
} from "@material-ui/core";
import { Alert as MuiAlert } from '@material-ui/lab';
import './dashboard.css';
import DashboardController, { Props, configJSON, SortHeaders } from "./DashboardController";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import { Pending_earnings, Lifetime_earnings, Engagements, profileLogo, noRecordLogo} from './assets';
import { Chart } from "react-google-charts";
import StarHalfIcon from '@material-ui/icons/StarHalf';
import EngagementModal from "./EngagementModal.web";
import moment from "moment";
import SearchContractor from "../../landingpage/src/searchContractor.web";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Header from "../../emailnotifications2/src/TopHeader.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Loader from "../../../components/src/Loader.web";
import {greenTick} from "../../forgot-password/src/assets"
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: "#2966FF"}} />;

const theme = createTheme({
    palette: {
        primary: {
            main: "#2966FF",
            contrastText: "#fff",
        },
    },
});

const chipData = {
    PENDING: {
        title: "Pending",
        backgroundColor: "rgba(255, 41, 41, 0.20)",
        fontColor: "#DC2626"
    },
    CONTESTED: {
        title: "Contested",
        backgroundColor: "rgba(203, 213, 225, 0.60)",
        fontColor: "#1E293B",
    },
    PAID: {
        title: "Paid",
        backgroundColor: "rgba(41, 102, 255, 0.30)",
        fontColor: "#2966FF"
    },
    REFUNDED: {
        title: "Refunded",
        backgroundColor: "rgba(110, 235, 255, 0.30)",
        fontColor: "#199FB4"
    },
}

const getPaymentChip = (chipTitle: string) => {
    switch (chipTitle) {
        case "pending":
            return chipData.PENDING
        case "contested":
            return chipData.CONTESTED
        case "paid":
            return chipData.PAID
        case "refunded":
            return chipData.REFUNDED
    }
}

const customerData = (item: any) => {

    // Fullname
    let firstName: string = ""
    let lastName: string = ""
    if (item.attributes.customer.data && item.attributes.customer.data.attributes.first_name) {
        firstName = item.attributes.customer.data.attributes.first_name
    }
    if (item.attributes.customer.data && item.attributes.customer.data.attributes.last_name) {
        lastName = item.attributes.customer.data.attributes.last_name
    }
    const FullName = firstName + " " + lastName;

    // Payment Status
    let paymentStatus: any = "-"
    if (item.attributes.payment_status) {
        paymentStatus = getPaymentChip(item.attributes.payment_status)
    }

    // Request MSG
    let engagementRequest: string = "-"
    if (item.attributes.engagement_request) {
        engagementRequest = item.attributes.engagement_request
    }
    // Date
    let itemStartDate: string = "-"
    if (item.attributes.service_start_time) {
        itemStartDate = moment(item.attributes.service_start_time).format('DD MMM YYYY, hh:mm A') // create date function accoring to requirement
    }

    // Duration
    let durationTime: string = "-"
    if (item.attributes.duration) {
        durationTime = item.attributes.duration + " Hrs"
    }

    // PlateForm
    let platformName: string = "-"
    if (item.attributes.platform) {
        platformName = item.attributes.platform
    }

    // TotalCost
    let totalCost: string = "$0"
    if (item.attributes.total_cost) {
        totalCost = "$" + item.attributes.total_cost
    }

    // Profile Image 
    let profileImg: string = profileLogo
    if (item.attributes.customer.data && item.attributes.customer.data.attributes.photo) {
        profileImg = item.attributes.customer.data.attributes.photo
    }

    return { FullName, paymentStatus, engagementRequest, itemStartDate, durationTime, platformName, totalCost, profileImg }

}

const applyFilter = (data: any) => {
    const currentMonth = new Date().toLocaleString('default', { month: 'short' });
    return data.map((item:any, index: number) => {
        if (index === 0) {
            return item; // Keep the header row unchanged
        } else {
            return [item[0], item[1], (item[0] === currentMonth) ? '#2966FF' : '#DBEAFE'];
        }
    });
};

export default class DashboardBlock extends DashboardController {
    constructor(props: Props) {
        super(props);
    }

    showEngagementRecords = () => {
        return (
            this.state.engagementData.length > 0 ? this.state.engagementData.map((row: any, index: number) => {
                const { FullName, durationTime, engagementRequest, itemStartDate, paymentStatus, platformName, totalCost, profileImg } = customerData(row)
                return (
                    <>
                        <TableRow onClick={() => this.redirectToEngDetailPage(row)} className="table-content" data-test-id={`engagement-${index}`} key={`engagement-${index}`}>
                            <TableCell className="image-container" component="th" scope="row">
                                <img src={profileImg }
                                 alt="No Img" className="user-img" />
                                {FullName}
                            </TableCell>
                            <TableCell align="left">
                                <span className="status-pending">
                                    <Chip label={paymentStatus.title} style={{ color: paymentStatus.fontColor, backgroundColor: paymentStatus.backgroundColor }} />
                                </span>
                            </TableCell>
                            <TableCell align="left">
                                <Tooltip title={engagementRequest}>
                                    <Box style={{ maxWidth: "150px" }}>
                                        <Typography noWrap>{engagementRequest}</Typography>
                                    </Box>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="left">{itemStartDate}</TableCell>
                            <TableCell align="left">{durationTime}</TableCell>
                            <TableCell align="left">{platformName}</TableCell>
                            <TableCell align="left">{totalCost}</TableCell>
                        </TableRow>
                    </>
                )
            })
                :
                <TableRow>
                    <TableCell align="center" colSpan={7} style={{ fontWeight: 700,height: '500px', padding: '20px' }}>
                        <img src={noRecordLogo} alt="no record" />
                        <p style={{ color: "#94A3B8", fontFamily: "Lato"}}>{configJSON.noDataFound}</p>    
                    </TableCell>
                </TableRow>
        )
    }

    handleSortingArrows = (key: keyof SortHeaders) => {
        const arrowHanle = this.state.sortHeader[key] ? <span> &#8593; </span> : <span> &#8595; </span>
        return arrowHanle
    }

    previousYearArr = () => {
        const currentYear: number = new Date().getFullYear();
        let last5Years: number[] = [];

        for (let i = 0; i < 5; i++) {
            last5Years.push(currentYear - i);
        }

        return last5Years
    }


    render() {
        const userData = this.state.accountData?.attributes
        return (
            <>
                <Snackbar
                    open={this.state.snackbarOpen}
                    autoHideDuration={2000} style={{ top: "80px", boxShadow: "0px 6px 15px -3px #00000026" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={this.closeAlertMsg}
                >
                <MuiAlert 
                    style={{ backgroundColor: "white", borderRadius: "8px",display: "flex", alignItems: "center"}}
                    iconMapping={{ success: <img src={greenTick} width={20} height={20} />}}
                    severity={this.state.alertMsgType}
                ><Typography>{this.state.alertMsg}</Typography></MuiAlert>
                </Snackbar>
                <Header />
                {this.state.pageLoader &&  <Loader loading={true} />}
                <ThemeProvider theme={theme}>
                    <Box className="contractor-dashboard" style={{ backgroundColor: 'rgb(236, 253, 253)', display: 'flex' }}>
                        <Box style={{ height: '100%', width: 200, backgroundColor: 'white', }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <IconButton disableTouchRipple size='small' style={{  marginTop: '40px' }}>
                                    {(userData && userData.photo) ?
                                        <img src={userData.photo} style={{ width: 80, height: 80, borderRadius: '50%', border: '5px solid #2966ff' }} alt="userImg" />
                                        : <IconButton disableTouchRipple style={{ width: 80, height: 80, borderRadius: '50%', border: '5px solid #2966ff', backgroundColor: "rgb(203, 213, 225)" }}>
                                            <PersonOutlineOutlinedIcon />
                                        </IconButton>}
                                </IconButton>
                                <Typography style={{ marginTop: 20, marginBottom: 10, fontWeight: 'bold', fontSize: '18px' }} >{userData?.first_name}</Typography>
                                <Typography style={{ fontSize: '14px' }}>{configJSON.hourly}{userData?.contractor_price}</Typography>
                            </Box>
                        </Box>
                        <Grid container spacing={0}>
                            <Grid item md={8} sm={10}>
                                <Box className="cardBox">
                                    <Box className="box">
                                        <Box>
                                            {this.renderModal()}
                                            <Typography data-test-id="textId" variant="h5" style={{ marginBottom: 10 }}>Availability</Typography>
                                            <AntSwitch  data-test-id="modalid" checked={this.state.availableStatus} onChange={this.changeAvailability} name="checkedC" />
                                        </Box>
                                    </Box>
                                    <Box className="box" >
                                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={Lifetime_earnings} />
                                        </Box>
                                        <Box style={{ marginLeft: '20px' }}>
                                            <Typography style={{ color: '#94A3B8', marginBottom: '8px', fontFamily: "Lato", fontSize: "14px" }}>{configJSON.lifetimeEarning}</Typography>
                                            <Typography style={{ color: "#21272A", fontFamily: "Lato", fontSize: "32px" }} variant="h5">${this.state.contractorDashboardData?.lifetime?.total}</Typography>
                                            <Box style={{ marginTop: '8px', display: "flex", alignItems: "center" }}>
                                                {this.state.contractorDashboardData?.lifetime?.month >= 0 ?
                                                <ArrowUpwardOutlinedIcon style={{ color: '#2aa980', height: "20px", width: "20px" }} /> :
                                                <ArrowDownwardOutlinedIcon style={{ color: '#DC2626', height: "20px", width: "20px" }} /> 
                                                }
                                                <Typography style={{ display: 'flex', color:this.state.contractorDashboardData?.lifetime?.month >= 0 ?  '#059669' : 'red', fontSize: "12px", fontFamily: "Lato", }}>{this.state.contractorDashboardData?.lifetime?.month}% <Typography style={{ color: 'black', fontFamily: "Lato", marginLeft: '10px', fontSize: "12px" }}>{configJSON.thisMonth}</Typography></Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="cardBox">
                                    <Box className="box">
                                        <Box>
                                            <img src={Engagements} />
                                        </Box>
                                        <Box style={{ marginLeft: '20px' }}>
                                            <Typography style={{ color: '#94A3B8', marginBottom: '8px', fontFamily: "Lato", fontSize: "14px" }}>{configJSON.totalEnagagements}</Typography>
                                            <Typography style={{ color: "#21272A", fontFamily: "Lato", fontSize: "32px" }} variant="h5">{this.state.contractorDashboardData?.total_engagement?.total}hrs</Typography>
                                            <Box style={{ display: 'flex', marginTop: '8px', alignItems: "center" }}>
                                            {this.state.contractorDashboardData?.total_engagement?.week >= 0 ?
                                                <ArrowUpwardOutlinedIcon style={{ color: '#2aa980', height: "20px", width: "20px" }} /> :
                                                <ArrowDownwardOutlinedIcon style={{ color: '#DC2626', height: "20px", width: "20px" }} /> 
                                                }
                                                <Typography style={{ display: 'flex', color:this.state.contractorDashboardData?.total_engagement?.week >= 0 ? '#059669' :"red", fontSize: "12px", fontFamily: "Lato", }}>{this.state.contractorDashboardData?.total_engagement?.week}% <Typography style={{ color: 'black', fontFamily: "Lato", marginLeft: '10px', fontSize: "12px" }}>{configJSON.thisweek}</Typography></Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="box">
                                        <Box>
                                            <img src={Pending_earnings} />
                                        </Box>
                                        <Box style={{ marginLeft: '20px' }}>
                                            <Typography style={{ color: '#94A3B8', marginBottom: '8px', fontFamily: "Lato", fontSize: "14px" }}>Pending Earning</Typography>
                                            <Typography style={{ color: "#21272A", fontFamily: "Lato", fontSize: "32px" }} variant="h5">${this.state.contractorDashboardData?.pending_earning?.total}</Typography>
                                            <Box style={{ display: 'flex', marginTop: '8px', alignItems: "center" }}>
                                            {this.state.contractorDashboardData?.pending_earning?.month >= 0 ?
                                                <ArrowUpwardOutlinedIcon style={{ color: '#2aa980', height: "20px", width: "20px" }} /> :
                                                <ArrowDownwardOutlinedIcon style={{ color: '#DC2626', height: "20px", width: "20px" }} /> 
                                                }
                                                <Typography style={{ display: 'flex', color:this.state.contractorDashboardData?.pending_earning?.month >= 0 ? '#059669' :"red", fontSize: "12px", fontFamily: "Lato", }}>{this.state.contractorDashboardData?.pending_earning?.month}% <Typography style={{ color: 'black', fontFamily: "Lato", marginLeft: '10px', fontSize: "12px" }}>{configJSON.thisMonth}</Typography></Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                    
                                <Box className="earningBox chart-box">
                                    <Box style={{ margin: "0px 0px 0px 20px", display: 'flex', justifyContent: "space-between" }}>
                                        <p className="earningText" style={{ fontFamily: "Lato"}}>My Earnings</p>
                                        <Select className="yearSelection" data-test-id="yearSelection" onChange={this.handleEarningYear} value={this.state.earningYear} disableUnderline>
                                            {this.previousYearArr().map((item: number) => {
                                                return (
                                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </Box>
                                    <Box>
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="400px"
                                        data={applyFilter(this.state.data)}
                                        options={{
                                            vAxis: {
                                              title: "Earnings ($)",
                                              minValue: 10, 
                                            },
                                          }}
                                    />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={4} sm={10}>
                                <Box className="box3">
                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="h5">{configJSON.reviwes}</Typography>
                                            <StarHalfIcon style={{ color: '#6eebff', marginLeft: '20px', marginRight: '10px' }} />
                                            <Typography style={{ fontFamily: "Lato", color: "#1E293B", }}>{this.state.contractorDashboardData?.reviews?.rating}</Typography>
                                        </Box>
                                        <Typography data-test-id="reviwNavigation" onClick={() => this.props.navigation.navigate("Review")} style={{ color: '#2966FF', textDecorationColor: '#2966FF', fontFamily: "Lato", textDecoration: 'underline', paddingBottom: '3px', cursor: "pointer" }}>{configJSON.viewAll}</Typography>
                                    </Box>
                                    <Box style={{ marginTop: '30px' }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                            <Typography style={{ marginRight: '30px' }}>{configJSON.fiveStar}</Typography>
                                            <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.contractorDashboardData?.reviews?.star?.five} />
                                        </Box>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                            <Typography style={{ marginRight: '30px' }}>{configJSON.fourStar}</Typography>
                                            <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.contractorDashboardData?.reviews?.star?.four} />
                                        </Box>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                            <Typography style={{ marginRight: '30px' }}>{configJSON.threeStar}</Typography>
                                            <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.contractorDashboardData?.reviews?.star?.three} />
                                        </Box>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                            <Typography style={{ marginRight: '30px' }}>{configJSON.twoStar}</Typography>
                                            <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.contractorDashboardData?.reviews?.star?.two} />
                                        </Box>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                            <Typography style={{ marginRight: '30px' }}>{configJSON.oneStar}</Typography>
                                            <BorderLinearProgress style={{ width: '70%' }} variant="determinate" value={this.state.contractorDashboardData?.reviews?.star?.one} />
                                        </Box>

                                    </Box>
                                    <hr style={{ border: '0.5px solid #D3D3D3', marginTop: '40px', width: '100%' }} />
                                    <Box>
                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "23px" }}>
                                            <Box style={{ display: "flex", columnGap: "5px", alignItems: "center" }}>
                                                <PointTag />
                                                <ProfessionalismText component={"span"}>{configJSON.professionalism}</ProfessionalismText>
                                                <PercentText component={"span"}>{this.state.contractorDashboardData?.reviews?.professionalism}%</PercentText>
                                            </Box>
                                            <Box style={{ display: "flex", columnGap: "5px", alignItems: "center" , marginRight:"20px"}}>
                                                <PointTagTwo />
                                                <ProfessionalismText component={"span"}>{configJSON.efficiency}</ProfessionalismText>
                                                <PercentText component={"span"}>{this.state.contractorDashboardData?.reviews?.efficiency}%</PercentText>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: "flex", columnGap: "5px", alignItems: "center", marginTop: "23px" }}>
                                            <PointTagThree />
                                            <ProfessionalismText component={"span"}>{configJSON.likelihood}</ProfessionalismText>
                                            <PercentTextTwo component={"span"}>{this.state.contractorDashboardData?.reviews?.likelihood}%</PercentTextTwo>
                                        </Box>
                                        <Box style={{ height: "295px", display: "flex", justifyContent: "center", alignItems: "center", }}>
                                            <div style={{ height: "202px", width: "202px", position: "relative", }}>
                                                <div
                                                    style={{
                                                        height: "200px",
                                                        width: "200px",
                                                        position: "absolute",
                                                        borderRadius: "100%",
                                                    }}
                                                >
                                                    <CircularProgressbar
                                                        value={this.state.contractorDashboardData?.reviews?.professionalism}
                                                        strokeWidth={10}
                                                        styles={{
                                                            path: { stroke: "#2966FF" },
                                                            trail: { stroke: "#F1F5F9" },
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        height: "150px",
                                                        width: "150px",
                                                        position: "absolute",
                                                        top: "25px",
                                                        left: "25px",
                                                        borderRadius: "100%",
                                                    }}
                                                >
                                                    <CircularProgressbar
                                                        value={this.state.contractorDashboardData?.reviews?.efficiency}
                                                        strokeWidth={13}
                                                        styles={{
                                                            path: { stroke: "#DB2777" },
                                                            trail: { stroke: "#fff" },
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        height: "95px",
                                                        width: "95px",
                                                        position: "absolute",
                                                        top: "53px",
                                                        left: "53px",
                                                        borderRadius: "100%",
                                                    }}
                                                >
                                                    <CircularProgressbar
                                                        value={this.state.contractorDashboardData?.reviews?.likelihood}
                                                        strokeWidth={20}
                                                        styles={{
                                                            path: { stroke: "#312E81" },
                                                            trail: { stroke: "#fff" },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={12} sm={10}>
                                <Box className="box h-max-content mr-24">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    padding: "10px"
                                                }}
                                            >
                                                <Typography className="w-100" gutterBottom variant="h5">
                                                    {configJSON.recentEngagementRecords}
                                                </Typography>
                                                <span style={{fontFamily: "Lato", fontSize: "17px"}} data-test-id ="nextScreenCall" className="float-right anchor text-underLine f-16" onClick={this.handleNavigate}>View All</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ScrollableContainer>
                                            <StyledTable className="engagment-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell
                                                         className="tag-colour-Box"
                                                        data-test-id="firstNameCallID"
                                                        onClick={()=> this.sortTableDataHandlerBlock("customer", "first_name", this.state.sortingOrder)}
                                                        >{configJSON.customer} {this.handleSortingArrows("customer")}</TableCell>
                                                        <TableCell
                                                        className="tag-colour-Box"
                                                        data-test-id="payMentCallID"
                                                         onClick={()=> this.sortTableDataHandlerBlock("payment_status", "", this.state.sortingOrder)}
                                                        align="left">{configJSON.paymentStatusTwo} {this.handleSortingArrows("payment_status")}</TableCell>
                                                        <TableCell
                                                        className="tag-colour-Box"
                                                        data-test-id="engagementRequestCallID"
                                                        onClick={()=> this.sortTableDataHandlerBlock("engagement_request", "", this.state.sortingOrder)}
                                                        align="left">{configJSON.request} {this.handleSortingArrows("engagement_request")}</TableCell>
                                                        <TableCell
                                                        className="tag-colour-Box"
                                                        data-test-id="startTimeCallID"
                                                        onClick={()=> this.sortTableDataHandlerBlock("service_start_time", "",this.state.sortingOrder)}
                                                        align="left">{configJSON.date} {this.handleSortingArrows("service_start_time")}</TableCell>
                                                        <TableCell 
                                                        className="tag-colour-Box"
                                                        data-test-id="durationCallID"
                                                        onClick={()=> this.sortTableDataHandlerBlock("duration", "",this.state.sortingOrder)}
                                                        align="left">{configJSON.duration} {this.handleSortingArrows("duration")}</TableCell>
                                                        <TableCell
                                                        className="tag-colour-Box"
                                                        data-test-id="platformCallID"
                                                        onClick={()=> this.sortTableDataHandlerBlock("platform", "", this.state.sortingOrder)}
                                                        align="left">{configJSON.Platform} {this.handleSortingArrows("platform")}</TableCell>
                                                        <TableCell 
                                                        className="tag-colour-Box"
                                                        data-test-id="totalcoseCallID"
                                                        onClick={()=> this.sortTableDataHandlerBlock("total_cost", "", this.state.sortingOrder)}
                                                        align="left">{configJSON.total} {this.handleSortingArrows("total_cost")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                </TableBody>
                                                <TableBody>
                                                    {this.state.loader ? <TableRow>
                                                        <TableCell align="center" colSpan={7}>
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow> : this.showEngagementRecords()}
                                                </TableBody>
                                            </StyledTable>
                                            </ScrollableContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </ThemeProvider>
                {this.state.showEngagementModal && <EngagementModal
                    showEngagementModal={this.state.showEngagementModal}
                    engageMentRecords={this.state.engageMentRecords}
                    navigation={undefined} id={""}
                    engagementModalType={this.state.engagementModalType}
                    closeModal={this.closeEngagementModal}
                    onSubmitRating={this.ratingSubmitAPi}
                    onAcceptEngagement={this.onAcceptEngagement}
                    history={this.props.history}
                />}
                {this.state.showRatingModal && <SearchContractor
                    navigation={this.props.navigation}
                    history={this.props.history}
                    id={""}
                    showContractorModal={this.state.showRatingModal}
                    modalContractorType={this.state.ratingModalType}
                    closeModal={this.closeRatingModal}
                    onPregressDone={() => { }}
                    contractorData={{}}
                    onSubmitRating={this.ratingSubmitAPi}
                    onSubmitSelectedContractor={() => { }}
                    location={this.props.location}
                    dashboard={true}
                />}
            </>
        )
    }

    renderModal = () => {
        return (
            <>
                <Modal
                  data-test-id="closeModalId"
                    open={this.state.openModal}
                    onClose={this.handleClose}
                >
                    <ModalContainer>
                        <ModalBody>
                            <ModalTitleContainer>
                                <ModalTitle>{configJSON.welcomeAboard}</ModalTitle>
                                <ModalSubTitle>{configJSON.tagList}</ModalSubTitle>
                            </ModalTitleContainer>
                            <StyledForm>
                                <Fields>
                                    <FieldContainer>
                                        <Box className="countyFields">
                                            <StyledInputLabel id="demo-mutiple-chip-label">{configJSON.subdomain}</StyledInputLabel>
                                            <Autocomplete
                                                multiple
                                                id="checkboxes-tags-demo"
                                                options={this.state.subDomainData}
                                                disableCloseOnSelect
                                                limitTags={1}
                                                data-test-id="subDomain"
                                                className="multi-select-subdomain"
                                                getOptionLabel={(option: any) => option.attributes.name}
                                                defaultValue={this.state.subSkillsDefaultValue}
                                                onChange={(e: any, newVal: any) => this.changeSubDomainData(newVal)}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        <span className="txt-capitalize">{option.attributes.name}</span>
                                                    </React.Fragment>
                                                )}
                                                style={{ padding : 0}}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" placeholder="Subdomain" />
                                                )}
                                            />
                                        </Box>
                                    </FieldContainer>
                                    <FieldContainer>
                                    <Box className="countyFields">
                                        <StyledInputLabel id="contractor-hour-rate-label" htmlFor="contractor-hour-rate">{configJSON.hourRate}</StyledInputLabel>
                                        <TextField style={{marginTop: 10}} id="contractor-hour-rate" data-testid="contractorPrice" name="contractor_price" className="contractor-hour-rate-modal" onChange={this.onContractorPriceChange} value={this.state.contractor_price} placeholder='Hour Rate' variant="outlined" />
                                    </Box>
                                    </FieldContainer>
                                </Fields>
                                <Fields>
                                    <FieldContainer>
                                        <Box className="countyFields">
                                        <StyledInputLabel id="demo-mutiple-chip-label">{configJSON.Country}</StyledInputLabel>
                                        <Autocomplete 
                                        id="combo-box-demo" 
                                        data-test-id="contryId"                                         
                                        style={{ height: 46 }} 
                                        options={this.state.countryList} 
                                        getOptionLabel={(option: any) => option.name} 
                                        value={this.state.selectedCountry} 
                                        onChange={(event, value) => this.handleCountryChange(value)} 
                                        renderInput={(params) => <TextField {...params} variant="outlined" />} 
                                        disableClearable={true} 
                                        />
                                        </Box>
                                    </FieldContainer>
                                    <FieldContainer>
                                        <Box className="countyFields">
                                        <StyledInputLabel id="demo-mutiple-chip-label">{configJSON.city}</StyledInputLabel>
                                        <Autocomplete
                                        className="autoClass"
                                        id="combo-box-demo"
                                        options={this.state.citiesList}
                                        data-test-id= "cityChecked"
                                        onChange={(event, value) => this.handleCityChange(value)}                                         
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        disableClearable={true}
                                        getOptionLabel={(option: any) => option.name}
                                        value={this.state.selectedcity}
                                        />
                                    </Box>
                                    </FieldContainer>
                                </Fields>
                                <ButtonContainer>
                                    <Button data-test-id="cancelButton"  onClick={this.cancelButtonCall} variant="outlined" color="primary" disableElevation>{configJSON.cancel}</Button>
                                    <Button data-test-id="nextButton" onClick={this.onPressSignInCallID} variant="contained" color="primary" >{configJSON.next}</Button>
                                </ButtonContainer>
                            </StyledForm>
                        </ModalBody>
                    </ModalContainer>
                </Modal>
            </>
        )
    }
}


export { DashboardBlock }

const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(14px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: "#069669",
                    borderColor: "#069669",
                },
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 26 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }),
)(Switch);

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#6eebff',
        },
    }),
)(LinearProgress);

const ProfessionalismText = styled(Box)({
    color: "#21272A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.14px",
    marginLeft:"10px"
})

const PercentText = styled(Box)({
    color: "#21272A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.14px",
    marginLeft: "7px"
})

const PercentTextTwo = styled(Box)({
    color: "#21272A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.14px",
    marginLeft: "40px",
})

const PointTag = styled(Box)({
    height: "12px",
    width: "12px",
    backgroundColor: "#2966FF",
    borderRadius: '10px'
})

const PointTagTwo = styled(Box)({
    height: "12px",
    width: "12px",
    backgroundColor: "#DB2777",
    borderRadius: '10px',
    marginLeft:"8px"

})

const PointTagThree = styled(Box)({
    height: "12px",
    width: "12px",
    backgroundColor: "#312E81",
    borderRadius: '10px'

});

const ModalBody = styled(Box)({
    backgroundColor: "#fff",
    width: "600px",
    padding: "26px",
    borderRadius: "10px",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%"
});

const ModalTitleContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
});

const ModalTitle = styled(Typography)({
    fontWeight: 700,
    fontSize: "24px",
    color: "#2966FF"
});

const ModalSubTitle = styled(Typography)({
    fontSize: "20px",
    fontWeight: 400,
    color: "#475569"
});


const ModalContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});
const StyledForm = styled("form")({
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "40px", 
    "& .countyFields" : {
        "& .MuiAutocomplete-inputRoot": {
            padding: 5,
            marginTop:'10px',
            borderRadius:"7px"
      },
    }
})
const Fields = styled(Box)({
    display: "flex",
    gap: "20px",
    width: "100%"
});
const FieldContainer = styled(Box)({
    flex: "0.5"
})
const StyledSelect = styled(Select)({
    marginTop: "10px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingLeft: "10px",
        borderRadius: "5px",
        borderColor: "#CBD5E1"
    },
    "& .MuiSelect-selectMenu": {
        minHeight: "56px",
        display: "flex",
        alignItems: "center"
    },
    "& .MuiOutlinedInput-input": {
        padding: "0 10px !important"
    },
});
const ButtonContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    marginTop: "10px",
    "& .MuiButton-root": {
        width: "155px",
        height: "50px",
        borderRadius:"8px",
        textTransform: "capitalize"
    }
});
const ChipContainer = styled(Box)({
    display: "flex",
    gap: "6px",
    flexWrap: "wrap",
    padding: "10px 0"
});
const MenuItemNone = styled(MenuItem)({
    display: "none",
    color: "gray"
});
const StyledPlaceholder = styled("span")({
    color: "#CBD5E1"
});
const StyledInputLabel = styled(InputLabel)({
    color: "#1E293B",
    fontSize: "16px",
    fontWeighg: 400
});
const ScrollableContainer = styled(Box)({
    overflowX: "auto",
    paddingBottom: "20px",
    marginTop: "10px"
  });
const StyledTable = styled(Table)({
    minWidth: "900px",
    "& .tag-colour-Box":{
        color:"#64748B",
        fontFamily: "Lato",
        cursor: "pointer"
      }
})


