import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBANQoxlGBYOm9L1DhoO5mw8DwmTYeJ6RA",
    authDomain: "admin-now-test.firebaseapp.com",
    projectId: "admin-now-test",
    storageBucket: "admin-now-test.appspot.com",
    messagingSenderId: "168250696234",
    appId: "1:168250696234:web:663b708f48c7784ce39953"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;