// Customizable Area Start
import React from "react";
import { useMeeting } from "@videosdk.live/react-sdk";

import ParticipantsView from "./ParticipantsView";

const Participants = ({screenisAlreadySharing, chatShow, participantsShow}) => {
  const { participants } = useMeeting();

  const viewProps = {
    participants,
    screenisAlreadySharing,
    chatShow,
    participantsShow
  }

  return (<ParticipantsView testID="ParticipantsView" {...viewProps} />);
};

export default Participants;
// Customizable Area End
