import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Divider, Button, ClickAwayListener, Snackbar, Badge } from "@material-ui/core"
import { Color, Alert as MuiAlert } from '@material-ui/lab';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Link, withRouter } from "react-router-dom";
import { Logo, greaterThan, profileLogo } from './assets';
import moment from 'moment';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
//@ts-ignore
import firebaseApp from '../../web/src/firebaseConfig';
import EngagementModal from '../../blocks/dashboard/src/EngagementModal.web';
import { cropImage } from '../../blocks/emailnotifications2/src/assets';

function Header(props: any) {
    const {list} = props
    const {history, onUserLogOut, redirectToLogin} = props;
    const [activeLink, setActiveLink] = useState<string>('home');
    const [userType, setUserType] = useState<string>();
    const [userDropDown, setUserDropdown] = useState<boolean>(false);
    const [isLogin, setisLogin] = useState<boolean>(false)  
    const [userphoto,setUserphoto] = useState("");
    const [engageMentRecords, setEngageMentRecords] = useState<any>([]);
    const [showEngagementModal, setShowEngagementModal] = useState<boolean>(false);
    const [engagementModalType, setEngagementModalType] = useState<string>("");
    const [notificationPopup, setNotificationPopUp] = useState(false); 
    const customerRoutes = ["/landing", "/profile", "/ProfileEdit", "/"];
    const contractorRoutes = ["/ContractorDashboard", "/UserProfile", "/EditProfile", "/"];
    const [alertMsg, setAlertMsg] = useState({
        show: false,
        msg:""
    })    
    const [alertType, setAlertType]= useState<Color>('error')
    const [notificationPermission, setNotificationPermission] = useState(false);     
    const requestNotificationPermission = () => {
      Notification.requestPermission().then(permission => {
        if(permission ==="granted"){
            setTimeout(() => window.location.href="/", 500)
        }
        setNotificationPermission(false);
      });
    };

    const genarateFcmToken = async () => {
        const messaging = getMessaging(firebaseApp);
        const currentToken = await getToken(messaging);
        console.log('Current FCM Token:', currentToken);
        localStorage.setItem('fcmToken', currentToken.toString())
        history.push("/")
    }

    const checkFcmToken = (token: any) => {
        const fcmToken = localStorage.getItem("fcmToken")
        if(fcmToken){             
            if(token){
                onUserLogOut();
            }else{
                localStorage.removeItem("signin-token")
                localStorage.removeItem("userData")
                localStorage.removeItem("signup-token")
                localStorage.removeItem("userId");
                localStorage.removeItem("fcmToken");
                localStorage.removeItem("available");
            }
        }
    }
    useEffect(() => {
        if(redirectToLogin){
            genarateFcmToken()
        }

    },[redirectToLogin, ""])     

    useEffect(() => {
        const token: any = localStorage.getItem('signin-token');
        const handleUnload  = async (event: any) => {
            if(token){
                event.preventDefault();
            }
            props.showLogoutModal("updateAvailability", false);
        }
          
        const handleBeforeUnload = (event: any) => { 
            if(token){
                event.preventDefault();
                event.returnValue = ""
            }            
            props.showLogoutModal("updateAvailability", false);
            return "";
        };

        const localStorageUserData: any = localStorage.getItem("userData"); 
               
        if(localStorageUserData){
            const userData = JSON.parse(localStorageUserData);
            if(userData.user_type === "contractor"){
                const isAvailable: any = localStorage.getItem("available");
                props.showLogoutModal("updateAvailability", isAvailable === "true" ? true : false);
                window.addEventListener("beforeunload",handleBeforeUnload, false);
                window.addEventListener("unload",handleUnload, false);
            }
        }
       
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload, false);   
            window.removeEventListener("unload", handleUnload, false);
        }
      }, []);
    
    useEffect(() => {
        const currentPath = window.location.pathname;
        const localStorageUserData: any = localStorage.getItem("userData");  
        if(localStorageUserData){
            const userData = JSON.parse(localStorageUserData);
            if (userData.photo) {                
                const imageUrl = userData.photo; // Replace with the URL of the uploaded image
    
                cropImage(imageUrl, false)
                .then((squareImageUrl: any) => {
                    setUserphoto(squareImageUrl)
                })
            }
            const storageUserType = userData?.user_type;
            setUserType(storageUserType)
        }
        if(currentPath === "/company") {
            setActiveLink('company');
        }
    });
    useEffect(() => {        
        const token = localStorage.getItem("signin-token");
        setisLogin(token ? true : false);      
        if(token){
            initializeFirebaseMessaging();
            getBroadcastMessage();
            getUserToken();
        } 
        
        if(Notification.permission === "default"){
            setNotificationPermission(true)
        } 
        if(Notification.permission !== "granted"){
            setNotificationPermission(true)
            checkFcmToken(token)
        } 
        
    },[])
    const getUserToken = () => {
        const token = localStorage.getItem('signin-token');
        if (!token) return null;      
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const expirationTime = tokenData.exp * 1000;  
        const currentDate = new Date();
        if (currentDate.getTime() < expirationTime) {
            console.log('Token is still valid.');
            checkUserRoutes()
        } else {
            props.showLogoutModal("logout");
        }
    }
    const checkUserRoutes = () => {
        const currentPath = window.location.pathname;
        let userData: any = localStorage.getItem('userData');
        if(userData && userData!==""){
            userData = JSON.parse(userData);
            if(userData.user_type === "customer"){
                const findCustomerPath = contractorRoutes.findIndex((val:string) => val === currentPath);
                console.log(findCustomerPath, "findCustomerPath");
                if(findCustomerPath !== -1){
                    pushTo("/landing")
                }
            }else{
                const findPath = customerRoutes.findIndex((val:string) => val === currentPath);
                console.log(findPath, "findPath")
                if(findPath !== -1){
                    pushTo("/ContractorDashboard")
                }
            }
            
        }
    }
    const pushTo = (url:string) => {
        history.push(url)
    }

    const handleLinkClick = (link: string) => {
        if(isLogin){
            setActiveLink(link);
        }
    };

    const initializeFirebaseMessaging = async () => {
        const messaging = getMessaging(firebaseApp);
        onMessage(messaging, (payload: any) => {
            if(payload.data.videosdk_token){
                onNotificationMessage(payload)
            }
            console.log(payload,"header-initializeFirebaseMessaging")
        });
    };
    const getBroadcastMessage = () => {
        const channel = new BroadcastChannel('background-msg-channel');    
        channel.addEventListener('message', (event) => {
          const payload = event.data;
          console.log(payload,"header-getBroadcastMessage")
          if(payload.data.videosdk_token){
            onNotificationMessage(payload)
          }
          // Handle the payload as needed
        });
    }
    const onNotificationMessage = (payload: any) => {
        const data = payload.data;
          let userEngData, photo ="";
          if(data["photo"]){
            photo = data["photo"]
          }
          if(data["customer"]) {
            userEngData = JSON.parse(data["customer"]);
          }
          const engagementData = {
            meeting_id: data["meeting_id"],
            platform_name: data["platform_name"],
            videosdk_token: data["videosdk_token"],
            body: data["message"],
            message: data['message'],
            customer: userEngData,
            photo: photo,
            platform_id: data["platform_id"] ? JSON.parse(data["platform_id"]) : [1]
          }
          const oldRecords = [...engageMentRecords];       
          if(engagementData?.meeting_id){             
            oldRecords.push(engagementData);
            setEngageMentRecords(oldRecords);
            setShowEngagementModal(true); 
            setEngagementModalType("engagmentRequest");          
          }    
      }

    const closeEngagementModal = (roomId?: any) =>{
        if(engageMentRecords.length > 1){
            const oldRecords = engageMentRecords.filter((rec:any) => rec.meeting_id !== roomId);  
            setEngageMentRecords(oldRecords)
        }else{
            setEngageMentRecords([]);
            setShowEngagementModal(false); 
            setEngagementModalType("");  
        }
         
    }

    const renderEngagementModal = () => {
        return <React.Fragment>
            {engageMentRecords.map((eachrecord:any) => (<EngagementModal
                key={eachrecord.meeting_id}
                showEngagementModal={showEngagementModal}
                engageMentRecords={eachrecord}
                navigation={undefined} id={""}
                engagementModalType={engagementModalType}
                closeModal={closeEngagementModal}
                onSubmitRating={() => {}}
                onAcceptEngagement={() => {}}
                history={history}
                showAlertMsg={showAlertMsg}
            />))}            
        </React.Fragment>
    }

    const homeLink = isLogin ? userType === "customer" ? "landing": "ContractorDashboard" : "/";
    const handleNotificationClick = () => {
        if(userType !== undefined) {
            setNotificationPopUp(!notificationPopup)
        } else {
            setNotificationPopUp(false)
        }
    }
    const onLogout = () => {
        setUserDropdown(!userDropDown);
        props.showLogoutModal("modal");
    }

    const showAlertMsg = (msg: string, type: Color) => {
        setAlertMsg({ show: true, msg: msg })
        setAlertType(type)
    }

    const closeAlertMsg = () => {
       setAlertMsg({
            show: false,
            msg:""
        })
    }
    const redirectToProfile = () => {
       const profileUrl = `/${userType === "customer" ? "profile": "UserProfile"}`;
       history.push(profileUrl);
    }

    const handleImage = (item: any) => {
        let image : string = profileLogo
        if(item.attributes.photo) {
           image = item.attributes.photo
        }
    
        return image
      }
     
    return (
        <Box style={{ borderBottom: '1px solid rgb(236, 236, 236)' }}>   
         {notificationPermission && (
            <div className="modal" 
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', zIndex: 10 }}>
                <div className="modal-content" style={{ gap:10,textAlign:'right',background: '#ffffff', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)' }}>
                <Typography variant="h5" className="modal-Engagement-header" component="div">This site requires notifications. Can you please allow notifications?</Typography>                    
                    <Button style={{marginTop: '10px'}} variant="contained" size="large" color="primary"className="custom-button-padding custom-button-contained" onClick={requestNotificationPermission}>{(Notification && Notification.permission === "denied") ? "Okay" : "Allow"}</Button>
                </div>
            </div>)}         
            {showEngagementModal && renderEngagementModal()}
            <Snackbar open={alertMsg.show} autoHideDuration={2000} style={{ top: "80px" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={closeAlertMsg} >
                <MuiAlert style={{ backgroundColor: "white", borderRadius: "8px",display: "flex", alignItems: "center"}} severity={alertType}
                    ><Typography>{alertMsg.msg}</Typography></MuiAlert>
            </Snackbar>
            <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 40px' }}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={Logo} style={{ marginRight: 30 }} />

                    <Typography style={{ marginRight: 20, fontFamily: 'Lato', fontWeight: 400, cursor: 'pointer' }} onClick={() => handleLinkClick('home')}>
                        <Link style={{ textDecoration: 'none', fontFamily: 'Lato', fontWeight: 400 }} className={`headerLink ${activeLink === 'home' ? 'active' : 'inactive'}`} to={homeLink}>Home</Link>
                    </Typography>
                    <Typography style={{ marginRight: 20, cursor: 'pointer' }}>
                        <Link style={{ textDecoration: 'none', fontFamily: 'Lato', fontWeight: 400 }} className={`headerLink ${activeLink === 'company' ? 'active' : 'inactive'}`} to={"/company"} onClick={() => handleLinkClick('company')}>Company</Link>
                    </Typography>
                </Box>
                <Box className='disabledHoveronIconBtn' style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton style={{ marginRight: 20 }} disableTouchRipple onClick={handleNotificationClick}>
                        <Badge
                            badgeContent={list.filter((item: any) => !item.attributes.is_read).length}
                            color='error'
                        >
                            <NotificationsNoneIcon />
                        </Badge>
                    </IconButton> 
                    <ClickAwayListener onClickAway={() => setUserDropdown(false)}>
                    <IconButton disableTouchRipple className='nav-user-icon-container' onClick={() => setUserDropdown(!userDropDown)} size='small' style={{ width: "100%", height: "100%",maxHeight: "55px", maxWidth: "55px", borderRadius: '50%'}}>
                        {userphoto ? 
                         <img src={userphoto} style={{ width: 49, height: 49, borderRadius: '50%', border:"1px solid rgb(41, 102, 255)"}} alt='user' /> 
                         : 
                         <IconButton disableTouchRipple style={{ width: 49, height: 49, borderRadius: '50%', backgroundColor: '#cbd5e1' }}> 
                             <PersonOutlineOutlinedIcon />                        
                         </IconButton>}
                    </IconButton>
                    </ClickAwayListener>                   

                    {(userDropDown && isLogin) && <div className="navbar-collapse">
                    <Typography className='nav-item'>
                        <div className='c-pointer clr-black text-decoration-none' onClick={redirectToProfile}>Profile <span className='icon-right'><img src={greaterThan} style={{height:24, width: 24}} /></span></div>
                    </Typography>
                    <Typography onClick={onLogout} className='c-pointer clr-red nav-item'>Log out</Typography>
                    </div>}

                    {notificationPopup && <ClickAwayListener onClickAway={() => setNotificationPopUp(false)}>
                        <Box className='notificationMainDiv'>
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "15px 5px 40px 24px" }}>
                                <Typography className='notificatText'>Notifications</Typography>
                                <Typography className='readAllText' onClick={() => props.markAllRead()}>Mark all as read</Typography>
                            </div>
                            {list && list.length > 0 ? list.slice(0,5).map((item: any) => 
                                <>
                                <div key={item} className='subHeaderDiv'>
                                    <img className='userNotificationPhoto' src={handleImage(item)} />
                                    <Typography className='userNotificationTitle'> <b style={{ fontSize: "16px" , paddingRight: "5px"}}>{item.attributes.headings}</b>{item.attributes.contents} </Typography>
                                    <Box style={{ display: "flex", gap: "5px"}}>                                    
                                    <Typography className='userNotificationTime'>{moment(item.attributes.created_at).format("hh:mm")}</Typography>
                                    </Box>
                                </div>
                                    <Divider style={{ marginBottom: "15px"}} />
                                </>
                            ) : <div style={{ textAlign: 'center'}}>
                                   <Typography style={{ fontWeight: "bold"}}> No Notification </Typography>
                                </div>}

                            {list && list.length > 0 && <div className='viewAllBtn'>
                                <Button className='BtnElement' variant='outlined'>
                                    <Link style={{ textDecoration: 'none'}} to={"notification"} >View All</Link> 
                                </Button>
                            </div>}
                            
                        </Box></ClickAwayListener>}
                </Box>
            </Box>
        </Box>
    )
}

export default withRouter(Header);