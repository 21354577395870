import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { cropImage } from "../../emailnotifications2/src/assets";
export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  location?: any
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  showListData:any,
  roomId : string,
  filePath: string,
  contractorId: any,
  getTotalCostData : any,
  payStatus:string,
  loader:boolean,
  engagementFullData: any,
  currentUserType: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EngagemenRequestControlle extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
   statusUpdate: any;
   getVideoRecordingApiCallId : string = "";
   showTotalCostApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessageScanData)
    ];

    this.state = {
      showListData:"",
      roomId: "",
      filePath: "",
      contractorId: "",
      getTotalCostData: {
        Description: "Connect Fee: ",
        ContractorFee: "",
        ServiceFee: "",
        TotalCost: ""
      },
      payStatus:"",
      loader:true,
      engagementFullData: {},
      currentUserType: ""
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    if(this.props.location !== undefined) {
      this.getUserData();
      const listData = this.props.location.state.requestData.dataList[0];
      if(listData.img){
        listData.img = await cropImage(listData.img, false);
      }
      this.setState({ 
        showListData : listData,
        roomId: this.props.location.state.requestData.dataList[0].roomId,
        contractorId: this.props.location.state.requestData.dataList[0].contractorId,
        payStatus: this.props.location.state.engageMentRecords.attributes.payment_status,
        engagementFullData: this.props.location.state.engageMentRecords.attributes
      })
    }
    // Customizable Area End
  }  
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.roomId !== this.state.roomId) {
         this.getVideoRecording()
    }

    if (prevState.contractorId !== this.state.contractorId) {
      this.getTotalCostData()
    }
  }

  getUserData = () => {
    const localStorageUserData:any = localStorage.getItem("userData");  
    if(localStorageUserData){
      const userData = JSON.parse(localStorageUserData);
      this.setState({ currentUserType: userData.user_type })
    } 
  }
  BackButtonCall =() =>{
    this.props.navigation.goBack()
  }


  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const myOrder = await message.getData(getName(MessageEnum.NavigationPropsMessageScanData));
      this.setState({showListData: myOrder.dataList[0], roomId: myOrder.dataList[0].roomId})
  
  } 

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      this.handleAPICalls(apiRequestCallId, responseJson)
    }
  }

  handleAPICalls = (apiRequestCallId: string, responseJson : any) => {
    if(apiRequestCallId === this.getVideoRecordingApiCallId ) {
       this.setFileURLResponse(responseJson)
    } else if( apiRequestCallId === this.showTotalCostApiCallId) {
       this.setTotalCostApiResponse(responseJson)
    }
  }

  setTotalCostApiResponse = (responseJson: any) => {
    if(responseJson) {
      const data: any = responseJson;
      const updatedData: any = {};
      
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const newKey = key.replace(/\s+/g, ''); // Remove spaces from the key
          updatedData[newKey] = data[key];
        }
      }
      this.setState({ getTotalCostData: updatedData })
    } else {
     this.setState({ getTotalCostData: {}})
    }
  }

  setFileURLResponse = (responseJson : any) => {
    this.setState({loader:false})
       if(responseJson && responseJson.data) {
        const fileUrl = responseJson.data.data[0].file.fileUrl
        this.setState({ filePath : fileUrl})
       } else {
          this.setState({ filePath : ""})
       }
  }

  getVideoRecording = () => {
    this.setState({loader:true})
    const header = {
      token: localStorage.getItem('signin-token')
    };

    let formData = new FormData();

    formData.append("roomId" , this.state.roomId)

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getVideoRecordingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.videoRecordingApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  truthyValue = (key: any) => {
    if (key !== "" || key !== null) {
        return key
    } else {
        return ""
    }     
  }

  getTotalCostData = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.showTotalCostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTotalCostApiEndpoint}?contractor_id=${this.state.contractorId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPaymentChipTwo = (chipTitle: string) => {
    let chipDataTwo = {
      PENDING: {
        backgroundColor: "rgba(255, 41, 41, 0.20)",
        title: "Pending",
        fontColor: "#DC2626",
      },
      CONTESTED: {
        backgroundColor: "rgba(203, 213, 225, 0.60)",
        title: "Contested",
        fontColor: "#1E293B",
      },
      PAID: {
        backgroundColor: "rgba(41, 102, 255, 0.30)",
        title: "Paid",
        fontColor: "#2966FF",
      },
      REFUNDED: {
        backgroundColor: "rgba(110, 235, 255, 0.30)",
        title: "Refunded",
        fontColor: "#199FB4",
      },
      DEFAULT_VALUE: {
        backgroundColor: "",
        title: "",
        fontColor: "",
      }
    }
    switch (chipTitle) {
      case "contested":
        return chipDataTwo.CONTESTED
      case "pending":
        return chipDataTwo.PENDING
      case "refunded":
        return chipDataTwo.REFUNDED
      case "paid":
        return chipDataTwo.PAID
      default:
        return chipDataTwo.DEFAULT_VALUE;
    }

  }
  }



// Customizable Area End
