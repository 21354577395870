// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
export const configJSON = require("./config");
import { toast } from "react-toastify";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notificationListData:any[];
  token: string;
  userData: any;
  pageLoader: boolean;
  logoutModal: boolean;
  onlyUpdateAvailability: boolean;
  availability: boolean;
  redirectToLogin: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class TopHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationApiCallId: string = "";
  markAllReadNotificationApiCallId: string  = "";
  viewNotificationApiCallId: string = "";
  changeAvailabilityAPiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      notificationListData:[],
      token: "",
      userData: {},
      pageLoader: false,
      logoutModal: false,
      onlyUpdateAvailability: false,
      availability: false,
      redirectToLogin: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        this.handleAPICallsOfHeader(apiRequestCallId, responseJson)

    }

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    this.isAuthenticated();
       this.getNotificationDataList()
  }


  // Customizable Area Start
  isAuthenticated = async () => {
    const token = localStorage.getItem('signin-token');
    const localStorageUserData: any = localStorage.getItem("userData");     
    if (token) {
      const userData = JSON.parse(localStorageUserData);
      this.setState({ token: token, userData: userData })
    }
  }
  closeLogoutModal = () => {
    this.setState({
      logoutModal: false
    })
  }
  callHeaderApi(props: any) {
    const getHeaderApi = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getHeaderApi.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    getHeaderApi.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(props.headers));
    if (props.body) {
      getHeaderApi.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(props.body));
    }
    getHeaderApi.addData(getName(MessageEnum.RestAPIRequestMethodMessage), props.requestType);
    runEngine.sendMessage(getHeaderApi.id, getHeaderApi);
    return getHeaderApi.messageId;
  }
  showLogoutModal = (type: string, availability?: boolean) => {
    if(type === "logout"){
      this.onUserLogOut();
    }else if(type === "updateAvailability"){
      const isAvailabilityAvailable: boolean = availability|| false
      this.setState({
        onlyUpdateAvailability: true,
        availability: isAvailabilityAvailable
      }, () => {
        this.updateUserAvalailabilityStatus()
      })   
    }else{
      this.setState({
        logoutModal: true,     
      })
    }    
  }
  clearLocalstorage = () => {
    if(!this.state.onlyUpdateAvailability){
      localStorage.removeItem("signin-token")
      localStorage.removeItem("userData")
      localStorage.removeItem("signup-token")
      localStorage.removeItem("userId");
      localStorage.removeItem("fcmToken");
      localStorage.removeItem("available");
      this.setState({ pageLoader: false });
      this.setState({
        redirectToLogin: true
      })
    }else{
      this.setState({
        onlyUpdateAvailability: false,
        availability: false
      })
    }
   
  }
  onUserLogOut = () => {
    const userType = this.state.userData?.user_type;
   
    if(userType !== "contractor"){
      this.clearLocalstorage();
    }else{
      this.setState({ pageLoader: true, logoutModal: false });
      this.updateUserAvalailabilityStatus(true)
    }    
  }
  updateUserAvalailabilityStatus = (isLogout?: boolean) => {
    const headers = {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    const body = {
      data:{
        attributes:{
          is_available: isLogout ? false : this.state.availability
        }
      }
    }
    this.changeAvailabilityAPiID = this.callHeaderApi({
      headers: headers,
      body: body,
      endPoint: "account/change_availability_status",
      requestType: "PATCH"
    })
  }
  setNotificationDataResponse = (responseJson: any) => {
    if(responseJson && responseJson.data) {
       this.setState({ notificationListData: responseJson.data})
    } else {
      if(responseJson.errors && responseJson.errors[0]?.account && responseJson.errors[0]?.account ==="Account Not Found"){
        this.clearLocalstorage();
      }
       this.setState({ notificationListData: []})
    }
 }

 markAllReadNotificationDataResponse = (responseJson: any) => {
  if(responseJson && responseJson.data) {
      toast.success("Notification Marked as read")
      this.getNotificationDataList()
  } else {
     toast.error("Something went wrong"); 
  }
}

  handleAPICallsOfHeader = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getNotificationApiCallId) {
        this.setNotificationDataResponse(responseJson)
    } else if (apiRequestCallId === this.markAllReadNotificationApiCallId) {
       this.markAllReadNotificationDataResponse(responseJson)
    } else if (apiRequestCallId === this.viewNotificationApiCallId) {
        this.readNotificationdataResponse(responseJson)
    } else if(apiRequestCallId === this.changeAvailabilityAPiID){       
      this.clearLocalstorage();   
    }
  }

  readNotificationdataResponse = (responseJson: any) => {
    if(responseJson && responseJson.data) {
        this.getNotificationDataList()
        toast.success("Notification Marked as read")
    } else {
       toast.error("Something went wrong"); 
    }
  }

  getNotificationDataList = () => {
    const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem('signin-token')
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
      this.getNotificationApiCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getNotificationListApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
  
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  markAllAsReadNotificationData = () => {
    const header = {
      "Content-Type": "application/json",
        token: localStorage.getItem('signin-token')
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      
      this.markAllReadNotificationApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.markAllReadNotificationApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPatchMethod
      );
  
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  readNotificationDataHeader = (id: any) => {
    const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem('signin-token')
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      this.viewNotificationApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.readNotificationApiEndPoint}?notification_id=${id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage); 
  }
  // Customizable Area End
}
// Customizable Area End