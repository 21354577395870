import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { profileLogo } from './assets';
import moment from "moment";
// Customizable Area Star

type MonthlyData = (string | number)[];
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string
}

interface EngagementRecord {
  id: string;
  type: string;
  attributes: {
    id: number;
    engagement_request: string;
    platform_id: number;
    payment_status: string;
    service_start_time: string;
    service_end_time: string | null;
    total_cost: number;
    room_id: string | null;
    platform: string;
    duration: string | null;
    customer: {
      data: {
        id: string;
        type: string;
        attributes: {
          [key: string]: boolean | number | string | null; // Updated index signature
          activated: boolean;
          email: string;
          first_name: string;
          last_name: string;
          phone_number: number;
          type: string;
          city: string | null;
          country: string | null;
          verified: boolean;
          rating: number;
          photo: string | null;
        };
      };
    };
    contractor: {
      data: {
        id: string;
        type: string;
        attributes: {
          [key: string]: boolean | number | string | null | {}[]; // Updated index signature
          activated: boolean;
          email: string;
          first_name: string;
          last_name: string;
          phone_number: number;
          type: string;
          city: string | null;
          country: string | null;
          verified: boolean;
          rating: number;
          photo: string | null;
          year_of_experience: number;
          is_available: boolean;
          contractor_price: number;
          skills: {
            name: string;
            subskills: string[];
          }[];
        };
      };
    };
  };
}

export interface SortingHeaders {
  customer : boolean;
  contractor : boolean;
  "engagement_request" : boolean;
  "payment_status": boolean;
  "service_start_time" : boolean;
  "duration" : boolean;
  "platform" : boolean;
  "total_cost" : boolean;
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history?: any;
  location?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  errorMsg: string;
  loading: boolean;
  engagementDataList: any;
  loader: boolean;
  page:number;
  perPage:number;
  totalPage : number;
  userData: any;
  pageLoading: boolean;
  sortOrder: string;
  sortableHeaders: SortingHeaders
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class EngagemenRequestListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getEngageMentDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigateEngagementRequestMessage),
      getName(MessageEnum.NavigationPropsMessageScanData)
    ];

    this.state = {
      errorMsg: "",
      token: "",
      loading: false,
      engagementDataList: [],
      loader: true,
      page:1,
      perPage:6,
      totalPage : 1,
      userData:{},
      pageLoading: true,
      sortOrder: "ascending",
      sortableHeaders: {
        contractor: false,
        customer: false,
        "engagement_request": false,
        "payment_status": false,
        "service_start_time": false,
        "duration": false,
        "platform": false,
        "total_cost": false,
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getEngagementData(this.state.page, this.state.perPage);
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    const restApiResponseErrorMessageName = getName(MessageEnum.RestAPIResponceErrorMessage);
    const restApiResponseSuccessMessageName = getName(MessageEnum.RestAPIResponceSuccessMessage);
    const restApiResponseDataMessageName = getName(MessageEnum.RestAPIResponceDataMessage);
    const restApiResponseMessageName = getName(MessageEnum.RestAPIResponceMessage);
    if (restApiResponseMessageName === message.id) {
      const errorResponse = message.getData(restApiResponseErrorMessageName);
      const responseJson = message.getData(restApiResponseSuccessMessageName);
      const apiRequestCallId = message.getData(restApiResponseDataMessageName);
      if (responseJson && !responseJson.errors) {
        this.handleEngageMentApiCall(apiRequestCallId, responseJson)
      } else {
        this.setState({
          errorMsg: errorResponse || "Something went wrong", loading: false,

        }, () => {
          this.props.history.replace("contractorDashboard")
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getUserData = () => {
    const localStorageUserData:any = localStorage.getItem("userData");  
    if(localStorageUserData){
      const userData = JSON.parse(localStorageUserData);
      this.setState({ userData: userData })
    } 
  }
  getTokenValue = () => {
    const token = localStorage.getItem('signin-token');
    return token;
  }

  callDashBoardApi(props: any) {
    const getdashBoardApi = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getdashBoardApi.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    getdashBoardApi.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(props.headers));
    if (props.body) {
      getdashBoardApi.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(props.body));
    }
    getdashBoardApi.addData(getName(MessageEnum.RestAPIRequestMethodMessage), props.requestType);
    runEngine.sendMessage(getdashBoardApi.id, getdashBoardApi);
    return getdashBoardApi.messageId;
  }

  handleEngageMentApiCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getEngageMentDataApiCallId) {
      this.setEngageMentDataResponse(responseJson)
    }
  }

  getEngagementData = ( page :number , perPage : number) => {
    if(page === 1){
      this.getUserData();
    }
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEngageMentDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getEngagementDataApiEndpointIndex}?page=${page}&per_page=${perPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setEngageMentDataResponse = (responseJson: any) => {
    if (responseJson?.engagement_records.data) {
      this.setState({
        loader: false, engagementDataList: responseJson?.engagement_records.data,
        page: responseJson.current_page,
        perPage: responseJson.per_page,
        totalPage: parseInt(responseJson.total_records) >= 6 ? parseInt(responseJson.total_records) / 6 : 1
      }, () => {
        if (responseJson.total_records >= 6) {
          if (responseJson.total_records % 6 == 0) {
            this.setState({ totalPage: this.state.totalPage })
          } else {
            this.setState({ totalPage: parseInt(String(this.state.totalPage)) + 1 })
          }
        }
      })
    } else {
      this.setState({ loader: false, engagementDataList: [], })
    }
  }

  engagemenNavigate = ({profileImg,FullNameList, durationTimeList, engagementRequestMsg, itemStartDateList, paymentStatusList, platformNameList, totalCostList, roomId, contractorId, rating, row} : any) => {
    let arr = []
  
    arr.push({img : profileImg,name : FullNameList,durationTime :durationTimeList,engagementRequestMsg : engagementRequestMsg, paymentStatusList:paymentStatusList,platformNameList :platformNameList,totalCostList:totalCostList, roomId, contractorId, rating})
    
    this.props.history.push({
      pathname: "/EngagemenRequest",
      state: {engageMentRecords : row,requestData : { dataList : arr}},
    });
  }

  getPaymentChipTwo = (chipTitle: string) => {
    let chipDataTwo = {
      PENDING: {
        title: "Pending",
        backgroundColor: "rgba(255, 41, 41, 0.20)",
        fontColor: "#DC2626"
      },
      CONTESTED: {
        backgroundColor: "rgba(203, 213, 225, 0.60)",
        title: "Contested",
        fontColor: "#1E293B",
      },
      PAID: {
        backgroundColor: "rgba(41, 102, 255, 0.30)",
        title: "Paid",
        fontColor: "#2966FF",
      },
      REFUNDED: {
        backgroundColor: "rgba(110, 235, 255, 0.30)",
        fontColor: "#199FB4",
        title: "Refunded",
      },
    }
    switch (chipTitle) {
      case "contested":
        return chipDataTwo.CONTESTED
      case "pending":
        return chipDataTwo.PENDING
      case "refunded":
        return chipDataTwo.REFUNDED
      case "paid":
        return chipDataTwo.PAID
    }
  }


 customerData = (item: any) => {
   
  let userToDisplay: any={};
  const customerData = item.attributes.customer.data;
  const contractorData = item.attributes.contractor.data;
  const userData = this.state.userData; 
  if(userData){    
    const userType = userData.user_type;
    userToDisplay = userType === "customer" ? contractorData : customerData;  
  }

  let firstName: string = ""
  let lastName: string = ""
  if (userToDisplay?.attributes.first_name) {
      firstName = userToDisplay.attributes.first_name
  }
  if (userToDisplay?.attributes.last_name) {
      lastName = userToDisplay.attributes.last_name
  }
  const FullNameList = firstName + " " + lastName;
  let paymentStatusList: any = "-"
  if (item.attributes.payment_status) {
      paymentStatusList = this.getPaymentChipTwo(item.attributes.payment_status)
    }

    let engagementRequestMsg: string = "-"
    if (item.attributes.engagement_request) {
      engagementRequestMsg = item.attributes.engagement_request
    }

    let itemStartDateList: string = "-"
    if (item.attributes.service_start_time) {
      itemStartDateList = moment(item.attributes.service_start_time).format('DD MMM YYYY, hh:mm A')
    }

    let durationTimeList: string = "-"
    if (item.attributes.duration) {
      durationTimeList = item.attributes.duration + " Hrs"
    }

    let platformNameList: string = "-"
    if (item.attributes.platform) {
      platformNameList = item.attributes.platform
    }

    let totalCostList: string = "$0"
    if (item.attributes.total_cost) {
      totalCostList = "$" +item.attributes.total_cost
    }

  let roomId: string = ""
  if (item.attributes.room_id) {
      roomId = item.attributes.room_id
  }

  let contractorId = ""
  if(contractorData.id) {
    contractorId = contractorData.id
  }
  let rating: number = 0
  rating = this.ratingData(userToDisplay?.attributes.rating)

  let profileImg: string = profileLogo
  if (userToDisplay && userToDisplay.attributes.photo) {
    profileImg = userToDisplay.attributes.photo
}

    return {
      FullNameList,
      paymentStatusList,
      engagementRequestMsg,
      itemStartDateList,
      durationTimeList,
      platformNameList,
      totalCostList,
      profileImg,
      roomId,
      contractorId,
      rating
  }
}
 
  ratingData = (rating: any) => {
    let ratingData = 0
    if (rating) {
      ratingData = rating
    }
    return ratingData
  }

  onPageChangeChildCategory = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({
      page: Number(value)
    }, () => {
      this.getEngagementData(this.state.page, this.state.perPage)
    });
  };

  BackButton = () => {
    this.props.navigation.goBack()
  };

  sortTableHandler = (sortBy: keyof SortingHeaders, sortName: string, sortOrder: string) => {
    const sortedData = this.state.engagementDataList.sort((a: any, b: any) => {
      
      let firstVal: string = '';
      let secondVal: string = ''; 
      switch (sortBy) {
        case "payment_status":
        case "engagement_request":
        case "service_start_time":
        case "platform":
          firstVal = a.attributes[sortBy].toLowerCase();
          secondVal = b.attributes[sortBy].toLowerCase();
          break;
        case "total_cost":
          firstVal = a.attributes[sortBy].toString();
          secondVal = b.attributes[sortBy].toString();
          break;
        case "duration":
          firstVal = a.attributes[sortBy].toString().toLowerCase();
          secondVal = b.attributes[sortBy].toString().toLowerCase();
          break;
        case "customer":
        case "contractor":
          firstVal = (a.attributes[sortBy].data.attributes[sortName])?.toLowerCase();
          secondVal = (b.attributes[sortBy].data.attributes[sortName])?.toLowerCase();
          break;
      }

      
      switch (sortOrder) {
        case "ascending":
          if (firstVal < secondVal) {
            return -1;
          }
          if (firstVal > secondVal) {
            return 1;
          }
          break;
        case "descending":
          if (firstVal > secondVal) {
            return -1;
          }
          if (firstVal < secondVal) {
            return 1;
          }
          break;
        default:
          return 0;
      }
    });

    const sortOrderHandle = this.handlingSortOrder()

    const initialValue : SortingHeaders = {
        contractor: false,
        customer: false,
        "engagement_request": false,
        "payment_status": false,
        "service_start_time": false,
        "duration": false,
        "platform": false,
        "total_cost": false,
    }

    initialValue[sortBy] = this.handlingSortIcon()
    
    this.setState({
      engagementDataList: sortedData,
      sortOrder: sortOrderHandle,
      sortableHeaders: initialValue
    });
}

    handlingSortIcon = () => {
      return this.state.sortOrder === "ascending" ? false : true;
    }

    handlingSortOrder = () => {
      return this.state.sortOrder === "ascending" ? "descending" : "ascending"
    }


  // Customizable Area End
}
