import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { striprKey, profileLogo } from "../../../components/src/assets"
import LandingPageController, {
    Props,
} from "./LandingPageController";
import {
    Grid,
    Box,
    Typography,
    TextField,
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Select, Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Link,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert as MuiAlert } from '@material-ui/lab';
import { image, landing, contractor, star, callingImg } from './assets'
import Header from "../../emailnotifications2/src/TopHeader.web";
import SearchContractor from "./searchContractor.web";
import {greenTick} from "../../forgot-password/src/assets"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "../../../components/src/CheckoutForm";
const stripePromise = loadStripe(striprKey);
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const EngageMentData = (item: any) => {

    // Fullname
    let firstName: string = ""
    let lastName: string = ""
    if (item.attributes.contractor.data && item.attributes.contractor.data.attributes.first_name) {
        firstName = item.attributes.contractor.data.attributes.first_name
    }
    if (item.attributes.contractor.data && item.attributes.contractor.data.attributes.last_name) {
        lastName = item.attributes.contractor.data.attributes.last_name
    }
    const FullName = firstName + " " + lastName;

    // City Country
    let City: string = ""
    let Country: string = ""
    if (item.attributes.contractor.data && item.attributes.contractor.data.attributes.city) {
        City = item.attributes.contractor.data.attributes.city
    }
    if (item.attributes.contractor.data && item.attributes.contractor.data.attributes.country) {
        Country = item.attributes.contractor.data.attributes.country
    }
    const Location = City + "," + Country;


    // Rating
    let rating: number = 0
    if (item.attributes.contractor.data && item.attributes.contractor.data.attributes.rating) {
        rating = item.attributes.contractor.data.attributes.rating
    }
    // TotalCost
    let totalCost: number = 0
    if (item.attributes.contractor.data && item.attributes.contractor.data.attributes.contractor_price) {
        totalCost = item.attributes.contractor.data.attributes.contractor_price
    }

    // ProfileImg
    let profileImg: string = profileLogo
    if (item.attributes.contractor.data && item.attributes.contractor.data.attributes.photo) {
        profileImg = item.attributes.contractor.data.attributes.photo
    }

    return { FullName, rating, totalCost, Location, profileImg }

}
// Customizable Area End

export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start 
    showEngagementRecords = () => {
        return (
            this.state.recentEngagementRecords.length > 0 ? this.state.recentEngagementRecords.map((row: any, index: number) => {
                const { FullName, rating, totalCost, Location, profileImg } = EngageMentData(row)
                return (
                    <Grid key={`engagment-${index}`} item lg={2} md={3} sm={6} xs={12}>
                        <Card className="card engagement-user-card" data-test-id={`engagment-${index}`} onClick={() => this.redirectToEngagementDetailPage(row)}>
                            <CardActionArea>
                                <CardMedia
                                    style={{ height: 109 }}
                                    image={profileImg}
                                    title={FullName}
                                />
                                {row.attributes.customer_rating && <Box className="rateus">
                                    <img src={star} />
                                    <Typography>{row.attributes.customer_rating}</Typography>
                                </Box>}
                                <CardContent>
                                    <Typography gutterBottom className="card-title">
                                        {FullName}
                                    </Typography>
                                    <Typography className="card-detail">{Location}</Typography>
                                    <Typography className="card-detail">$ {totalCost}/ hr</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            }) :
                <div>
                    <Typography style={{ fontWeight: 'bold', padding: '20px' }}>
                        No Data Found
                    </Typography>
                </div>
        )
    }
    // Customizable Area End

    render() {
        const arr = ["", "", "", "", '', ""];
        return (
            // Customizable Area Start
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <Header />
                    <Snackbar open={this.state.snackbarOpen} autoHideDuration={2000} style={{ top: "80px" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={this.closeAlertMsg} >
                    <MuiAlert 
                        style={{ backgroundColor: "white", borderRadius: "8px",display: "flex", alignItems: "center"}}
                        iconMapping={{ success: <img src={greenTick} width={20} height={20} />}}
                        severity={this.state.alertMsgType}
                    ><Typography>{this.state.alertMsg}</Typography></MuiAlert>
                    </Snackbar>

                    <Dialog
                        open={this.state.freeTrialModel}
                        keepMounted
                        data-test-id='closeCardModel'
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div style={{ padding: 25 }}>
                            <DialogTitle id="alert-dialog-slide-title">
                                <div style={{
                                    textAlign: 'center', 
                                    fontSize: "24px", 
                                    color: "#2966FF",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "32px",
                                    letterSpacing: "-0.12px", 
                                    padding: "4px 16px", 
                                    margin: 0
                                }}>
                                    Please Add Credit Card Details
                                </div>
                                <div style={{
                                    textAlign: 'center', 
                                    fontSize: "18px", 
                                    color: "#475569",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "28px",
                                    letterSpacing: "-0.12px", 
                                    padding: "4px 16px", 
                                    margin: 0
                                }}>
                                    You will not be charged for your first engagement
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm data-test-id='checkoutForm' updateCardData={this.updateCardData} addCardDetail={(token: string) => this.addCardsRecords(token)} handleClose={() => this.handleFreeTrialModel()} />
                                </Elements>
                            </DialogContent>
                        </div>
                    </Dialog>

                    {this.state.showContractorModal && <SearchContractor
                        navigation={this.props.navigation}
                        history={this.props.history}
                        id={""}
                        showContractorModal={this.state.showContractorModal}
                        modalContractorType={this.state.modalContractorType}
                        closeModal={this.closeContarctorModal}
                        onPregressDone={this.onPregressDone}
                        contractorData={this.state.contractorData}
                        onSubmitRating={this.onSubmitRating}
                        onSubmitSelectedContractor={this.onSubmitSelectedContractor}
                        location={this.props.location}
                    />}

                    <Box className="landing-wrapper">
                        <Box>
                            <img src={landing} className="landing-wrapper-bg" />
                        </Box>
                        <Grid className="container" justifyContent="space-between" container spacing={2}>
                            <Grid item  lg={6} md={6} sm={12} xs={12}>
                                <Box className="landing-title-wrap">
                                    <Typography className="landing-title">Welcome! <span>{this.state.userName}</span></Typography>
                                    <Typography className="landing-detail"> Enter your question here. Contractors are ready to assist you.</Typography>
                                </Box>
                                <Box style={{ marginTop: '30px' }}>
                                    <Box className="welcome-form-field-box">
                                        <Box className="welcome-form-field-inner">
                                            <TextField className="welcome-form-field"
                                                placeholder="Add your question here"
                                                variant="outlined"
                                                name="question"
                                                value={this.state.question}
                                                data-test-id="question-field"
                                                onChange={this.handlePostQuestionObj}
                                            />
                                        </Box>
                                        <Box className="inputplatform">
                                            <FormControl fullWidth variant="outlined">
                                                <Autocomplete
                                                    id="checkboxes-tags-demo"
                                                    options={this.state.platFormArr}
                                                    value={this.state.defaultPlatform}
                                                    getOptionLabel={(option: any) => option?.attributes?.name}
                                                    onChange={(e: any, newVal: any) => this.onSelectPlatform(newVal)}
                                                    data-test-id="platform-field"
                                                    disableClearable={true}
                                                    className="autoCompletePaddingDown"
                                                    renderInput={(params) => (
                                                        <TextField {...params} style={{ padding :0}} variant="outlined" placeholder="Select platform" />
                                                    )}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box className="category-box">
                                        <Box className="inputcategory">
                                            <FormControl fullWidth variant="outlined">
                                                <Select
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={this.state.subCategory}
                                                    onChange={this.handlePostQuestionObj}
                                                    name="subCategory"
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    disabled={this.state.platform ? false : true}
                                                    className="categorySelect"
                                                >
                                                    <MenuItem style={{ color: 'gray' }} disabled value="">Select subcategory</MenuItem>
                                                    {this.state.subCategoryArr.map((item: any) => <MenuItem key={`subCat-${item.id}`} value={item.id}>
                                                        <span style={{ textTransform: 'capitalize' }}>{item.attributes.name}</span>
                                                    </MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                            <Button data-test-id="btnSearchContractor" onClick={this.submitQuestion} className={"submit-btn"} variant="contained" color="primary" disabled={(this.state.platform && this.state.subCategory && this.state.question) ? false: true}>Submit</Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <img style={{ width: 400, height: 280 }} className="landing-img" src={callingImg} />
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                    <Box className="Recent-Engagements">
                        <Box className="container">
                            <Box className="recent-title-wrapper">
                                <Typography className="recent-title">Recent Engagements</Typography>
                                <Link style={{cursor: "pointer"}} onClick={()=> this.state.recentEngagementRecords.length > 0 ? this.props.navigation.navigate("EngagemenRequestList"): ""} className="viewall">View All</Link>
                            </Box>
                            <Box>
                                <Grid container spacing={2} className="reacent-engagement-list">
                                    {this.showEngagementRecords()}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>


                </ThemeProvider>
            </React.Fragment>
            // Customizable Area End     
        );
    }
}

// Customizable Area Start
// Customizable Area End
