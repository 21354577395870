// Customizable Area Start
import React from "react";
import Loader from "../../../components/src/Loader.web";
import { Box, Button, Modal } from "@material-ui/core"
// Customizable Area End

import TopHeaderController, {
  Props,
} from "./TopHeaderController";
import Header from "../../../components/src/Header";

export default class TopHeader extends TopHeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLogoutContent = () => (
    <Box 
      className="logout-modal-button-container"
    >
      <Box>
        <Button 
          variant="outlined" 
          color="primary" 
          onClick={this.closeLogoutModal} 
          size="large" 
          className="custom-button-padding custom-button-outlined"
        >
          No
        </Button>
      </Box>
      <Box>
        <Button 
          variant="contained" 
          size="large" 
          color="primary" 
          className="custom-button-padding custom-button-contained" 
          onClick={this.onUserLogOut}
        >
          Yes
        </Button>
      </Box>
    </Box>
  )

  renderLogoutModal = () => (
    <Modal onClose={this.closeLogoutModal} open={this.state.logoutModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="logout-modal-container">
        <h5 className="heading">
          Are you sure you want to logout?
        </h5>
        {this.renderLogoutContent()}
      </Box>
    </Modal>)
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>      
      {this.state.pageLoader && <div style={{zIndex:100}}><Loader loading={true} /></div>}
      {this.renderLogoutModal()}
      <Header data-test-id="headerComponent" readNotification={(id:any) => this.readNotificationDataHeader(id)} markAllRead={() => this.markAllAsReadNotificationData()} list={this.state.notificationListData} showLogoutModal={this.showLogoutModal} onUserLogOut={this.onUserLogOut}
      redirectToLogin={this.state.redirectToLogin}
      />
      </React.Fragment>
      // Customizable Area End
    );
  }
}
