import React from "react"

// Customizable Area Start
import {
    Box,
    Container,
    Grid,
    styled,
    Tooltip
} from "@material-ui/core"
import StarHalfIcon from '@material-ui/icons/StarHalf';
import Header from "../../emailnotifications2/src/TopHeader.web";
import { backImg, personImg, videoImg, paymentImg } from "./assets"
import EngagemenRequestControlle from "./EngagemenRequestController.web"
import { configJSON } from "./DashboardController";
import ReactPlayer from 'react-player';
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";

// Customizable Area End
export default class EngagemenRequest extends EngagemenRequestControlle {

    // Customizable Area Start
    renderCustomerProfile = () => {
        const { name, platformNameList, img } = this.state.showListData;
        const rating = this.state.currentUserType === "customer"? this.state.engagementFullData.customer_rating: this.state.engagementFullData.contractor_rating
        return (
            <>
                <ProfileOuterBox>
                    <ProfileBox>
                        <CustomerProfileBox>
                            <CustomerProfile src={img} />
                        </CustomerProfileBox>
                        <CustomerDetailsBox>
                            <CustomerNameText>
                                {name}
                            </CustomerNameText>
                            <Box>
                                <ProfileHeadingText component={"span"}>
                                    {configJSON.Platform}
                                </ProfileHeadingText>
                                <ProfileSubHeadingText component={"span"}>
                                    {platformNameList}
                                </ProfileSubHeadingText>
                            </Box>
                            <Box>
                                <ProfileHeadingText component={"span"}>
                                    {configJSON.hourly}
                                </ProfileHeadingText>
                                <ProfileSubHeadingText component={"span"}>
                                    {this.state.engagementFullData?.contractor?.data?.attributes?.contractor_price}
                                </ProfileSubHeadingText>
                            </Box>
                            <Box style={{ display: "flex" }}>
                                <ProfileHeadingText component={"div"} style={{ width: 'auto' }}>
                                    {configJSON.totalcost}
                                </ProfileHeadingText>                                
                                <ProfileSubHeadingText component={"div"} style={{ display : "flex"}}>
                                ${this.state.engagementFullData.total_cost} 
                                   <Tooltip title={<>
                                     <div>
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "3px 15px"}}>
                                            <div style={{fontFamily: "Lato", fontSize: "12px", color: "#64748B"}}> Contractor Fee </div>
                                            <div style={{fontFamily: "Lato", fontSize: "12px", fontWeight: 700}}>${this.state.engagementFullData.contractor_fee}</div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "3px 15px"}}>
                                            <div style={{fontFamily: "Lato", fontSize: "12px", color: "#64748B"}}> Connection Fee </div>
                                            <div style={{fontFamily: "Lato", fontSize: "12px", fontWeight: 700}}> ${this.state.engagementFullData.connection_fee}</div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "3px 15px"}}>
                                            <div style={{fontFamily: "Lato", fontSize: "12px", color: "#64748B"}}> Service Fee (for {this.state.engagementFullData.duration} hrs)</div>
                                            <div style={{fontFamily: "Lato", fontSize: "12px", fontWeight: 700}}>${this.state.engagementFullData.service_fee}</div>
                                        </div>
                                        <hr style={{ color: "#CBD5E1" }} />
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "3px 15px"}}>
                                            <div style={{fontFamily: "Lato", fontSize: "12px", color: "#64748B"}}> Total Cost</div>
                                            <div style={{fontFamily: "Lato", fontSize: "12px", fontWeight: 700}}>${this.state.engagementFullData.total_cost}</div>
                                        </div>
                                     </div>
                                   </>}>
                                     <ExpandMoreIcon style={{ height: "18px", width: "18px"}} />
                                   </Tooltip>
                                </ProfileSubHeadingText>
                            </Box>
                        </CustomerDetailsBox>
                    </ProfileBox>
                    <RatingBox>
                        {rating && <StarHalfIcon className="ratingtext" />}
                        <RatingText component={"span"}>{rating}</RatingText>
                    </RatingBox>
                </ProfileOuterBox>
            </>
        )
    }

    renderVideoSection = () => {
        return (
            <Grid item md={8} xs={12}>
                {this.state.filePath === ""
                    ? <div style={{ textAlign: "center", fontWeight: "bold" }}>{configJSON.noVideo}</div>
                    : <><VideoHeader>
                        <HeadingText>
                            {configJSON.video}
                        </HeadingText>
                        <VideoButton>
                            <img src={videoImg} alt="video" />
                        </VideoButton>
                    </VideoHeader>
                        <VideoBox>
                            <ReactPlayer width={"100%"} controls
                                config={{
                                    file: {
                                        forceVideo: true,
                                    },
                                }}
                                url={this.state.filePath}
                            />
                        </VideoBox></>
                }
            </Grid>
        )
    }

    renderPaymentDetails = () => {
        const { engagementRequestMsg } = this.state.showListData
        const { title, fontColor, backgroundColor } = this.getPaymentChipTwo(this.state.payStatus)
        return (
            <Grid item md={4} xs={12}>
                <EngagementRequestBox>
                    <EngagementRequestHeader>
                        <EngagementRequestHeaderTitle>
                            {configJSON.engagementRequest}
                        </EngagementRequestHeaderTitle>
                        <EngagementRequestIcon>
                            <img src={personImg} alt="back" />
                        </EngagementRequestIcon>
                    </EngagementRequestHeader>
                    <EngagementRequestText>{engagementRequestMsg}</EngagementRequestText>
                </EngagementRequestBox>
                <PaymentDetailsBox>
                    <PaymentDetailsHeader>
                        <PaymentDetailsHeaderTitle>
                            {configJSON.paymentDetails}
                        </PaymentDetailsHeaderTitle>
                        <PaymentDetailstIcon>
                            <img src={paymentImg} />
                        </PaymentDetailstIcon>
                    </PaymentDetailsHeader>
                    <PaymentDataBox>
                        <PaymentDetailsSubText>
                            {configJSON.totalTime}
                        </PaymentDetailsSubText>
                        <PaymentDetailsDataText>                        
                            {this.state.engagementFullData.duration||0} HRS
                        </PaymentDetailsDataText>
                    </PaymentDataBox>
                    <PaymentDataBox>
                        <PaymentDetailsSubText>
                            {configJSON.paymentStatus}
                        </PaymentDetailsSubText>
                        <PanddingTag style={{
                            background: backgroundColor,
                            color: fontColor
                        }}>{title}</PanddingTag>
                    </PaymentDataBox>
                    <PaymentDataBox>
                        <PaymentDetailsSubText>
                            {configJSON.serviceStartTime}
                        </PaymentDetailsSubText>
                        <PaymentDetailsDataText>
                        {this.state.engagementFullData.service_start_time && moment(this.state.engagementFullData.service_start_time).format('DD MMM YYYY, hh:mm A')}
                        </PaymentDetailsDataText>
                    </PaymentDataBox>
                    <PaymentDataBox>
                        <PaymentDetailsSubText>
                            {configJSON.serviceEndTime}
                        </PaymentDetailsSubText>
                        <PaymentDetailsDataText>
                            {this.state.engagementFullData.service_end_time && moment(this.state.engagementFullData.service_end_time).format('DD MMM YYYY, hh:mm A')}
                        </PaymentDetailsDataText>
                    </PaymentDataBox>
                </PaymentDetailsBox>
            </Grid>
        )
    }

    render() {
        return (
            <>
                <Header />
                <Container>
                    <HeaderBox>
                        <BackButton
                            data-test-id="backArrowcall"
                            onClick={this.BackButtonCall}>
                            <img src={backImg} alt="back" />
                        </BackButton>
                    </HeaderBox>
                    {this.renderCustomerProfile()}
                    <Grid container spacing={2}>
                        {this.renderPaymentDetails()}
                        {this.state.loader ?
                        <Loader loading={true} /> : this.renderVideoSection()}
                    </Grid>
                </Container>
            </>
        )
    }
}
// Customizable Area End

// Customizable Area Start
const BackButton = styled(Box)({
    padding: "5px",
    borderRadius: "6px",
    backgroundColor: "#DAFBFF",
    cursor: "pointer",
    width: "24px",
    height: "24px"
});
const HeaderBox = styled(Box)({
    margin: "10px 0"
});
const VideoHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0"
});
const HeadingText = styled(Box)({
    fontSize: "15px"
});
const VideoButton = styled("button")({
    height: "30px",
    width: "30px",
    borderRadius: "100%",
    backgroundColor: "#DAFBFF",
    color: "#2F59FF",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});
const ProfileBox = styled(Box)({
    display: "flex",
    columnGap: "10px"
});
const ProfileOuterBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    margin: "10px 0"
});
const ProfileHeadingText = styled(Box)({
    fontSize: "12px",
    fontStyle: "Lota",
    width: 700
});
const ProfileSubHeadingText = styled(Box)({
    fontSize: "12px",
    color: "#64748B",
    marginLeft: "5px",
    fontStyle: "Lota"
});
const CustomerNameText = styled(Box)({
    fontFamily: "Lato",
    fontSize: "23px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "#2966FF"
});
const CustomerProfileBox = styled(Box)({
    height: "100px",
    width: "100px"
});
const CustomerProfile = styled("img")({
    height: "100%",
    width: "100%"
});
const CustomerDetailsBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
});
const RatingBox = styled(Box)({
    display: "flex",
    columnGap: "5px",
    alignItems: "center",

    "& .ratingtext": {
        color: '#6eebff', height: "24px", width: "24px", marginLeft: '2px', marginRight: '2px'
    }
});
const RatingText = styled(Box)({
    fontFamily: "Lato",
    fontSize: "14px"
});
const VideoBox = styled(Box)({
    height: "350px",
    width: "auto",
    borderRadius: "5px",
    overflow: "hidden"
})
const EngagementRequestBox = styled(Box)({
    boxShadow: "0px 0px 5px 0px rgba(128, 128, 128, 0.75)",
    "-webkit - box - shadow": "0px 0px 5px 0px rgba(128, 128, 128, 0.75)",
    "-moz - box - shadow": "0px 0px 5px 0px rgba(128, 128, 128, 0.75)",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "5px",
    boxSizing: "border-box",
})
const EngagementRequestHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0"
});
const EngagementRequestText = styled(Box)({
    fontSize: "14px",
    padding: "10px 0",
    overflow: "hidden",
    overflowY: "scroll",
    height: 105,
})
const EngagementRequestHeaderTitle = styled(Box)({
    fontSize: "16px",
    fontWeight: 500
});
const EngagementRequestIcon = styled("button")({
    height: "30px",
    width: "30px",
    borderRadius: "100%",
    backgroundColor: "#DAFBFF",
    color: "#2F59FF",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});
const PaymentDetailsBox = styled(Box)({
    boxShadow: "0px 0px 5px 0px rgba(128, 128, 128, 0.75)",
    "-webkit - box - shadow": "0px 0px 5px 0px rgba(128, 128, 128, 0.75)",
    "-moz - box - shadow": "0px 0px 5px 0px rgba(128, 128, 128, 0.75)",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "5px",
    boxSizing: "border-box"
});
const PaymentDetailsHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0"
});
const PaymentDetailstIcon = styled("button")({
    height: "30px",
    width: "30px",
    borderRadius: "100%",
    backgroundColor: "#DAFBFF",
    color: "#2F59FF",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});
const PaymentDetailsHeaderTitle = styled(Box)({
    fontSize: "16px",
    fontWeight: 500
});
const PaymentDataBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0"
});
const PaymentDetailsSubText = styled(Box)({
    fontSize: "12px",
    color: "#808080"
});
const PaymentDetailsDataText = styled(Box)({
    fontSize: "14px",
    fontWeight: 500
});
const PanddingTag = styled(Box)({
    backgroundColor: "#F5CACB",
    fontSize: "12px",
    padding: "5px 10px",
    color: "#D00E1E",
    borderRadius: "20px",
    fontWeight: 400
});
const VideoPreview = styled("video")({
    height: "100%",
    width: "100%"
});
const StyleDiv = styled("div")({
    "& .tooltipContent": {
        display: "flex",
        justifyContent: "space-between",
        padding: "3px 15px"
    },
    "& .tooltipContentTxt": { fontFamily: "Lato", fontSize: "12px", color: "#64748B" },
    "& .tooltipContentSubTxt": { fontFamily: "Lato", fontSize: "12px", fontWeight: 700 }
});

// Customizable Area End
