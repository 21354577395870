// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Box } from '@material-ui/core';
import { styled } from "@material-ui/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function NewCheckoutForm(props: any) {

  const [name, setName] = useState<any>("");
  const [nameErr, setNameErr] = useState("");
  const [numberErr, setNumberErr] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [cvvErr, setCvvErr] = useState("");

  const [message, setMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expdate, setExpDate] = useState("")
  const [minDate, setMinDate] = useState(new Date())

  useEffect(() => {
     setName(props.cardInfo.card_holder_name ?? "")
     setExpDate(props.cardInfo.expiry_month)
  },[props])

  const isNameValid = () => {
    if (name.length < 1) {
      setNameErr("Your name is incomplete.");
      return false;
    } else if (!(/^[A-Za-z]+( [A-Za-z]+)*$/i.test(name)) || name.trim() === "") {
      setNameErr("Your name is invalid.");
      return false;
    } else {
      setNameErr("");
      return true;
    }
  }

  const handleDateChange = (originalDateString: string) => {
    const originalDate = new Date(originalDateString);

    // Extract day, month, and year components
    const month = originalDate.getMonth() + 1; // Months are zero-indexed, so adding 1
    const year = originalDate.getFullYear();

    // Pad day and month with leading zeroes if necessary
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Create the desired formatted date string
    const formattedDate = `${formattedMonth}/${year}`;

    setMinDate(originalDate)
    setExpDate(formattedDate)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();


    if (name.trim() === "") {
        setNameErr("Your name is incomplete.");
        return false;
      }

      const payLoad = {
          id: props.cardInfo.id, 
          body: {
              "billing_details": {
                  "name": name
              },
              "card": {
                  "exp_month": expdate.split("/")[0],
                  "exp_year": expdate.split("/")[1]
              }
          }
      } 
      props.updateCardInfo(payLoad)
  };

  const handleCancelClose = (e: any) => {
    e.preventDefault();
    setName("")
    setExpDate("")
    setNameErr("")
    props.handleModelClose();
  }

  return (
    <>
      <R1Form>
      <form className={"r1form"} id="payment-form" onSubmit={handleSubmit}>
        <Box mb="20px" display="flex" flexDirection="column">
          <label className="labelField">Card Holder Name</label>
          <input
            className="inputField"
            placeholder="Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
              setNameErr("");
              setMessage("");
            }}
            onBlur={() =>isNameValid()}
          />
          <div className="error">{nameErr}</div>
        </Box>
        <Box mb="20px">
          <label className="labelField">Card Number</label><br/>
          <input
            id='cardNumberId'
            style={{ width: '100%'}}
            className="inputField"
            disabled
            value={`**** **** **** ${props.cardInfo.card_number}`}
          />
          <div className="error">{numberErr}</div>
        </Box>
        <Box display="flex">
          <Box mb="20px" mr="10px" width="50%">
            <label className="labelField">Expiration Date</label>
            <DatePicker
                    placeholderText='Exp date'
                    value={expdate}
                    minDate={minDate}
                    required
                    showMonthYearPicker
                    className="inputField"
                    dateFormat="MM/yy"
                    onChange={(date: any) => handleDateChange(date)}
                />
            <div className="error">{dateErr}</div>
          </Box>
          <Box mb="20px" width="50%">
            <label className="labelField">Security Code</label>
            <input
              id='cardCvvId'
              style={{ width: '100%'}}
              className="inputField"
              disabled
              value={"***"}
            />
            <div className="error">{cvvErr}</div>
          </Box>
        </Box>
        <br />
          <div style={{ padding: "0 68px 0px 68px", display:"flex" }}>
            <button id="cancel" onClick={(e) => handleCancelClose(e)}>
              Cancel
            </button>
            <button style={{ background: isLoading ? 'gray' : '#2966FF'}} disabled={ isLoading} id="submit">
              Update
            </button>
          </div>
        
        {message && <div id="payment-message">{message}</div>}
      </form>
      </R1Form>
    </>
  );
}

const R1Form = styled(Box)({
  width: "100%",
  "& .r1form": {
    boxShadow: "none !important",
    borderRadius: "0 !important",
    "@media (max-width: 600px)": {
      minWidth: "100% !important",
      width: "100% !important",
      borderTop: "1px solid #dcdcdc",
      borderRight: "none",
      padding: "20px 0 0 !important",
    },
    "& .labelField" : {
      color: "#1E293B",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px", /* 150% */
    },
    "& .inputField" : {
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      background: "#FFF",
      padding: "16px 14px",
      marginTop: "4px"
    },
    "& #cancel" : {
      width: "168px",
      height: "56px",
      padding:" 10px 16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      flexShrink: 0,
      borderRadius: "8px",
      border: "1px solid #2966FF",
      background: "#FFF",
      marginRight: 15,
      color: "#2966FF",
      cursor: 'pointer'
    },
    "& #submit" : {
      width: "168px",
      height: "56px",
      padding:" 10px 16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      flexShrink: 0,
      borderRadius: "8px",
      border: "1px solid #2966FF",
      background: "#2966FF",
      color: '#fff',
      cursor: 'pointer'
    },
    "& .error" : {
       color: "red",
    }
  },
});
// Customizable Area End;