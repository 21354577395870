import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,  
  InputLabel,
  TextField
} from  "@material-ui/core";
import { createTheme,  ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { goldStar, blackStar } from "../../landingpage/src/assets";
import { halfStar } from "./assets"
import EngagementModalController, { Props } from "./EngagementModalController.web";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Loader from "../../../components/src/Loader.web";

// Customizable Area End


export default class EngagementModal extends EngagementModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  renderEngagment = () => {
    const customerDetails = this.props.engageMentRecords?.customer || {};
    return(
      <div className="modal-Engagement-container">
        <Typography variant="h5" className="modal-Engagement-header" component="div">Engagement Request </Typography>
        <Box className="modal-Engagement-content" sx={webStyles.engagementContent}>
          
          <div className="user-details">
            <div style={{background: 'gray'}}>
              {this.props.engageMentRecords?.photo ? <img src={this.props.engageMentRecords.photo} className="user-image" /> : <PersonOutlineOutlinedIcon className="user-image"/>}           
            </div>
            <div className="user-details-container">
              <Typography className="userName" gutterBottom variant="h6">{customerDetails.first_name} {customerDetails.last_name}</Typography>
              <Typography variant="subtitle2">{`${this.props.engageMentRecords.platform_name||""}`}</Typography>
              <Typography className="d-flex" variant="subtitle2">Rating : {customerDetails.rating} <img  style={{ height: 16, width: 16, marginTop:3}} src={halfStar} alt="Star"/>
              </Typography>
              <Typography className="timeLeft" variant="subtitle2">Time Left: </Typography>
            </div>
          </div>
          <div className="">
            <Typography className="clr-blue" gutterBottom variant="h5">ENGAGEMENT REQUEST</Typography>
            <div className="engagement-description">
              <Typography variant="subtitle2">{this.state.engageMentRecords?.message}</Typography>
            </div>
          </div>
        </Box>
        
        <Box sx={webStyles.modalButtonView}>
            <Button variant="outlined" color="primary" onClick={this.onDeclineReq} size="large" className="custom-button-padding custom-button-outlined"> Deny</Button>
            <Button disabled={this.state.engageMentRecords.meeting_id ? this.state.disableEngagementAcceptBtn : false} variant="contained" size="large" color="primary"className="custom-button-padding custom-button-contained" onClick={() => this.validateRooomId()}> Accept </Button>
        </Box>         
      </div>
    )
  }
  renderRatingContractor = () => {
    return(
      <Box className="rate-contractor-container">
          <Typography variant="h6"  className="rate-contractor-header">Please rate your contractor</Typography>

          <Typography variant="subtitle2" className="rate-contractor-sub-header"> Share Your Experience </Typography> 
          
          <Box className="rate-contractor-content">
            {this.state.ratingCat.map((cate:any, index: number) => {
              return(
                <Box key={`cat-${index}`} className="star-category-container">
                  <Typography variant="subtitle2" style={{color: '#64748B'}}>{cate.title}</Typography>
                  <Box style={{display: 'flex', alignItems: 'center', gap: 5}}>
                  {[1, 2, 3, 4, 5].map((value: number, starIndex: number) => (<img onClick={() => this.onStarClick(index, value)} key={`stars-${index}-${starIndex}`} style={{color: '#DDC026', height: 36, width: 36, cursor: 'pointer'}} src={(value <= cate.value) ? goldStar: blackStar} alt="Star"/>))}
                  </Box>             
                </Box>
              )
            })}
            <Box className="rating-field" >
              <InputLabel style={{textAlign: 'left'}} id="anyThing-else"> Anything else? </InputLabel>
              <TextField style={{ marginTop: '15px', width: '100%' }} value={this.state.anythingElse} onChange={(event:any) => this.onFieldChange(event)} id="anyThing-else" variant="outlined" placeholder="Share with us here"/>
            </Box>             
          </Box>
          <Box>
            <Box sx={webStyles.modalButtonView}>
            <Box>
              <Button variant="outlined" color="primary" onClick={this.props.closeModal} size="large" className="custom-button-padding custom-button-outlined" >Cancel </Button>
            </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className="custom-button-padding custom-button-contained"
                    onClick={this.onRatingSubmit}
                  >Submit</Button>
              </Box>
            </Box>             
          </Box>
      </Box>    
    )
  }
  renderContractorModal = () => (
    <Modal
      open={this.props.showEngagementModal}
      // onClose={this.props.closeModal}
    >
      <Box className={`search-contractor-modal ${this.props.engagementModalType}`} sx={webStyles.modalContainer}>    
        <Loader loading={this.state.pageLoading} />     
        {this.props.engagementModalType === "engagmentRequest" && this.renderEngagment()}
        {this.props.engagementModalType === "rating" && this.renderRatingContractor()} 
        {this.props.engagementModalType === "engagmentRequest" && <Box> 
          <Button variant="text" onClick={this.onDeclineReq} className="engagement-modal-close">X</Button>
        </Box>}      
      </Box>      
    </Modal>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          {this.renderContractorModal()}
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  modalButtonView: {
    display: 'flex',
    justifyContent: 'right',
    gap: 10,
    paddingRight: 25,
    paddingTop: 20,
    paddingBottom: 15
  },
  modalContainer: {
    flexDirection: "column",
    display: "flex",
    left: "50%",
    top: "50%",
    position: "absolute" as "absolute",
    boxShadow: 24,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 8,
  },
  engagementContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 25,
    borderBottom: '1px solid #1E293B3D'
  }
};
// Customizable Area End
