// Customizable Area Start
// @ts-nocheck
import React, { useState} from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Box } from '@material-ui/core';
import { styled } from "@material-ui/styles";

export default function NewCheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [numberErr, setNumberErr] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [cvvErr, setCvvErr] = useState("");

  const [message, setMessage] = useState(null);

  const clearStripeModal = () => {
    const cardNo = elements.getElement(CardNumberElement)
    const cardExpiry = elements.getElement(CardExpiryElement)
    const cardCVV = elements.getElement(CardCvcElement)
    cardNo?.clear()
    cardCVV?.clear()
    cardExpiry?.clear()
    setName("")
  }
  const isNameValid = () => {
    if (name.length < 1) {
      setNameErr("Your name is incomplete.");
      return false;
    } else if (!(/^[A-Za-z]+( [A-Za-z]+)*$/i.test(name)) || name.trim() === "") {
      setNameErr("Your name is invalid.");
      return false;
    } else {
      setNameErr("");
      return true;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const nameValid = isNameValid();
    if (numberErr != "" && dateErr != "" && cvvErr != "") {
      if (!nameValid) {
        return;
      }
    }
    const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
    const {token, error} = await stripe.createToken(cardElement);


    if (!nameValid || numberErr || dateErr || cvvErr) {
      return;
    }
    if (error) {
      setMessage(error.message);
    } else {
      props.addCardDetail(token?.id)
      clearStripeModal();
      if(props.updateCardData){
        props.updateCardData(token.card)
      }
    }
  };

  const handleCancelClose = (e) => {
    e.preventDefault();
    setNameErr("")
    setNumberErr("")
    setDateErr("")
    setCvvErr("")
    props.handleClose();
    clearStripeModal()
  }

  return (
    <>
      <R1Form>
      <form className={"r1form"} id="payment-form" onSubmit={handleSubmit}>
        <Box mb="20px" display="flex" flexDirection="column">
          <label className="labelField">Card Holder Name</label>
          <input
            className="inputField"
            placeholder="Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
              setNameErr("");
              setMessage("");
            }}
            onBlur={() =>isNameValid()}
          />
          <div className="error">{nameErr}</div>
        </Box>
        <Box mb="20px">
          <label className="labelField">Card Number</label>
          <CardNumberElement
            id='cardNumberId'
            className="inputField"
            options={{ showIcon: true }}
            onChange={(e: any) => {
              setNumberErr(e.error?.message);
              setMessage("");
            }}
          />
          <div className="error">{numberErr}</div>
        </Box>
        <Box display="flex">
          <Box mb="20px" mr="10px" width="50%">
            <label className="labelField">Expiration Date</label>
            <CardExpiryElement
              id='cardExpiryId'
              className="inputField"
              onChange={(e: any) => {
                setDateErr(e.error?.message);
                setMessage("");
              }}
            />
            <div className="error">{dateErr}</div>
          </Box>
          <Box mb="20px" width="50%">
            <label className="labelField">Security Code</label>
            <CardCvcElement
              id='cardCvvId'
              className="inputField"
              onChange={(e: any) => {
                setCvvErr(e.error?.message);
                setMessage("");
              }}
            />
            <div className="error">{cvvErr}</div>
          </Box>
        </Box>
        <br />
          <div style={{ padding: "0 68px 0px 68px", display:"flex" }}>
            <button id="cancel" onClick={(e) => handleCancelClose(e)}>
              Cancel
            </button>
            <button style={{ background: !stripe || !elements ? 'gray' : '#2966FF'}} disabled={!stripe || !elements } id="submit">
              Submit
            </button>
          </div>
        
        {message && <div style={{ color: "red"}} id="payment-message">{message}</div>}
      </form>
      </R1Form>
    </>
  );
}

const R1Form = styled(Box)({
  width: "100%",
  "& .r1form": {
    boxShadow: "none !important",
    borderRadius: "0 !important",
    "@media (max-width: 600px)": {
      minWidth: "100% !important",
      width: "100% !important",
      borderTop: "1px solid #dcdcdc",
      borderRight: "none",
      padding: "20px 0 0 !important",
    },
    "& .labelField" : {
      color: "#1E293B",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px", /* 150% */
    },
    "& .inputField" : {
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      background: "#FFF",
      padding: "16px 14px",
      marginTop: "4px"
    },
    "& #cancel" : {
      width: "168px",
      height: "56px",
      padding:" 10px 16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      flexShrink: 0,
      borderRadius: "8px",
      border: "1px solid #2966FF",
      background: "#FFF",
      marginRight: 15,
      color: "#2966FF",
      cursor: 'pointer'
    },
    "& #submit" : {
      width: "168px",
      height: "56px",
      padding:" 10px 16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      flexShrink: 0,
      borderRadius: "8px",
      border: "1px solid #2966FF",
      background: "#2966FF",
      color: '#fff',
      cursor: 'pointer'
    },
    "& .error" : {
       color: "red",
    }
  },
});
// Customizable Area End;