// Customizable Area Start
import React, { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  useTheme,
  Tooltip,
  Menu,
  MenuItem
} from "@material-ui/core";
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  ScreenShare,  
  CallEndSharp,
  ChatBubbleOutlineOutlined,
  GroupRounded,
  Info,
  FiberManualRecordRounded, 
  StopScreenShare,
  VisibilityRounded,
  VisibilityOffRounded
} from "@material-ui/icons"
import ExternalVideo from "./ExternalVideo.web";
import Participants from "./Participants";
import Connections from "./Connections";
import MeetingChat from "./MeetingChat";

const configJSON = require("./config");

const MeetingView = ({
  participantViewVisible,
  meetingId,
  toggleMic,
  toggleWebcam,
  toggleScreenShare,
  value,
  setValue,
  handleStartRecording,
  handleStopRecording,
  confirmLeave,
  localWebcamOn,
  localMicOn,
  enableWebcam,
  disableWebcam,
  muteMic,
  unmuteMic,
  disableScreenShare,
  enableScreenShare,
  getMics,
  getWebcams,
  localScreenShareOn,
  participants,
  localParticipant,
  isRecording,
  changeMic,
  screenisAlreadySharing,
  changeWebcam,
  getCustomWebCamCustomTrack
}) => {
  const theme = useTheme();
  const [chatShow, setChatShow] = useState(true)
  const [participantsShow, setParticipantsShow] = useState(true)
  const [webcamMenuAnchor, setWebcamMenuAnchor] = useState(false);
  const [webCamsAVailable, setWebcamsAvailable] = useState([]);
  const [webMicsAVailable, setWebMicsAvailable] = useState([]);
  const [webMicsMenuAnchor, setwebMicsMenuAnchor] = useState(false);


  const onClickChat = () => {
    setChatShow(!chatShow)
  }

  const onHideParticipantsClick = () => {
    setParticipantsShow(!participantsShow)
  }

  const onMicIconClick = async() => {
    const mics = await getMics();
    console.log(mics, "mics");     
    if(localMicOn){
      muteMic()
    }else{
      if(mics.length > 1){
        setWebMicsAvailable(mics);
        setwebMicsMenuAnchor(true); 
      }else{
        changeMic(mics[0]);
        unmuteMic(mics[0]);
      }      
    }    
  }

  const onVideoIconClick = async() => {    
    if(!localWebcamOn){  
      const video = await getWebcams();
      if(video.length > 1){
        setWebcamsAvailable(video);
        setWebcamMenuAnchor(true);        
      }else{
        const customCamTrack = await getCustomWebCamCustomTrack(video[0].deviceId);
        changeWebcam(customCamTrack);
      }
    } else {
      disableWebcam()
    }
  }

  const handleWebcamMenuClose = () => {
    setWebcamMenuAnchor(false);
  };

  const handleWebMicsMenuClose = () => {
    setWebcamMenuAnchor(false)
  }

  const getCustomScreenShareTrack = async () => {
    if(localScreenShareOn){
      disableScreenShare()
    }else{
      if(screenisAlreadySharing){
        window.alert("Sorry! You cant share your Screen when other person is sharing the Screen")
      }else{
        try {      
          const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });  
          enableScreenShare(stream);
          if(chatShow){
            onClickChat()
          }
          return ""
        } catch (error) {
          console.error('Error capturing custom screen-sharing track:', error);
          return null;
        }
      }
    }
  }
  const handleWebcamMenuItemClick =async (device) =>{     
    const customCamTrack = await getCustomWebCamCustomTrack(device.deviceId);
    changeWebcam(customCamTrack);
    setWebcamMenuAnchor(false);    
  }

  const handleWebMicMenuItemClick =async (device) =>{     
    changeMic(device.deviceId);
    setwebMicsMenuAnchor(false)
  }

  return (
    <>
      <div
        style={{
          ...webStyles.mainContainer
        }}
      >
        <div className="video-container" style={webStyles.maxContainer}>
        <Paper style={webStyles.bottomNavigation} elevation={3}>
          <div style={webStyles.leftIconContainer}>
            <div style={webStyles.iconContainer}>
              <Info style={{color: 'white', margin: 'auto'}}/>            
            </div>
            {isRecording && <div style={{color: 'white', display:'flex', margin: 'auto'}}><FiberManualRecordRounded style={{color: 'red'}} /> <span>Recording...</span></div>}
          </div>         
           
          <BottomNavigation
            showLabels
            value={value}
            style={webStyles.bottomNavigationContainer}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            data-testid="bottomNavigation"
          >
            <BottomNavigationAction  
              style={webStyles.iconContainer}             
              icon={localMicOn ? <Mic style={{color: 'white', margin: 'auto'}}/> : <MicOff style={{color: 'white'}} />}
              onClick={onMicIconClick}
              data-testid="audioIcon"
            />
            <Menu
              id="mics-menu"
              keepMounted
              open={webMicsMenuAnchor}
              onClose={handleWebMicsMenuClose}
              PopoverClasses="webcam-container"
              className="webcam-container"
              style={{
                top: 82,
                right: 20
              }}
            >
              {webMicsAVailable.map((device, index) => (
                <MenuItem data-testid={device.deviceId} id={device.deviceId} key={device.deviceId} onClick={() => handleWebMicMenuItemClick(device)}>
                  {device.label || `Web Mic ${index + 1}`}
                </MenuItem>
              ))}
            </Menu>
            <BottomNavigationAction              
              icon={localWebcamOn ? <Videocam style={{color: 'white', margin: 'auto'}} /> : <VideocamOff style={{color: 'white'}} />}
              onClick={onVideoIconClick}
              data-testid="webcam"
              style={webStyles.iconContainer}
            />
            <Menu
              id="webcam-menu"
              keepMounted
              open={webcamMenuAnchor}
              onClose={handleWebcamMenuClose}
              PopoverClasses="webcam-container"
              className="webcam-container"
              style={{
                top: 82,
                right: 20
              }}
            >
              {webCamsAVailable.map((device, index) => (
                <MenuItem data-testid={device.deviceId} id={device.deviceId} key={device.deviceId} onClick={() => handleWebcamMenuItemClick(device)}>
                  {device.label || `Webcam ${index + 1}`}
                </MenuItem>
              ))}
            </Menu>
            <BottomNavigationAction              
              icon={localScreenShareOn ? <ScreenShare style={{color: '#3c3e50', margin: 'auto'}} />: <StopScreenShare style={{color: 'white', margin: 'auto'}} />}
              onClick={getCustomScreenShareTrack}
              data-testid="ScreenShare"
              style={localScreenShareOn ? webStyles.iconWhiteContaner: webStyles.iconContainer}
            />
            <BottomNavigationAction               
              icon={<ChatBubbleOutlineOutlined style={{color: chatShow ? '#3c3e50':'white', margin: 'auto'}}/>}
              onClick={onClickChat}
              data-testid="chatShow"
              style={chatShow ? webStyles.iconWhiteContaner: webStyles.iconContainer }
            />
            <Tooltip 
             title={
              <React.Fragment>
               <div style={webStyles.participantsList}>
              {[...participants.entries()].map((participant) => (
                  <p key={participant[0]}>
                    {participant[1].displayName}
                  </p>
                ))}
              </div> 
              </React.Fragment>
             }>
              <BottomNavigationAction
                icon={<GroupRounded style={{ color: 'white', margin: 'auto' }} />}
                data-testid="gropPeopeJoined"
                style={webStyles.iconContainer}                
              />
            </Tooltip>

            {screenisAlreadySharing && <BottomNavigationAction  
              style={participantsShow ? webStyles.iconWhiteContaner: webStyles.iconContainer}             
              icon={participantsShow ? <VisibilityRounded style={{color: '#3c3e50', margin: 'auto'}}/> : <VisibilityOffRounded style={{color: 'white'}} />}
              onClick={onHideParticipantsClick}
              data-testid="viewParticipants"
            />}
              
            <div style={webStyles.EndIcon}></div>
            <BottomNavigationAction
              icon={<CallEndSharp style={{color: 'white', margin: 'auto'}}/>}
              onClick={confirmLeave}
              data-testid="confirmLeave"
              style={webStyles.iconEndContainer}
            />
          </BottomNavigation>
        </Paper>
        <div style={webStyles.container}>
          <div
            style={{
              ...webStyles.participantsContainer,
              // height: `calc(100vh - ${configJSON.toolbarHeight}px)`,
            }}
          >
            <ExternalVideo />
            {participantViewVisible ? (
              <Participants data-testid="participants" screenisAlreadySharing={screenisAlreadySharing} participantsShow={participantsShow} chatShow={chatShow} />
            ) : (
              <Connections />
            )}
          </div>
          {chatShow && <MeetingChat closeChat={onClickChat} localParticipantId = {localParticipant?.id} />}
        </div>
        </div>        
        
      </div>
    </>
  );
};

const webStyles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    background:'#202337',    
  },
  maxContainer:{  
    display: "flex",
    flexDirection: "column",
    width: 1308,
    margin: 'auto'
  },
  meetingId: {
    color: "#706694",
  },
  container: {
    display: "flex",
    flex: 1,
    gap: 15,
    border: "1px solid #3c3e50",
    borderRadius: 14,
    padding: 16,
    // width: '100%'
  },
  participantWithoutChat: {
    width: '100%',
    margin: 'auto',
    border: "1px solid #3c3e50",
    borderRadius: 14,
    padding: 16
  },
  participantsContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
  },
  bottomNavigation: {     
    background: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: 'center',
    height: 85
  },
  bottomNavigationContainer: {
    background: 'transparent',
    width: 'max-content',
    float: 'right',
    alignItems: 'center',
    gap: 20
  },
  EndIcon:{
    borderRight: '1px solid white',
    width: 1,
    height: 30
  },
  leftIconContainer:{
    display: 'flex',
    flexDirection: 'row'
  },
  iconContainer: {
    height: 34,
    width: 34,
    minWidth: 34,
    maxWidth: 34,
    background: '#3c3e50',
    display: 'flex',
    borderRadius: 8,
    padding: 0
  },
  iconEndContainer: {
    height: 34,
    width: 34,
    minWidth: 34,
    maxWidth: 34,
    background: 'red',
    display: 'flex',
    borderRadius: 8,
    padding: 0
  },
  iconWhiteContaner:{
    height: 34,
    width: 34,
    minWidth: 34,
    maxWidth: 34,
    background: 'white',
    display: 'flex',
    borderRadius: 8,
    padding: 0
  },
  participantsList: {    
    padding: 10,
    background: 'white',
    borderRadius: 10,
  }
};

export default MeetingView;
// Customizable Area End
