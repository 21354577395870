import React from "react";
// Customizable Area Start
import {
  Box,
  createTheme,
  ThemeProvider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  Chip,
  Tooltip
} from "@material-ui/core";
import EngagemenRequestListController, { SortingHeaders } from "./EngagemenRequestListController.web";
import { Pagination } from "@material-ui/lab"
import Header from "../../../blocks/emailnotifications2/src/TopHeader.web";
import { backImg, noRecordLogo } from "./assets"
import { configJSON } from "./DashboardController";
// Customizable Area End



// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
 // Customizable Area End

export default class EngagemenRequestList extends EngagemenRequestListController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderPagination = () => {
    return (
      <Pagination
        count={this.state.totalPage}
        shape="rounded"
        boundaryCount={1}
        page={this.state.page}
        siblingCount={0}
        onChange={this.onPageChangeChildCategory}
      />
    );
  };

  showEngagementRecords = () => {
    return this.state.engagementDataList.length > 0 ? (
      this.state.engagementDataList.map((row: any, index: number) => {
        const {
          profileImg,
          FullNameList,
          durationTimeList,
          engagementRequestMsg,
          itemStartDateList,
          paymentStatusList,
          platformNameList,
          totalCostList,
          roomId,
          contractorId,
          rating
        } = this.customerData(row);
        return (
          <>
            <TableRow
              data-test-id="navigationDataID"
              onClick={() =>
                this.engagemenNavigate({ profileImg, FullNameList, durationTimeList, engagementRequestMsg, itemStartDateList, paymentStatusList, platformNameList, totalCostList, roomId, contractorId, rating, row })
              }
              className="table-content"
              key={`engagement-${index}`}
            >
              <TableCell className="image-container" component="th" scope="row">
                <img
                  src={profileImg}
                  alt="No img"
                  className="user-img"
                />
                {FullNameList}
              </TableCell>
              <TableCell align="left">
                <span className="status-pending">
                  <Chip 
                    label={paymentStatusList.title}
                    style={{
                      color: paymentStatusList.fontColor,
                      backgroundColor: paymentStatusList.backgroundColor
                    }}
                  />
                </span>
              </TableCell>
              <TableCell align="left">
                <Tooltip title={engagementRequestMsg}>
                  <Box style={{ maxWidth: "150px" }}>
                    <Typography noWrap>{engagementRequestMsg}</Typography>
                  </Box>
                </Tooltip>
              </TableCell>
              <TableCell align="left">{itemStartDateList}</TableCell>
              <TableCell align="left">{durationTimeList}</TableCell>
              <TableCell align="left">{platformNameList}</TableCell>
              <TableCell align="left">{totalCostList}</TableCell>
            </TableRow>
          </>
        );
      })
    ) : (
      <TableRow>
        <TableCell align="center" colSpan={7} style={{ fontWeight: 700,height: '500px', padding: '20px' }}>
            <img src={noRecordLogo} alt="no record" />
            <p style={{ color: "#94A3B8", fontFamily: "Lato"}}>{configJSON.noDataFound}</p>    
        </TableCell>
      </TableRow>
    );
  };

  handleSortByContra = () => {
    return this.state.userData.user_type === "customer" ? true : false
  }

  handleSortingArrows = (key: keyof SortingHeaders) => {
    const arrowDirection = this.state.sortableHeaders[key] ? <span> &#8593; </span> : <span> &#8595; </span>
    return arrowDirection
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box>
        <Header />
        <ThemeProvider theme={theme}>
          <ContentContainer>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box className="box h-max-content mr-24">
                  <Grid container>
                    <Grid item xs={12}>
                      <HeaderBox>
                        <BackButton
                          data-test-id="backArrowCall"
                          onClick={this.BackButton}>
                          <img src={backImg} alt="back Img" />
                        </BackButton>
                        <Typography
                          data-test-is="engageId"
                          className="w-100"
                          gutterBottom
                          variant="h5">
                          {configJSON.engagementRecord}
                        </Typography>
                      </HeaderBox>
                    </Grid>
                    <Grid item xs={12}>
<ScrollContainer>
  <BigTableWrapper>
                      <Table className="engagment-table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className="tag-colour"
                              data-test-id="customerCallID"
                              onClick={() => this.handleSortByContra() ? this.sortTableHandler("contractor", "first_name", this.state.sortOrder) : this.sortTableHandler("customer", "first_name", this.state.sortOrder)}
                            >{this.state.userData.user_type === "customer" ? configJSON.contractor :  configJSON.customer}  {this.handleSortingArrows(this.handleSortByContra() ? "contractor" :"customer")}</TableCell>
                            <TableCell align="left"
                              className="tag-colour"
                              data-test-id="paymentCallID"
                              onClick={() => this.sortTableHandler("payment_status", "",this.state.sortOrder)}
                            >{configJSON.paymentStatus} {this.handleSortingArrows("payment_status")}
                            </TableCell>
                            <TableCell
                              className="tag-colour"
                              data-test-id="engagementRequestTwoCallID"
                              onClick={() => this.sortTableHandler("engagement_request", "", this.state.sortOrder)}
                              align="left">{configJSON.request} {this.handleSortingArrows("engagement_request")}
                            </TableCell>
                            <TableCell
                              className="tag-colour"
                              data-test-id="servicestartTimeCallID"
                              onClick={() => this.sortTableHandler("service_start_time", "",this.state.sortOrder)}
                              align="left">{configJSON.date} {this.handleSortingArrows("service_start_time")}
                            </TableCell>
                            <TableCell
                              className="tag-colour"
                              data-test-id="durationCallID"
                              onClick={() => this.sortTableHandler("duration", "", this.state.sortOrder)}
                              align="left">{configJSON.duration} {this.handleSortingArrows("duration")}
                            </TableCell>
                            <TableCell
                              className="tag-colour"
                              data-test-id="platformShowCallID"
                              onClick={() => this.sortTableHandler("platform", "", this.state.sortOrder)}
                              align="left">{configJSON.Platform} {this.handleSortingArrows("platform")}
                            </TableCell>
                            <TableCell
                              className="tag-colour"
                              data-test-id="totalCost"
                              onClick={() => this.sortTableHandler("total_cost", "", this.state.sortOrder)}
                              align="left">{configJSON.total} {this.handleSortingArrows("total_cost")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{this.showEngagementRecords()}</TableBody>
                      </Table>
  </BigTableWrapper>
</ScrollContainer>
                      <PaginationBox>{this.renderPagination()}</PaginationBox>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </ContentContainer>
        </ThemeProvider>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ContentContainer = styled(Box)({
  backgroundColor: "#F8FAFC",
  "& .engagment-table .tag-colour": {
    color: "#64748B",
    fontFamily: "Lato",
    cursor:"pointer"
  }
});
const HeaderBox = styled(Box)({
  margin: "10px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "20px"
});

const BackButton = styled(Box)({
  padding: "5px",
  borderRadius: "6px",
  backgroundColor: "#DAFBFF",
  cursor: "pointer",
  width: "24px",
  height: "24px"
});

const PaginationBox = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "30px",
  marginBottom: "20px",
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#fff",
    color: "#2966FF"
  },
  "& .MuiPaginationItem-page.Mui-disabled": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px"
  }
});

const ScrollContainer = styled(Box)({
  overflowX: "auto",
  paddingBottom: "20px",
  marginTop: "10px"
});
const BigTableWrapper = styled(Box)({
  minWidth: "900px"
});

// Customizable Area End
