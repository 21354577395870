export const star = require("../assets/star.svg");
export const expertsicon = require("../assets/expertsicon.svg");
export const founder = require("../assets/founder.png");
export const groupbanner = require("../assets/groupbanner.png");
export const avatar = require("../assets/avatar.png");
export const globalcustomers = require("../assets/globalcustomers.svg");
export const groupicon = require("../assets/groupicon.svg");

export const Pending_earnings = require("../assets/image_Component 2.png");
export const Engagements = require("../assets/image_Component 2(1).png");
export const Lifetime_earnings = require("../assets/image_Component 2(2).png");
export const UserIcon = require("../assets/user-icon.png");
export const engagementProfileIcon = require("../assets/engagement_profile.png");
export const halfStar = require("../assets/half-star.png");
export const backImg = require("../assets/image-arrow-back-24px.png")
export const personImg = require("../assets/personImg.svg")
export const videoImg = require("../assets/video.svg")
export const paymentImg = require("../assets/payment.svg")
export const profileLogo = require("../assets/profileLogo.png")
export const noRecordLogo = require("../assets/noRecords.svg")






