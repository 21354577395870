// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";

import Participant from "./Participant.web";

const configJSON = require("./config");

const ParticipantsView = ({ participants, screenisAlreadySharing, chatShow, participantsShow }) => {
  const splitParticipantsIntoRows = numberPerRow => {
    const participantList = [...participants.keys()];

    const newArr = [];
    while (participantList.length) {
      newArr.push(participantList.splice(0, numberPerRow + 1));
    }
    return newArr;
  };
  const participantList = [...participants.keys()];

  return (
    <div className={`leftContainer${screenisAlreadySharing?"-withScreenshare":""}${chatShow?" withChat":""}`} key={"k1"} style={screenisAlreadySharing ? webStyles.shareContainer : webStyles.container}>      
      {screenisAlreadySharing && splitParticipantsIntoRows(2).map((row, index) => (
        <div key={`screenShare-${index}`} className="screenShareContainer" style={webStyles.screenShareContainer}>
          {row.map(participant => (
            <Participant key={participant} participantId={participant} displayType="screenShare" participantsList={participantList}/>
          ))}
        </div>
      ))}
      {splitParticipantsIntoRows(2).map((row, index) => (
        <div key={`participant-${index}`} className={`participantcontainer ${!participantsShow && screenisAlreadySharing ? "hide" :"" }`} style={webStyles.participantContainer}>
          {row.map(participant => (
            <Participant key={participant} participantId={participant} displayType="participant" participantsList={participantList} />
          ))}
        </div>
      ))}
    </div>
  );
};

const webStyles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: 0
  },
  shareContainer:{
    display: "flex",     
    flexDirection: "row",
    padding: 0,
    gap: 15
  },
  participantLabel: {
    color: "#3E84F6",
    display: 'none',
  },
  participantContainer: {
    display: "flex",
    flexDirection: 'column',
    gap: 5,
  },
  screenShareContainer:{
    display: "flex",
    flexDirection: 'column',
    gap: 5,

  }
};

export default ParticipantsView;
// Customizable Area End
