export const avatar = require("../assets/avatar.png");
export const flagUS = require("../assets/flagUS.png");
export const edit = require("../assets/edit.png");
export const deleteimg = require("../assets/delete.png");
export const add = require("../assets/add.png");
export const star = require("../assets/star.svg");
export const Editt = require("../assets/Editt.png");
export const crossicon = require("../assets/crossicon.png");
export const blackaddicon = require("../assets/blackadd.png");
export const cameraicon = require("../assets/camera.png");
export const editavatar = require("../assets/avatarsquare.png");
export const plusIcon = require("../assets/plusIcon.png");









