export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Logo = require('../assets/image_logo.png');
export const profileLogo = require("../assets/profileLogo.png")
export const cropImage = (imageUrl: string, isLanscape: boolean, newImage?: any) => {
    return new Promise((resolve, reject) => {
      const img = newImage ? newImage : new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context: CanvasRenderingContext2D |null = canvas.getContext('2d');
        let width, height, offsetX, offsetY;
  
        if (isLanscape) {                 
            const aspectRatio =  190 / 109; 
            const imageAspectRatio = img.width / img.height;
            let width, height, offsetX, offsetY;
    
            if (imageAspectRatio > aspectRatio) {
            // Image is wider than the target aspect ratio
            width = img.height * aspectRatio;
            height = img.height;
            } else {
            // Image is taller than the target aspect ratio
            width = img.width;
            height = img.width / aspectRatio;
            }
    
            offsetX = (img.width - width) / 2;
            offsetY = (img.height - height) / 2;
    
            canvas.width = width;
            canvas.height = height;
            context?.drawImage(img, offsetX, offsetY, width, height, 0, 0, width, height); 
                 
        } else {
            // For square, crop the smaller dimension to form a square
            const minSize = Math.min(img.width, img.height);
            width = minSize;
            height = minSize;
            offsetX = (img.width - minSize) / 2;
            offsetY = (img.height - minSize) / 2;
            canvas.width = minSize;
            canvas.height = minSize;
            context?.drawImage(img, offsetX, offsetY, width, height, 0, 0, minSize, minSize);        
        }
        resolve(canvas.toDataURL());
      };
      img.onerror = reject;
      img.src = imageUrl;
    });
}

